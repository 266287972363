define('thefader-cms-ember/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {

    sessionCurrentAdmin: _ember['default'].inject.service('session-current-admin'),

    moment: _ember['default'].inject.service(),

    showHeader: true,

    setupController: function setupController() {
      this._super.apply(this, arguments);
      this.controllerFor('application').set('showHeader', this.get('showHeader'));
    },

    beforeModel: function beforeModel() {
      // debugger;
      this.get('moment').setTimeZone('America/New_York');
      return this._loadCurrentAdmin();
    },

    sessionAuthenticated: function sessionAuthenticated() {
      var _this = this;

      this._loadCurrentAdmin()['catch'](function () {
        return _this.get('session').invalidate();
      });
      this._super.apply(this, arguments);
    },

    _loadCurrentAdmin: function _loadCurrentAdmin() {
      return this.get('sessionCurrentAdmin').loadCurrentAdmin();
    }
  });
});