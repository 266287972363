define("thefader-cms-ember/templates/post-package-item/-ad", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.0",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 6
            },
            "end": {
              "line": 12,
              "column": 6
            }
          },
          "moduleName": "thefader-cms-ember/templates/post-package-item/-ad.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "src", "/assets/images/spinner.gif");
          dom.setAttribute(el1, "width", "20");
          dom.setAttribute(el1, "height", "20");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 6
          }
        },
        "moduleName": "thefader-cms-ember/templates/post-package-item/-ad.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-9");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        var el4 = dom.createTextNode("Ad Image");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    \n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "image-dropzone");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "style", "margin-top: 20px;");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "upload_button_holder");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("button");
        dom.setAttribute(el6, "class", "btn btn-success");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("i");
        dom.setAttribute(el7, "class", "glyphicon glyphicon-plus");
        dom.setAttribute(el7, "style", "font-size:20px;");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            Upload\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [5]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element1, 1, 1);
        morphs[1] = dom.createMorphAt(element1, 3, 3);
        morphs[2] = dom.createAttrMorph(element2, 'class');
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [1]), 3, 3);
        morphs[4] = dom.createMorphAt(element0, 5, 5);
        return morphs;
      },
      statements: [["inline", "cloudinary-tag", [["get", "model.imageCloudinaryPublicId", ["loc", [null, [8, 23], [8, 52]]], 0, 0, 0, 0]], ["width", 200], ["loc", [null, [8, 6], [8, 64]]], 0, 0], ["block", "if", [["get", "imageLoading", ["loc", [null, [10, 12], [10, 24]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [10, 6], [12, 13]]]], ["attribute", "class", ["concat", ["form-group ", ["subexpr", "if", [["get", "imageHasError", ["loc", [null, [14, 34], [14, 47]]], 0, 0, 0, 0], "required-alert"], [], ["loc", [null, [14, 29], [14, 66]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "cloudinary-uploader", [], ["dropZone", "image-dropzone", "start", "showSpinner", "done", "imageFinished"], ["loc", [null, [20, 10], [20, 100]]], 0, 0], ["inline", "form-field", [], ["value", ["subexpr", "@mut", [["get", "model.adUrl", ["loc", [null, [26, 23], [26, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "fieldname", "adUrl", "label", "Ad URL", "object", ["subexpr", "@mut", [["get", "model", ["loc", [null, [26, 75], [26, 80]]], 0, 0, 0, 0]], [], [], 0, 0], "mandatory", true], ["loc", [null, [26, 4], [26, 97]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});