define('thefader-cms-ember/components/slideshow-images', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {
      var _this = this;

      _ember['default'].run.later(function () {

        _this.$(".sortable").sortable({

          update: function update(event, ui) {
            //console.log("sortable update, ", event, ui);

            var indexes = {};

            $(_this).find('.slideshow-image').each(function (index) {
              indexes[$(_this).data('id')] = index;
            });

            // $(this).sortable('cancel');

            _this.updateSortOrder(indexes);
          }
        });
      });
    },

    updateSortOrder: function updateSortOrder(indexes) {

      //console.log("slideshow updateSortOrder:");
      //console.log(indexes);

      // this.beginPropertyChanges();

      this.get('model.images').forEach(function (item) {

        // debugger;

        var index = indexes[item.get('id')];
        index = index + 1; // so we're not zero indexed

        item.set('position', index);

        //console.log("item with id: ", item.get('id'), index);

        // item.save();
      }, this);

      // this.endPropertyChanges();
    },

    actions: {
      save: function save() {
        var slideshow = this.get('model');
        var _this = this;

        slideshow.save().then(function () {
          slideshow.get('images').then(function (images) {
            images.forEach(function (image) {
              //console.log("image", image);
              image.save();
            });
          });

          _this.get('router').transitionTo('posts.show', slideshow.get('contentBlock.post.id'));
        });
      }
    }
  });
});