define('thefader-cms-ember/authenticators/torii', ['exports', 'ember', 'ember-simple-auth/authenticators/torii'], function (exports, _ember, _emberSimpleAuthAuthenticatorsTorii) {
    var service = _ember['default'].inject.service;
    exports['default'] = _emberSimpleAuthAuthenticatorsTorii['default'].extend({
        torii: service('torii'),
        authenticate: function authenticate(options) {
            return this._super(options).then(function (data) {
                console.log(data);
                alert('authorizationCode:\n' + data.authorizationCode + '\nprovider: ' + data.provider + '\nredirectUri: ' + data.redirectUri);
            });
        }
    });
});