define('thefader-cms-ember/controllers/thumbnail/preview', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    actions: {

      editNormalVersion: function editNormalVersion() {
        this.transitionToRoute('thumbnailVersion.edit', this.get('model.normalVersion'));
      },

      editWideVersion: function editWideVersion() {
        this.transitionToRoute('thumbnailVersion.edit', this.get('model.wideVersion'));
      },

      // editBigVersion: function() {
      //   this.transitionToRoute('thumbnailVersion.edit', this.get('bigVersion'));
      // },

      save: function save() {
        var _this = this;

        var thumbnail = this.get('model');
        var normalVersion = thumbnail.get('normalVersion');
        var wideVersion = thumbnail.get('wideVersion');
        // var bigVersion = thumbnail.get('bigVersion');

        var post = this.get('post');
        // debugger;

        thumbnail.save().then(function () {
          normalVersion.save().then(function () {
            wideVersion.save().then(function () {
              post.set('thumbnail', thumbnail);
              _this.transitionToRoute('posts.show', post.get('id'));
            });
          });
        });
      },

      cancel: function cancel() {
        var post = this.get('post');
        this.transitionToRoute('posts.show', post.get('id'));
      }

    }
  });
});
// thumbnailStart: function() {
////   console.log("thumbnailStart");
//   this.sendAction('start');
// },

// thumbnailFinished: function(publicId, width, height) {
////   console.log("thumbnailFinished");
//   this.sendAction('done', publicId, width, height);
// }