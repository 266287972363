define('thefader-cms-ember/routes/post-packages/edit', ['exports', 'ember', 'thefader-cms-ember/routes/base'], function (exports, _ember, _thefaderCmsEmberRoutesBase) {
  exports['default'] = _thefaderCmsEmberRoutesBase['default'].extend({

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        postPackage: this.store.findRecord('postPackage', params.post_package_id)
      });
    },

    setupController: function setupController(controller, model, transitionTo) {
      this._super.apply(this, arguments);

      controller.set('model', model.postPackage);

      var postPackageItem = this.get('store').createRecord('postPackageItem', {});

      controller.set('newPostPackageItem', postPackageItem);
    }
  });
});