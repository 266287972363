define('thefader-cms-ember/helpers/friendly-month', ['exports', 'date-range-picker/helpers/friendly-month'], function (exports, _dateRangePickerHelpersFriendlyMonth) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _dateRangePickerHelpersFriendlyMonth['default'];
    }
  });
  Object.defineProperty(exports, 'friendlyMonth', {
    enumerable: true,
    get: function get() {
      return _dateRangePickerHelpersFriendlyMonth.friendlyMonth;
    }
  });
});