define("thefader-cms-ember/templates/application", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 8
                },
                "end": {
                  "line": 17,
                  "column": 8
                }
              },
              "moduleName": "thefader-cms-ember/templates/application.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1, "src", "/assets/images/fader_logo.png");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 12
                },
                "end": {
                  "line": 26,
                  "column": 92
                }
              },
              "moduleName": "thefader-cms-ember/templates/application.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Posts");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 12
                },
                "end": {
                  "line": 30,
                  "column": 61
                }
              },
              "moduleName": "thefader-cms-ember/templates/application.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Reports");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 12
                },
                "end": {
                  "line": 38,
                  "column": 55
                }
              },
              "moduleName": "thefader-cms-ember/templates/application.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Post Packages");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child4 = (function () {
          return {
            meta: {
              "revision": "Ember@2.7.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 84,
                  "column": 14
                },
                "end": {
                  "line": 86,
                  "column": 16
                }
              },
              "moduleName": "thefader-cms-ember/templates/application.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "href", "#");
              var el2 = dom.createTextNode("Logout");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element0);
              return morphs;
            },
            statements: [["element", "action", ["invalidateSession"], [], ["loc", [null, [85, 27], [85, 59]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child5 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.7.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 87,
                    "column": 16
                  },
                  "end": {
                    "line": 87,
                    "column": 41
                  }
                },
                "moduleName": "thefader-cms-ember/templates/application.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Login");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.7.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 86,
                  "column": 16
                },
                "end": {
                  "line": 88,
                  "column": 14
                }
              },
              "moduleName": "thefader-cms-ember/templates/application.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "link-to", ["login"], [], 0, null, ["loc", [null, [87, 16], [87, 53]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.7.0",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 0
              },
              "end": {
                "line": 104,
                "column": 0
              }
            },
            "moduleName": "thefader-cms-ember/templates/application.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("header");
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("nav");
            dom.setAttribute(el2, "class", "navbar navbar-default");
            dom.setAttribute(el2, "role", "navigation");
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "container-fluid");
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment(" Brand and toggle get grouped for better mobile display ");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "navbar-header");
            var el5 = dom.createTextNode("\n        ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("button");
            dom.setAttribute(el5, "type", "button");
            dom.setAttribute(el5, "class", "navbar-toggle collapsed");
            dom.setAttribute(el5, "data-toggle", "collapse");
            dom.setAttribute(el5, "data-target", "#bs-example-navbar-collapse-1");
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6, "class", "sr-only");
            var el7 = dom.createTextNode("Toggle navigation");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6, "class", "icon-bar");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6, "class", "icon-bar");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("span");
            dom.setAttribute(el6, "class", "icon-bar");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n        ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n      ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment(" Collect the nav links, forms, and other content for toggling ");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "collapse navbar-collapse");
            dom.setAttribute(el4, "id", "bs-example-navbar-collapse-1");
            var el5 = dom.createTextNode("\n        ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("ul");
            dom.setAttribute(el5, "class", "nav navbar-nav");
            var el6 = dom.createTextNode("\n\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            dom.setAttribute(el6, "class", "");
            var el7 = dom.createTextNode("\n            ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n          ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            dom.setAttribute(el6, "class", "");
            var el7 = dom.createTextNode("\n            ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n          ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            dom.setAttribute(el6, "class", "");
            var el7 = dom.createTextNode("\n            ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("a");
            var el8 = dom.createTextNode("Pinned Posts");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n          ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            dom.setAttribute(el6, "class", "");
            var el7 = dom.createTextNode("\n            ");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n          ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            dom.setAttribute(el6, "class", "");
            var el7 = dom.createTextNode("\n            ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("a");
            var el8 = dom.createTextNode("Legacy CMS");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n          ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment(" placeholder ");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          \n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("\n          <li class=\"dropdown\">\n            <a href=\"#\" class=\"dropdown-toggle\" data-toggle=\"dropdown\">\n              Dropdown <span class=\"caret\"></span>\n            </a>\n            <ul class=\"dropdown-menu\" role=\"menu\">\n              <li><a href=\"#\">Action</a></li>\n              <li><a href=\"#\">Another action</a></li>\n              <li><a href=\"#\">Something else here</a></li>\n              <li class=\"divider\"></li>\n              <li><a href=\"#\">Separated link</a></li>\n              <li class=\"divider\"></li>\n              <li><a href=\"#\">One more separated link</a></li>\n            </ul>\n          </li>\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n        ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n\n        ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("\n        <form class=\"navbar-form navbar-left\" role=\"search\">\n          <div class=\"form-group\">\n            <input type=\"text\" class=\"form-control\" placeholder=\"Search\">\n          </div>\n          <button type=\"submit\" class=\"btn btn-default\">Submit</button>\n        </form>\n        ");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n        \n        ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("ul");
            dom.setAttribute(el5, "class", "nav navbar-nav navbar-right");
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment(" ><li><a href=\"#\">Link</a></li> ");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            dom.setAttribute(el6, "class", "dropdown");
            var el7 = dom.createTextNode("\n            ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("a");
            dom.setAttribute(el7, "href", "#");
            dom.setAttribute(el7, "class", "dropdown-toggle");
            dom.setAttribute(el7, "data-toggle", "dropdown");
            var el8 = dom.createTextNode("\n              ");
            dom.appendChild(el7, el8);
            var el8 = dom.createComment("");
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode(" ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("span");
            dom.setAttribute(el8, "class", "caret");
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n            ");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n\n            ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("ul");
            dom.setAttribute(el7, "class", "dropdown-menu");
            dom.setAttribute(el7, "role", "menu");
            var el8 = dom.createTextNode("\n\n");
            dom.appendChild(el7, el8);
            var el8 = dom.createComment("");
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("              \n              ");
            dom.appendChild(el7, el8);
            var el8 = dom.createComment(" <li><a href=\"#\">Another action</a></li>\n              <li><a href=\"#\">Something else here</a></li>\n              <li class=\"divider\"></li>\n              <li><a href=\"#\">Separated link</a></li>\n              ");
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n              \n            ");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n          ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n        ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n      ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment(" /.navbar-collapse ");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createComment(" /.container-fluid ");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  \n");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [0, 1, 1]);
            var element2 = dom.childAt(element1, [7]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element3, [5, 1]);
            var element5 = dom.childAt(element3, [9, 1]);
            var element6 = dom.childAt(element2, [5, 3]);
            var morphs = new Array(8);
            morphs[0] = dom.createMorphAt(dom.childAt(element1, [3]), 3, 3);
            morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
            morphs[3] = dom.createAttrMorph(element4, 'href');
            morphs[4] = dom.createMorphAt(dom.childAt(element3, [7]), 1, 1);
            morphs[5] = dom.createAttrMorph(element5, 'href');
            morphs[6] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
            morphs[7] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
            return morphs;
          },
          statements: [["block", "link-to", ["application"], ["class", "navbar-brand"], 0, null, ["loc", [null, [15, 8], [17, 20]]]], ["block", "link-to", ["posts", ["subexpr", "query-params", [], ["contributor_id", ["get", "session.contributor_id", ["loc", [null, [26, 67], [26, 89]]], 0, 0, 0, 0]], ["loc", [null, [26, 38], [26, 90]]], 0, 0]], [], 1, null, ["loc", [null, [26, 12], [26, 92]]]], ["block", "link-to", ["reports.published-summary"], [], 2, null, ["loc", [null, [30, 12], [30, 61]]]], ["attribute", "href", ["concat", [["get", "pinnedPostsLink", ["loc", [null, [34, 23], [34, 38]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "link-to", ["post-packages"], [], 3, null, ["loc", [null, [38, 12], [38, 55]]]], ["attribute", "href", ["concat", [["get", "legacyCmsLink", ["loc", [null, [42, 23], [42, 36]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "sessionCurrentAdmin.admin.displayName", ["loc", [null, [79, 14], [79, 55]]], 0, 0, 0, 0], ["block", "if", [["get", "session.isAuthenticated", ["loc", [null, [84, 20], [84, 43]]], 0, 0, 0, 0]], [], 4, 5, ["loc", [null, [84, 14], [88, 21]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.0",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 105,
              "column": 0
            }
          },
          "moduleName": "thefader-cms-ember/templates/application.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "session.isAuthenticated", ["loc", [null, [3, 6], [3, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 0], [104, 7]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 110,
            "column": 0
          }
        },
        "moduleName": "thefader-cms-ember/templates/application.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "main-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showHeader", ["loc", [null, [2, 6], [2, 16]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [105, 7]]]], ["content", "outlet", ["loc", [null, [108, 2], [108, 12]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});