define('thefader-cms-ember/pods/build-week/helper', ['exports', 'date-range-picker/build-week/helper'], function (exports, _dateRangePickerBuildWeekHelper) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _dateRangePickerBuildWeekHelper['default'];
    }
  });
  Object.defineProperty(exports, 'buildWeek', {
    enumerable: true,
    get: function get() {
      return _dateRangePickerBuildWeekHelper.buildWeek;
    }
  });
});