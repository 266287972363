define('thefader-cms-ember/components/content-blocks', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {

      var _this = this;

      _ember['default'].run.later(function () {

        _this.$(".sortable").sortable({

          update: function update(event, ui) {
            //console.log("sortable update called");

            var indexes = {};

            // debugger;

            $(this).find('.edit-form-content-block').each(function (index) {
              indexes[$(this).data('id')] = index;
            });

            // $(_this).sortable('cancel');

            _this.updateSortOrder(indexes);
          }
        });
      });
    },

    updateSortOrder: function updateSortOrder(indexes) {

      //console.log("updateSortOrder:");
      //console.log(indexes);

      var _this = this;
      var promises = [];

      this.beginPropertyChanges();

      // this.forEach(function(itemController) {
      this.get('model').forEach(function (contentBlock) {

        //console.log("updating contentBlock: ", contentBlock.get('id'));

        // debugger;
        // var contentBlock = itemController.get('contentBlock');

        var index = indexes[contentBlock.get('id')];
        index = index + 1; // so we're not zero indexed

        contentBlock.set('position', index);
        promises.push(contentBlock.save());
      }, this);

      _ember['default'].RSVP.all(promises).then(function (contentBlocks) {
        console.log("finished updating content blocks");
        _this.endPropertyChanges();
      });

      // this.endPropertyChanges();
    }
  });
});