define("thefader-cms-ember/models/content-block", ["exports", "ember-data", "thefader-cms-ember/config/environment"], function (exports, _emberData, _thefaderCmsEmberConfigEnvironment) {
  exports["default"] = _emberData["default"].Model.extend({
    post: _emberData["default"].belongsTo("post", { inverse: "contentBlocks", async: false }),
    prePostId: _emberData["default"].attr("number"),

    contentBlockType: _emberData["default"].attr("string"),
    position: _emberData["default"].attr("number"),

    headline: _emberData["default"].attr("string"),
    headline2: _emberData["default"].attr("string"),
    subheadline: _emberData["default"].attr("string"),
    subheadline2: _emberData["default"].attr("string"),

    sectionSlug: _emberData["default"].attr("string"),

    title: _emberData["default"].attr("string"),
    description: _emberData["default"].attr("string"),
    titleColor: _emberData["default"].attr("string"),
    backgroundColor: _emberData["default"].attr("string"),
    textColor: _emberData["default"].attr("string"),

    body: _emberData["default"].attr("string"),
    isQuote: _emberData["default"].attr("boolean"),

    align: _emberData["default"].attr("string"),

    mediaBody: _emberData["default"].attr("string"),
    mediaBody2: _emberData["default"].attr("string"),

    mediaDisplayType: _emberData["default"].attr("string"),

    diptychContent1Type: _emberData["default"].attr("string"),
    diptychContent2Type: _emberData["default"].attr("string"),

    isInsetMedia: _emberData["default"].attr("boolean"),
    isBreaker: _emberData["default"].attr("boolean"),
    isGutterImage: _emberData["default"].attr("boolean"),
    isFullWidth: _emberData["default"].attr("boolean"),
    isTwoColumnDisplay: _emberData["default"].attr("boolean"),

    attribution: _emberData["default"].attr("string"),
    attribution2: _emberData["default"].attr("string"),
    credit: _emberData["default"].attr("string"),
    credit2: _emberData["default"].attr("string"),
    caption: _emberData["default"].attr("string"),
    caption2: _emberData["default"].attr("string"),

    imageCloudinaryPublicId: _emberData["default"].attr("string"),
    imageCloudinaryPublicId2: _emberData["default"].attr("string"),
    imageIsAnimatedGif: _emberData["default"].attr("boolean"),
    imageIsAnimatedGif2: _emberData["default"].attr("boolean"),

    videoCloudinaryId: _emberData["default"].attr("string"),
    videoCloudinaryImageId: (function () {
      var id = this.get("videoCloudinaryId");
      id += ".jpg";
      return id;
    }).property("videoCloudinaryId"),

    uploadType: _emberData["default"].attr("string"),
    uploadType2: _emberData["default"].attr("string"),

    imageOriginalWidth: _emberData["default"].attr("number"),
    imageOriginalHeight: _emberData["default"].attr("number"),
    image2OriginalWidth: _emberData["default"].attr("number"),
    image2OriginalHeight: _emberData["default"].attr("number"),

    quote: _emberData["default"].attr("string"),
    quote2: _emberData["default"].attr("string"),

    videoUrl: _emberData["default"].attr("string"),
    videoUrl2: _emberData["default"].attr("string"),
    youtubeVideoId: _emberData["default"].attr("string"),
    youtubeVideoId2: _emberData["default"].attr("string"),

    linkUrl: _emberData["default"].attr("string"),

    embed: _emberData["default"].attr("string"),
    embed2: _emberData["default"].attr("string"),

    audioUrl: _emberData["default"].attr("string"),
    audioUrl2: _emberData["default"].attr("string"),
    trackName: _emberData["default"].attr("string"),
    trackName2: _emberData["default"].attr("string"),
    artistName: _emberData["default"].attr("string"),
    artistName2: _emberData["default"].attr("string"),

    slideshow: _emberData["default"].belongsTo("slideshow"),

    relatedPostUrl: _emberData["default"].attr("string"),
    relatedPost: _emberData["default"].belongsTo("post", { inverse: null, async: false }),

    // relatedPost: DS.belongsTo('post', {async: true}),
    // relatedPostLoaded: function() {
    //   this.get('relatedPost').then(function(post) {
    //     return post;
    //   });
    // }.property('relatedPost'),

    maybeReplaceAppleMusicShortCode: function maybeReplaceAppleMusicShortCode(bodyPart) {
      var found = null;
      var regex = /\[INSERT_AM url=\"(.*?)\"\]/;

      if (found = bodyPart.match(regex)) {
        var config_url = _thefaderCmsEmberConfigEnvironment["default"]["legacyCmsBaseUrl"];

        var height = 650;
        var type = "song_id";
        var id = "";
        var url = found[1];
        var parts = url.split("/");
        var last_part = parts.pop();

        // debugger;

        last_part = last_part.split("&")[0];

        if (last_part.includes("?mt=1")) {
          last_part = last_part.replace("?mt=1", "");
        }

        // debugger;

        if (last_part.slice(0, 3) == "pl.") {
          id = last_part;
          type = "playlist_id";
        } else if (last_part.match("i=")) {
          id = last_part.split("i=").pop();
          type = "song_id";
          height = 155;
        } else {
          id = last_part;
          type = "album_id";
        }

        var iframe = "<iframe scrolling=\"no\" allow=\"autoplay *; encrypted-media *;\" frameborder=\"0\" height=\"" + height + "\" style=\"width:100%;max-width:660px;overflow:hidden;background:transparent;\" sandbox=\"allow-same-origin allow-forms allow-popups allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation\" src=\"" + config_url + "/embeds/apple_music/" + type + "/" + id + "\"></iframe>";

        // debugger;

        bodyPart = bodyPart.replace(/\[INSERT_AM url=\"(.*?)\"\]/, iframe);
        return bodyPart;
      } else {
        return bodyPart;
      }
    },

    formattedEmbed: (function () {
      var $this = this;
      if (this.get("embed")) {
        var embed = this.get("embed");
        embed = $this.maybeReplaceAppleMusicShortCode(embed);
        return embed;
      } else {
        return "";
      }
    }).property("embed"),

    formattedBody: (function () {
      var _this = this;

      var $this = this;
      if (this.get("body")) {
        var _ret = (function () {
          var body = "";
          body = _this.get("body").replace(/\n\r?/g, "<br />");
          var body_parts = body.split("<br />");

          var new_body_parts = [];
          body_parts.forEach(function (part, index) {
            part = $this.maybeReplaceAppleMusicShortCode(part);
            new_body_parts.push(part);
          });

          body_parts = new_body_parts.join("<br />");
          return {
            v: body_parts
          };
        })();

        if (typeof _ret === "object") return _ret.v;
      } else {
        return "";
      }
    }).property("body"),

    formattedMediaBody: (function () {
      if (this.get("mediaBody")) {
        return this.get("mediaBody").replace(/\n\r?/g, "<br />");
      } else {
        return "";
      }
    }).property("mediaBody"),

    formattedMediaBody2: (function () {
      if (this.get("mediaBody2")) {
        return this.get("mediaBody2").replace(/\n\r?/g, "<br />");
      } else {
        return "";
      }
    }).property("mediaBody2"),

    youtubeEmbedSrc: (function () {
      return "//www.youtube.com/embed/" + this.get("youtubeVideoId");
    }).property("videoUrl", "youtubeVideoId"),

    youtubeEmbedSrc2: (function () {
      return "//www.youtube.com/embed/" + this.get("youtubeVideoId2");
    }).property("videoUrl2", "youtubeVideoId2"),

    isEditing: false,

    image1HasError: (function () {
      return this.get("errors").has("imageCloudinaryPublicId");
    }).property("errors.[]"),

    slideshowHasError: (function () {
      return this.get("errors").has("slideshow");
    }).property("errors.[]"),

    hasErrors: (function () {
      //console.log("contentBlock: hasErrors");
      return this.get("errors.length") > 0;
    }).property("errors.length"),

    isText: (function () {
      return this.get("contentBlockType") === "text";
    }).property("contentBlockType"),

    isPullQuote: (function () {
      return this.get("contentBlockType") === "pull_quote";
    }).property("contentBlockType"),

    isBlockQuote: (function () {
      return this.get("contentBlockType") === "block_quote";
    }).property("contentBlockType")
  });
});