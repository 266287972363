define('thefader-cms-ember/components/published-posts-summary', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    ajax: _ember['default'].inject.service(),
    summaryText: "",

    didInsertElement: function didInsertElement() {
      var _this = this;
      this.get('ajax').request("/cms_api/v1/reports/summary").then(function (data) {
        console.log("data: ", data);
        _this.set("summaryText", data);
      });
    }
  });
});