define("thefader-cms-ember/router", ["exports", "ember", "thefader-cms-ember/config/environment"], function (exports, _ember, _thefaderCmsEmberConfigEnvironment) {

  var Router = _ember["default"].Router.extend({
    location: _thefaderCmsEmberConfigEnvironment["default"].locationType
  });

  Router.map(function () {
    this.route("about");

    this.route("posts", { resetNamespace: true }, function () {
      this.route("headlines", { path: "/headlines" });

      this.route("show", { path: ":post_id" }, function () {
        this.route("locked", { path: "/locked" });

        this.route("thumbnail", { resetNamespace: true }, function () {
          this.route("edit", { path: ":thumbnail_id" });
          this.route("preview", { path: "/preview/:thumbnail_id" });
        });

        this.route("thumbnailVersion", { resetNamespace: true }, function () {
          this.route("edit", { path: ":thumbnail_version_id" });
        });

        this.route("slideshow", { resetNamespace: true }, function () {
          this.route("index", { path: ":slideshow_id" });
        });

        this.route("content-blocks", { resetNamespace: true }, function () {
          this.route("new", { path: "/new" });
          this.route("delete", { path: "/delete/:content_block_id" });
        });
      });

      this.route("destroy", { path: "/destroy/:post_id" });
      this.route("unpublish", { path: "/unpublish/:post_id" });

      this.route("optimize", { path: "/:post_id/optimize" });
      this.route("optimizing", { path: "/:post_id/optimizing" });
      this.route("published", { path: "/:post_id/published" });
      this.route("preview", { path: "/:post_id/preview" });
    });

    this.route("login", { path: "/" });
    this.route("access_denied");

    this.route("daily-newsletters", function () {
      this.route("edit", { path: "/edit/:daily_newsletter_id" }, function () {
        this.route("daily-newsletter-item.delete", {
          path: "/daily-newsletter-item/delete/:daily_newsletter_item_id"
        });
      });
    });

    this.route("post-packages", function () {
      this.route("edit", { path: "/edit/:post_package_id" }, function () {
        this.route("post-package-item.delete", {
          path: "/post-package-item/delete/:post_package_item_id"
        });
      });
    });

    // this.route('smoketest');

    this.route("reports", function () {
      this.route("published-summary", { path: "/published-summary" });
      this.route("published-detail", { path: "/published-detail" });
      this.route("top-performing", { path: "/top-performing" });
      this.route("under-performing", { path: "/under-performing" });
      this.route("published-author", { path: "/published-author" });
      this.route("published-editor", { path: "/published-editor" });
      this.route("apple-music-stats", { path: "/apple-music-stats" });
      this.route("apple-music-embed-count", { path: "/apple-music-embed-count" });
    });
  });

  exports["default"] = Router;
});