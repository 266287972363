define("thefader-cms-ember/templates/content-blocks/-image2-field", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.0",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 6,
              "column": 2
            }
          },
          "moduleName": "thefader-cms-ember/templates/content-blocks/-image2-field.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "src", "/assets/images/spinner.gif");
          dom.setAttribute(el1, "width", "20");
          dom.setAttribute(el1, "height", "20");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 48,
            "column": 0
          }
        },
        "moduleName": "thefader-cms-ember/templates/content-blocks/-image2-field.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "image-dropzone");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "style", "margin-top: 20px;");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "upload_button_holder");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn btn-success");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "glyphicon glyphicon-plus");
        dom.setAttribute(el5, "style", "font-size:20px;");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Upload\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-group");
        dom.setAttribute(el1, "style", "padding-top: 10px;");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-3");
        dom.setAttribute(el3, "style", "padding-top: 5px;");
        var el4 = dom.createTextNode("\n      Animated Gif?\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-9");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-2");
        dom.setAttribute(el3, "style", "padding-top: 5px;");
        var el4 = dom.createTextNode("\n      Image Credit\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-10");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        dom.setAttribute(el2, "style", "margin-top: 20px;");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-2");
        dom.setAttribute(el3, "style", "padding-top: 5px;");
        var el4 = dom.createTextNode("\n      Via\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-10");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(fragment, [4]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createAttrMorph(element1, 'class');
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [1]), 3, 3);
        morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [1, 3]), 1, 1);
        morphs[6] = dom.createMorphAt(dom.childAt(element2, [3, 3]), 1, 1);
        morphs[7] = dom.createMorphAt(dom.childAt(element2, [5, 3]), 1, 1);
        return morphs;
      },
      statements: [["inline", "cloudinary-tag", [["get", "model.imageCloudinaryPublicId2", ["loc", [null, [2, 19], [2, 49]]], 0, 0, 0, 0]], ["width", 200, "uploadType", ["subexpr", "@mut", [["get", "model.uploadType2", ["loc", [null, [2, 71], [2, 88]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [2, 90]]], 0, 0], ["block", "if", [["get", "image2Loading", ["loc", [null, [4, 8], [4, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 2], [6, 9]]]], ["attribute", "class", ["concat", ["form-group ", ["subexpr", "if", [["get", "image2HasError", ["loc", [null, [8, 30], [8, 44]]], 0, 0, 0, 0], "required-alert"], [], ["loc", [null, [8, 25], [8, 63]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "cloudinary-uploader", [], ["dropZone", "image-dropzone", "start", "showSpinner2", "done", "image2Finished"], ["loc", [null, [14, 6], [14, 98]]], 0, 0], ["inline", "form-field", [], ["value", ["subexpr", "@mut", [["get", "model.caption2", ["loc", [null, [20, 19], [20, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "textarea", "placeholder", "Add a caption...", "object", ["subexpr", "@mut", [["get", "model", ["loc", [null, [20, 88], [20, 93]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [20, 0], [20, 95]]], 0, 0], ["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "model.imageIsAnimatedGif2", ["loc", [null, [28, 38], [28, 63]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [28, 6], [28, 65]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "model.credit2", ["loc", [null, [36, 20], [36, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-control", "placeholder", ".."], ["loc", [null, [36, 6], [36, 73]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "model.attribution2", ["loc", [null, [44, 20], [44, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-control", "placeholder", "Where did you find it (URL)?"], ["loc", [null, [44, 6], [44, 104]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});