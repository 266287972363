define('thefader-cms-ember/components/fader-date-select', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    selectedYear: _ember['default'].computed('date', function () {
      return moment(this.get('date')).year();
    }),
    selectedMonth: _ember['default'].computed('date', function () {
      return moment(this.get('date')).month() + 1;
    }),
    selectedDay: _ember['default'].computed('date', function () {
      return moment(this.get('date')).date();
    }),

    months: (function () {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }).property(),

    days: (function () {
      var days = [];
      for (var i = 0; i < 32; i++) {
        days.push(i);
      }
      return days;
    }).property(),

    years: (function () {
      var startYear = 2016;
      var years = [];
      for (var i = startYear; i < startYear + 2; i++) {
        years.push(i);
      }
      return years;
    }).property(),

    actions: {
      dayChanged: function dayChanged(value) {
        // console.log("dayChanged: ", value);
        var date = moment(this.get('date'));
        var newDate = new Date(date.year(), date.month(), value);
        this.set('date', newDate);
      },

      monthChanged: function monthChanged(value) {
        var date = moment(this.get('date'));
        var newDate = new Date(date.year(), value - 1, date.date());
        this.set('date', newDate);
      },

      yearChanged: function yearChanged(value) {
        var date = moment(this.get('date'));
        var newDate = new Date(value, date.month(), date.date());
        this.set('date', newDate);
      }
    }

  });
});