define('thefader-cms-ember/controllers/application', ['exports', 'ember', 'thefader-cms-ember/config/environment', 'thefader-cms-ember/controllers/base'], function (exports, _ember, _thefaderCmsEmberConfigEnvironment, _thefaderCmsEmberControllersBase) {
  exports['default'] = _thefaderCmsEmberControllersBase['default'].extend({
    sessionCurrentAdmin: _ember['default'].inject.service('session-current-admin'),

    legacyCmsLink: (function () {
      var base = _thefaderCmsEmberConfigEnvironment['default']['legacyCmsBaseUrl'];
      // debugger;
      var url = base + "/cms";
      return url;
    }).property(),

    pinnedPostsLink: (function () {
      var base = _thefaderCmsEmberConfigEnvironment['default']['legacyCmsBaseUrl'];
      var url = base + "/cms/pinned_posts";
      return url;
    }).property(),

    postCollectionsLink: (function () {
      var base = _thefaderCmsEmberConfigEnvironment['default']['legacyCmsBaseUrl'];
      var url = base + "/cms/post_collections";
      return url;
    }).property(),

    emailNewsletterLink: (function () {
      var base = _thefaderCmsEmberConfigEnvironment['default']['legacyCmsBaseUrl'];
      var url = base + "/cms/daily_newsletters";
      return url;
    }).property(),

    currentAdminContributorId: (function () {
      if (this.get('session').get('currentAdmin')) {
        // debugger;
        if (this.get('session').get('currentAdmin')['contributorIdCached']) {
          return this.get('session').get('currentAdmin')['contributorIdCached'];
        } else {
          return "";
        }
      } else {
        return "";
      }
    }).property(),

    config: (function () {
      return _thefaderCmsEmberConfigEnvironment['default'].APP;
    }).property('ENV')

  });
});