define('thefader-cms-ember/components/published-posts-chart', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    data: [],

    dataChanged: _ember['default'].observer('data', function () {
      console.log("dataChanged");
      this.draw(this.get('data'));
    }),

    didInsertElement: function didInsertElement() {
      // this.draw(this.get('data'));
    },

    draw: function draw(data) {
      console.log("draw");

      var target_width = 800;
      var target_height = 300;

      var margin = { top: 20, right: 20, bottom: 50, left: 70 };
      var width = target_width - margin.left - margin.right;
      var height = target_height - margin.top - margin.bottom;

      // 2017-12-11T21:00:00.000-08:00
      var parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S.%L%Z");

      if (data == null) {
        return;
      }

      if (_ember['default'].keys(data).length === 0) {
        return;
      }

      data.forEach(function (d) {
        // debugger;
        d.date = parseTime(d.date);
        d.count = +d.count;
        console.log("date, count: ", d.date, d.count);
      });

      // debugger;

      var x = d3.scaleTime().range([0, width]);
      var y = d3.scaleLinear().range([height, 0]);

      // define the line
      var count_line = d3.line().x(function (d) {
        return x(d.date);
      }).y(function (d) {
        return y(d.count);
      });

      d3.select("svg").remove();

      var svg = d3.select(this.element).append("svg").attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom).append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // Scale the range of the data
      x.domain(d3.extent(data, function (d) {
        return d.date;
      }));
      y.domain([0, d3.max(data, function (d) {
        return d.count;
      })]);

      // Add the valueline path.
      svg.append("path").data([data]).attr("class", "line").attr("d", count_line);

      // Add the x Axis

      var x_axis = d3.axisBottom(x);

      if (data.length == 7) {
        x_axis.ticks(7);
      } else {
        x_axis.ticks(4);
      }

      svg.append("g").attr("transform", "translate(0," + height + ")").call(x_axis);

      // text label for the x axis
      svg.append("text").attr("transform", "translate(" + width / 2 + " ," + (height + margin.top + 20) + ")").style("text-anchor", "middle").text("Date");

      // Add the y Axis
      svg.append("g").call(d3.axisLeft(y));

      // text label for the y axis
      svg.append("text").attr("transform", "rotate(-90)").attr("y", 0 - margin.left).attr("x", 0 - height / 2).attr("dy", "1em").style("text-anchor", "middle").text("Post Count");
    }
  });
});