define('thefader-cms-ember/services/ajax', ['exports', 'ember', 'ember-ajax/services/ajax'], function (exports, _ember, _emberAjaxServicesAjax) {
  exports['default'] = _emberAjaxServicesAjax['default'].extend({
    session: _ember['default'].inject.service(),
    headers: _ember['default'].computed('session.authenticated.admin_id', {
      get: function get() {
        var headers = {};

        this.get('session').authorize('authorizer:devise', function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });

        return headers;
      }
    })
  });
});