define('thefader-cms-ember/components/post-package-items', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {

      var _this = this;

      _ember['default'].run.later(function () {

        _this.$(".sortable").sortable({

          update: function update(event, ui) {
            //console.log("sortable update called");

            var indexes = {};

            // debugger;

            $(this).find('.edit-form-content-block').each(function (index) {
              indexes[$(this).data('id')] = index;
            });

            // $(_this).sortable('cancel');

            _this.updateSortOrder(indexes);
          }
        });
      });
    },

    updateSortOrder: function updateSortOrder(indexes) {

      //console.log("updateSortOrder:");
      //console.log(indexes);

      var _this = this;
      var promises = [];

      this.beginPropertyChanges();

      // this.forEach(function(itemController) {
      this.get('model').forEach(function (item) {

        //console.log("updating item: ", item.get('id'));

        // debugger;
        // var item = itemController.get('item');

        var index = indexes[item.get('id')];
        index = index + 1; // so we're not zero indexed

        item.set('position', index);
        item.set('skipValidation', true);
        promises.push(item.save());
      }, this);

      _ember['default'].RSVP.all(promises).then(function (items) {
        console.log("finished updating");
        _this.endPropertyChanges();
      });

      // this.endPropertyChanges();
    }
  });
});