define('thefader-cms-ember/models/post-package', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    active: _emberData['default'].attr('boolean'),

    postPackageItems: _emberData['default'].hasMany('postPackageItem', { async: true, inverse: 'postPackage' }),
    postPackageItemsSorting: ['position:asc'],
    postPackageItemsSorted: Ember.computed.sort('postPackageItems', 'postPackageItemsSorting')

  });
});