define("thefader-cms-ember/controllers/posts/index", ["exports", "ember", "thefader-cms-ember/config/environment", "thefader-cms-ember/controllers/base", "moment"], function (exports, _ember, _thefaderCmsEmberConfigEnvironment, _thefaderCmsEmberControllersBase, _moment) {
  exports["default"] = _thefaderCmsEmberControllersBase["default"].extend({
    ajax: _ember["default"].inject.service(),

    moment: _ember["default"].inject.service(),

    sessionCurrentAdmin: _ember["default"].inject.service("session-current-admin"),

    // date range properties

    // start_date: null,
    // end_date: null,

    // start_date: moment.tz('America/New_York').startOf('day'),
    // end_date: moment.tz('America/New_York').startOf('day').add(2, 'days'),

    presets: [{
      name: "Today",
      startDate: _moment["default"].tz("America/New_York").startOf("day"),
      endDate: _moment["default"].tz("America/New_York").endOf("day")
    }, {
      name: "This Week",
      startDate: _moment["default"].tz("America/New_York").startOf("week"),
      endDate: _moment["default"].tz("America/New_York").endOf("week").startOf("day")
    }, {
      name: "This Month",
      startDate: _moment["default"].tz("America/New_York").startOf("month"),
      endDate: _moment["default"].tz("America/New_York").endOf("month").startOf("day")
    }, {
      name: "Last Week",
      startDate: _moment["default"].tz("America/New_York").startOf("week").subtract(1, "week"),
      endDate: _moment["default"].tz("America/New_York").endOf("week").subtract(1, "week").startOf("day")
    }, {
      name: "Last Month",
      startDate: _moment["default"].tz("America/New_York").startOf("month").subtract(1, "month"),
      endDate: _moment["default"].tz("America/New_York").endOf("month").subtract(1, "month").startOf("day")
    }],

    multiSiteEnabled: (function () {
      return _thefaderCmsEmberConfigEnvironment["default"].APP.ENABLE_MULTI_SITE;
    }).property(),

    // setup our query params
    queryParams: ["page", "perPage", "search_terms", "contributor_id", "contributor_name", "country_id", "published_state", "sensitive", "article_type", "partner", "category", "vertical_ids", "genre_ids", "start_date", "end_date"],

    // binding the property on the paged array
    // to the query params on the controller
    // pageBinding: "content.page",
    // perPageBinding: "content.perPage",
    // totalPagesBinding: "content.totalPages",

    // set default values, can cause problems if left out
    // if value matches default, it won't display in the URL
    page: 1,
    perPage: 10,
    contributor_id: "",
    country_id: "",
    published_state: "",
    sensitive: false,
    article_type: "",
    partner: false,
    category: "",
    vertical_ids: [],
    genre_ids: [],

    selectedVerticals: [],
    isMusicVerticalSelected: (function () {
      return this.get("selectedVerticals").mapBy("name").contains("MUSIC");
    }).property("selectedVerticals.length"),

    isStyleVerticalSelected: (function () {
      return this.get("selectedVerticals").mapBy("name").contains("STYLE");
    }).property("selectedVerticals.length"),

    isCultureVerticalSelected: (function () {
      return this.get("selectedVerticals").mapBy("name").contains("CULTURE");
    }).property("selectedVerticals.length"),

    selectedGenres: [],

    sort_by_date: false,

    articleTypes: (function () {
      return _thefaderCmsEmberConfigEnvironment["default"].post_article_types;
    }).property(),

    selectedIsMy: (function () {
      return this.get("country_id") === "" && this.get("contributor_id") != "";
    }).property("contributor_id", "country_id"),

    selectedIsAllPublished: (function () {
      return this.get("published_state") === "Published";
    }).property("published_state"),

    selectedIsAllDrafts: (function () {
      return this.get("published_state") === "Draft";
    }).property("published_state"),

    selectedIsUs: (function () {
      var us = this.get("countries").filter(function (item) {
        if (item.get("isUs")) {
          return true;
        }
      }).get("firstObject");
      return us.get("id") === this.get("country_id") && this.get("contributor_id") === "";
    }).property("country_id"),

    selectedIsUk: (function () {
      var uk = this.get("countries").filter(function (item) {
        if (item.get("isUk")) {
          return true;
        }
      }).get("firstObject");
      return uk.get("id") === this.get("country_id") && this.get("contributor_id") === "";
    }).property("country_id"),

    actions: {
      selectCountry: function selectCountry(country_id) {
        this.set("country_id", country_id);
      },

      createPost: function createPost() {
        //console.log("create a post");

        var _this = this;

        var post = this.store.createRecord("post", {
          title: "work in progress draft",
          publishedState: "Draft",
          articleType: "simple"
        });

        var currentAdmin = _this.get("sessionCurrentAdmin.admin");
        // debugger;

        post.save().then(function () {
          if (currentAdmin.get("contributor")) {
            var postCredit = _this.store.createRecord("postCredit");
            postCredit.set("contributorName", currentAdmin.get("contributor").get("displayName"));
            postCredit.set("type", "Author");
            postCredit.set("post", post);
            postCredit.save();
          }

          _this.transitionToRoute("posts.show", post.get("id"));
        });
      },

      doSearch: function doSearch() {
        console.log("doSearch");

        if (this.get("startDate") && this.get("endDate")) {
          this.set("start_date", this.get("startDate").toJSON());
          this.set("end_date", this.get("endDate").endOf("day").toJSON());
        }

        this.send("refreshSearch");
      },

      doExport: function doExport() {
        //console.log("doSearch");
        var url = "/cms_api/v1/posts/export";

        // debugger;

        var query_params = window.location.search;
        url += query_params;

        var _this = this;

        this.get("ajax").post(url).then(function (data) {
          console.log("export finished");
          var drop_box_url = _thefaderCmsEmberConfigEnvironment["default"]["legacyCmsBaseUrl"] + data.drop_box_url;
          _this.set("infoMessage", 'Export pending. <a target="new" href="' + drop_box_url + '">Head to your drop box for pickup here.</a>');
        });
      },

      showMyPosts: function showMyPosts() {
        //console.log("showMyPosts");
        var currentAdmin = this.get("sessionCurrentAdmin.admin");
        // debugger;
        if (currentAdmin.get("contributor")) {
          //console.log('i have contributor');
          // debugger;
          var contributorId = currentAdmin.get("contributor").get("id");
          this.set("contributor_id", contributorId);
          // this.set('country_id', "");
          this.set("published_state", "");
        }
      },

      showAllPublishedPosts: function showAllPublishedPosts() {
        this.set("contributor_id", "");
        // this.set('country_id', "");
        this.set("published_state", "Published");
      },

      showAllDraftPosts: function showAllDraftPosts() {
        this.set("contributor_id", "");
        // this.set('country_id', "");
        this.set("published_state", "Draft");
      },

      showUsPosts: function showUsPosts() {
        //console.log("showUsPosts");
        var us = this.get("countries").filter(function (item, index, self) {
          if (item.get("isUs")) {
            return true;
          }
        }).get("firstObject");

        //console.log("us: ", us);

        this.set("contributor_id", "");
        this.set("country_id", us.get("id"));
      },

      showUkPosts: function showUkPosts() {
        //console.log("showUkPosts");

        var uk = this.get("countries").filter(function (item, index, self) {
          if (item.get("isUk")) {
            return true;
          }
        }).get("firstObject");

        //console.log("uk: ", uk);

        this.set("contributor_id", "");
        this.set("country_id", uk.get("id"));
      },

      setSortBy: function setSortBy() {
        var sortBy = !this.get("sort_by_date");
        this.set("sort_by_date", sortBy);
        console.log("setSortBy: ", sortBy);
      },

      selectArticleType: function selectArticleType(value) {
        console.log("selectArticleType: ", value);
        this.set("article_type", value);
      },

      selectCategory: function selectCategory(category) {
        this.set("category", category);
      },

      selectFormat: function selectFormat(format) {
        this.set("format", format);
      },

      verticalChanged: function verticalChanged(verticalId, checked) {
        console.log("verticalChanged", verticalId);

        var vertical = this.get("store").peekRecord("vertical", verticalId);
        var selectedVerticals = this.get("selectedVerticals");
        var vertical_ids = this.get("vertical_ids");
        if (checked) {
          selectedVerticals.pushObject(vertical);
          vertical_ids.pushObject(verticalId);
        } else {
          selectedVerticals.removeObject(vertical);
          vertical_ids.removeObject(verticalId);
        }
      },

      musicGenreChanged: function musicGenreChanged(musicGenreId, checked) {
        console.log("musicGenreChanged", musicGenreId);
        var musicGenre = this.get("store").peekRecord("musicGenre", musicGenreId);
        var genres = this.get("selectedGenres");
        var genre_ids = this.get("genre_ids");
        if (checked) {
          genres.pushObject(musicGenre);
          genre_ids.pushObject(musicGenreId);
        } else {
          genres.removeObject(musicGenre);
          genre_ids.removeObject(musicGenreId);
        }
      },

      selectField: function selectField(field_name) {
        console.log("selectField");
        this.set("field_name", field_name);
      },

      apply: function apply() {
        console.log("apply");
        // var endDate = this.get('end');
        // this.set('end', endDate.endOf('day'));
        // this.getData();
      }
    }
  });
});