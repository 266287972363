define('thefader-cms-ember/models/headline', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    post: _emberData['default'].belongsTo('post'),
    title: _emberData['default'].attr('string'),
    headlineServiceIsWinner: _emberData['default'].attr('boolean'),

    titleIfWinner: (function () {
      if (this.get('headlineServiceIsWinner')) {
        return this.get('post.title');
      } else {
        return this.get('title');
      }
    }).property('headlineServiceIsWinner')
  });
});