define("thefader-cms-ember/serializers/post", ["exports", "ember-data", "active-model-adapter"], function (exports, _emberData, _activeModelAdapter) {
  exports["default"] = _activeModelAdapter.ActiveModelSerializer.extend(_emberData["default"].EmbeddedRecordsMixin).extend({
    isNewSerializerAPI: true,

    shouldReloadAll: function shouldReloadAll() {
      return true;
    },

    attrs: {
      verticals: { serialize: "ids", deserialize: "ids" },
      musicGenres: { serialize: "ids", deserialize: "ids" },
      styleGenres: { serialize: "ids", deserialize: "ids" },
      cultureGenres: { serialize: "ids", deserialize: "ids" },
      countries: { serialize: "ids", deserialize: "ids" },
      permalink: { serialize: false }
    }
  });
});