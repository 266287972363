define('thefader-cms-ember/models/thumbnail', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    cloudinaryPublicId: _emberData['default'].attr('string'),
    uploadType: _emberData['default'].attr('string'),

    post: _emberData['default'].belongsTo('post', { async: true }),

    originalWidth: _emberData['default'].attr('number'),
    originalHeight: _emberData['default'].attr('number'),
    isAnimatedGif: _emberData['default'].attr('boolean'),
    modelVersion: _emberData['default'].attr('number'),

    // http://res.cloudinary.com/thefader/image/upload/c_fit,h_535,w_200/ypsykdcx3mthu92pcaqv
    cloudinaryPublicUrl: (function () {

      var publicId = this.get('cloudinaryPublicId');

      // debugger;

      if (this.get('isModelVersion1')) {
        var url = "http://res.cloudinary.com/thefader/image/upload/c_fit,w_800,h_500/" + publicId;
      } else {
        var url = "http://res.cloudinary.com/thefader/image/" + this.get('uploadType') + "/c_limit,w_1024/" + publicId;
      }

      return url;
    }).property('cloudinaryPublicId'),

    thumbnailVersions: _emberData['default'].hasMany('thumbnailVersion', { async: true }),

    normalVersion: (function () {
      return this.get('thumbnailVersions').findBy('name', 'normal');
    }).property('thumbnailVersions.@each.name'),

    bigVersion: (function () {
      return this.get('thumbnailVersions').findBy('name', 'big');
    }).property('thumbnailVersions.@each.name'),

    wideVersion: (function () {
      return this.get('thumbnailVersions').findBy('name', 'wide');
    }).property('thumbnailVersions.@each.name'),

    isModelVersion1: (function () {
      return this.get('modelVersion') == 1;
    }).property('modelVersion'),

    isModelVersion2: (function () {
      return this.get('modelVersion') == 2;
    }).property('modelVersion')

  });
});