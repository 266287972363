define('thefader-cms-ember/app', ['exports', 'ember', 'ember/resolver', 'ember/load-initializers', 'thefader-cms-ember/config/environment'], function (exports, _ember, _emberResolver, _emberLoadInitializers, _thefaderCmsEmberConfigEnvironment) {

  var App;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;
  App = _ember['default'].Application.extend({
    modulePrefix: _thefaderCmsEmberConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _thefaderCmsEmberConfigEnvironment['default'].podModulePrefix,
    Resolver: _emberResolver['default'],
    customEvents: {
      paste: 'paste'
    }
  });

  (0, _emberLoadInitializers['default'])(App, _thefaderCmsEmberConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});