define('thefader-cms-ember/pods/range/helper', ['exports', 'date-range-picker/range/helper'], function (exports, _dateRangePickerRangeHelper) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _dateRangePickerRangeHelper['default'];
    }
  });
  Object.defineProperty(exports, 'range', {
    enumerable: true,
    get: function get() {
      return _dateRangePickerRangeHelper.range;
    }
  });
});