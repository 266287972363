define('thefader-cms-ember/initializers/pusher', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'pusher-service', 'service:pusher-service');
    application.inject('controller', 'pusher-service', 'service:pusher-service');
    application.inject('component', 'pusher-service', 'service:pusher-service');
  }

  exports['default'] = {
    name: 'pusher-service',
    initialize: initialize
  };
});