define("thefader-cms-ember/templates/posts/_buzzfeed_tabs", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 53,
            "column": 5
          }
        },
        "moduleName": "thefader-cms-ember/templates/posts/_buzzfeed_tabs.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("ul");
        dom.setAttribute(el1, "id", "suplist_omnibox_tabs");
        dom.setAttribute(el1, "class", "c suplist_omnibox_tabs");
        dom.setAttribute(el1, "rel:gt_cat", "[ttp]:omni-box");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "id", "suplist_new_pullquote");
        dom.setAttribute(el3, "class", "fa fa-quote-left fa-align-left bf_dom suplist_tab suplist_tab_quote hidden");
        dom.setAttribute(el3, "rel:bf_bucket_data", "{'longform_insert_pullquote':{}, 'usertip_hover':{'id':'content_type_pullquote'}}");
        dom.setAttribute(el3, "title", "Add A Pullquote");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "id", "suplist_new_text");
        dom.setAttribute(el3, "class", "fa fa-lg fa-align-left bf_dom suplist_tab suplist_tab_text selected");
        dom.setAttribute(el3, "rel:gt_act", "text");
        dom.setAttribute(el3, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'text', 'tab':'text'}, 'usertip_hover':{'id':'content_type_text'}}");
        dom.setAttribute(el3, "title", "");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "id", "suplist_new_image");
        dom.setAttribute(el3, "class", "fa fa-lg fa-picture-o bf_dom suplist_tab suplist_tab_image");
        dom.setAttribute(el3, "rel:gt_act", "image");
        dom.setAttribute(el3, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'image', 'tab':'image'}, 'usertip_hover':{'id':'content_type_image'}}");
        dom.setAttribute(el3, "title", "");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "id", "suplist_new_video");
        dom.setAttribute(el3, "class", "fa fa-lg fa-video-camera bf_dom suplist_tab suplist_tab_video");
        dom.setAttribute(el3, "rel:gt_act", "video");
        dom.setAttribute(el3, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'video', 'tab':'video'}, 'usertip_hover':{'id':'content_type_video'}}");
        dom.setAttribute(el3, "title", "");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "id", "suplist_new_embed");
        dom.setAttribute(el3, "class", "fa fa-lg fa-puzzle-piece bf_dom suplist_tab suplist_tab_embed");
        dom.setAttribute(el3, "rel:gt_act", "embed");
        dom.setAttribute(el3, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'embed', 'tab':'embed'}, 'usertip_hover':{'id':'content_type_embed'}}");
        dom.setAttribute(el3, "title", "");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "id", "suplist_new_link");
        dom.setAttribute(el3, "class", "fa fa-lg fa-link bf_dom suplist_tab suplist_tab_link qp_moderated");
        dom.setAttribute(el3, "rel:gt_act", "link");
        dom.setAttribute(el3, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'link', 'tab':'link'}, 'usertip_hover':{'id':'content_type_link'}}");
        dom.setAttribute(el3, "title", "");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        dom.setAttribute(el2, "class", "acl_control quiz_create poll_create");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "id", "suplist_new_quiz");
        dom.setAttribute(el3, "class", "text-list-item suplist_tab suplist_tab_quiz bf_dom");
        dom.setAttribute(el3, "rel:bf_bucket_data", "{'suplist_show_quiz_menu':{}, 'suplist_hide_quiz_menu':{}}");
        dom.setAttribute(el3, "title", "Add a Quiz");
        var el4 = dom.createTextNode("QUIZ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3, "id", "suplist_quiz_menu");
        dom.setAttribute(el3, "style", "left: 209px; top: 62px; display: none;");
        dom.setAttribute(el3, "class", "bf_dom");
        dom.setAttribute(el3, "rel:bf_bucket_data", "{'usertip_hover':{'id':'content_type_quiz'},'suplist_show_quiz_menu':{}, 'suplist_hide_quiz_menu':{}}");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "id", "quiz_arrowBorder");
        dom.setAttribute(el4, "class", "arrow");
        dom.setAttribute(el4, "style", "left: 53px; top: -8px;");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "id", "quiz_arrowFill");
        dom.setAttribute(el4, "class", "arrow");
        dom.setAttribute(el4, "style", "left: 54px; top: -15px;");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "id", "quiz_menu_wrapper");
        dom.setAttribute(el4, "class", "");
        var el5 = dom.createTextNode("\n	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        var el6 = dom.createTextNode("Select Quiz Type:");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "acl_control quiz_create");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "bf_dom");
        dom.setAttribute(el6, "rel:gt_act", "quiz-trivia");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'quiz', 'quiz_type':'standard', 'tab':'quiz'}, 'usertip_hover':{'id':'content_type_quiz'}}");
        var el7 = dom.createTextNode("Trivia");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "acl_control quiz_create");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "bf_dom");
        dom.setAttribute(el6, "rel:gt_act", "quiz-single-question");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'quiz', 'quiz_type':'single question', 'tab':'quiz'}, 'usertip_hover':{'id':'content_type_quiz'}}");
        var el7 = dom.createTextNode("Single Question");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "acl_control quiz_create");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "bf_dom");
        dom.setAttribute(el6, "rel:gt_act", "quiz-%correct");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'quiz', 'quiz_type':'percentage', 'tab':'quiz'}, 'usertip_hover':{'id':'content_type_quiz'}}");
        var el7 = dom.createTextNode("% Correct");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "acl_control quiz_create");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "bf_dom");
        dom.setAttribute(el6, "rel:gt_act", "quiz-personality");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'quiz', 'quiz_type':'personality', 'tab':'quiz'}, 'usertip_hover':{'id':'content_type_quiz'}}");
        var el7 = dom.createTextNode("Personality");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "acl_control quiz_create");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "bf_dom");
        dom.setAttribute(el6, "rel:gt_act", "quiz-checklist");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'quiz', 'quiz_type':'checklist', 'tab':'quiz'}, 'usertip_hover':{'id':'content_type_quiz'}}");
        var el7 = dom.createTextNode("Checklist");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "acl_control quiz_create");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "bf_dom");
        dom.setAttribute(el6, "rel:gt_act", "quiz-image-checklist");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'quiz', 'quiz_type':'image checklist', 'tab':'quiz'}, 'usertip_hover':{'id':'content_type_quiz'}}");
        var el7 = dom.createTextNode("Image Checklist");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "acl_control polls_create");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "bf_dom");
        dom.setAttribute(el6, "rel:gt_act", "quiz-poll");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'quiz', 'quiz_type':'poll', 'tab':'quiz'}, 'usertip_hover':{'id':'content_type_quiz'}}");
        var el7 = dom.createTextNode("Poll");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        dom.setAttribute(el2, "class", "acl_control general_admin");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "id", "suplist_more");
        dom.setAttribute(el3, "class", "text-list-item suplist_tab suplist_tab_more bf_dom");
        dom.setAttribute(el3, "rel:bf_bucket_data", "{'suplist_show_more_menu':{}, 'suplist_hide_more_menu':{}}");
        dom.setAttribute(el3, "title", "Add Another Buzz");
        var el4 = dom.createTextNode("MORE");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3, "id", "suplist_more_menu");
        dom.setAttribute(el3, "style", "display: none;");
        dom.setAttribute(el3, "class", "bf_dom");
        dom.setAttribute(el3, "rel:bf_bucket_data", "{'suplist_show_more_menu':{}, 'suplist_hide_more_menu':{}}");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "id", "more_arrowBorder");
        dom.setAttribute(el4, "class", "arrow");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "id", "more_arrowFill");
        dom.setAttribute(el4, "class", "arrow");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "id", "more_menu_wrapper");
        dom.setAttribute(el4, "class", "");
        var el5 = dom.createTextNode("\n	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "id", "suplist_new_article_update");
        dom.setAttribute(el6, "rel:gt_act", "more-update");
        dom.setAttribute(el6, "class", "bf_dom acl_control general_admin");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'article_update', 'tab':'more'}, 'usertip_hover':{'id':'content_type_article_update'}}");
        var el7 = dom.createTextNode("Update");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "id", "suplist_new_article_correction");
        dom.setAttribute(el6, "rel:gt_act", "more-correction");
        dom.setAttribute(el6, "class", "bf_dom acl_control general_admin");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'article_correction', 'tab':'more'}, 'usertip_hover':{'id':'content_type_article_correction'}}");
        var el7 = dom.createTextNode("Correction");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "acl_control photo_slider");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "id", "suplist_new_photoslider");
        dom.setAttribute(el6, "rel:gt_act", "more-photo-slider");
        dom.setAttribute(el6, "class", "bf_dom");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'photoslider', 'tab':'more'}, 'usertip_hover':{'id':'content_type_photoslider'}}");
        var el7 = dom.createTextNode("Photo Slider");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "id", "suplist_new_picmixer");
        dom.setAttribute(el6, "rel:gt_act", "more-picmixer");
        dom.setAttribute(el6, "class", "bf_dom acl_control picmixer_admin");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'picmixer', 'tab':'more'}, 'usertip_hover':{'id':'content_type_picmixer'}}");
        var el7 = dom.createTextNode("Picmixer");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n	");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "qp_p_admin");
        var el6 = dom.createTextNode("\n	  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "id", "suplist_new_recipe");
        dom.setAttribute(el6, "class", "bf_dom suplist_tab suplist_tab_recipe");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'recipe', 'tab':'more'}, 'usertip_hover':{'id':'content_type_recipe'}}");
        dom.setAttribute(el6, "title", "Post recipe");
        var el7 = dom.createTextNode("Recipe");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n	  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "id", "pie_chart");
        dom.setAttribute(el6, "rel:gt_act", "more-pie-chart");
        dom.setAttribute(el6, "class", "bf_dom acl_control general_admin");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'chart', 'chart_type':'pie', 'tab':'more'}}");
        var el7 = dom.createTextNode("Pie Chart");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n	  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "id", "bar_chart");
        dom.setAttribute(el6, "rel:gt_act", "more-bar-chart");
        dom.setAttribute(el6, "class", "bf_dom acl_control general_admin");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'barchart', 'chart_type':'bar', 'tab':'more'}}");
        var el7 = dom.createTextNode("Bar Chart");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n	  ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "id", "venn_chart");
        dom.setAttribute(el6, "rel:gt_act", "more-venn-diagram");
        dom.setAttribute(el6, "class", "bf_dom acl_control general_admin");
        dom.setAttribute(el6, "rel:bf_bucket_data", "{'suplist_open_module':{'item_type':'vennchart', 'chart_type':'venn', 'tab':'more'}}");
        var el7 = dom.createTextNode("Venn Diagram");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" START: not actually a menu items but opened tab indicators ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "suplist_new_article_correction text-list-item hidden selected");
        var el4 = dom.createTextNode("Correction");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "suplist_new_article_update text-list-item hidden selected");
        var el4 = dom.createTextNode("Update");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" END: not actually a menu items but opened tab indicators ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        dom.setAttribute(el2, "class", "right qp_p_admin");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "id", "suplist_new_daylife");
        dom.setAttribute(el3, "rel:gt_act", "wire");
        dom.setAttribute(el3, "class", "text-list-item bf_dom suplist_tab suplist_tab_daylife");
        dom.setAttribute(el3, "rel:bf_bucket_data", "{'daylife_open':{}, 'usertip_hover':{'id':'content_type_daylife'}}");
        dom.setAttribute(el3, "title", "Add AP, Getty, and Reuters Photo from Daylife");
        var el4 = dom.createTextNode("WIRE");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("li");
        dom.setAttribute(el2, "class", "right acl_control use_twitter_wizard");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "id", "suplist_new_twitter");
        dom.setAttribute(el3, "rel:gt_act", "twitter");
        dom.setAttribute(el3, "class", "fa fa-lg fa-twitter bf_dom suplist_tab suplist_tab_twitter");
        dom.setAttribute(el3, "rel:bf_bucket_data", "{'twitter_open':{}, 'usertip_hover':{'id':'content_type_twitter'}}");
        dom.setAttribute(el3, "title", "Add Twitts");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});