define("thefader-cms-ember/templates/post-credits/-new", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 6
          }
        },
        "moduleName": "thefader-cms-ember/templates/post-credits/-new.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "add-user-form");
        var el2 = dom.createTextNode("\n  \n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "href", "#");
        dom.setAttribute(el2, "class", "add-user-icon");
        var el3 = dom.createElement("span");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" \n  <div class=\"\" id=\"add-user-ui\">\n    <input type=\"text\" class=\"bf_dom\" rel:bf_bucket=\"search_add_user\" id=\"search-add-user\" name=\"search_user\" placeholder=\"Type username\" autocomplete=\"on\">\n    <a href=\"#\" class=\"add-user-button\">Add</a>\n  </div>\n\n  <div id=\"add-user-spinner\" class=\"qp-add-user-spinner hidden\">\n    <img src=\"http://s3-ak.buzzfed.com/static/images/public/spinners/big_on_eee.gif\">\n  </div>\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  \n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(1);
        morphs[0] = dom.createElementMorph(element0);
        return morphs;
      },
      statements: [["element", "action", ["edit"], [], ["loc", [null, [3, 5], [3, 22]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});