define('thefader-cms-ember/controllers/posts/unpublish', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      cancel: function cancel() {
        var post = this.get("model");
        this.transitionTo('posts', { queryParams: { contributor_id: this.get('session').get('contributor_id') } });
      },

      unpublish: function unpublish() {
        var post = this.get('model');
        var _this = this;
        post.set('publishedState', 'Draft');
        post.save().then(function () {
          _this.transitionTo('posts', { queryParams: { contributor_id: _this.get('session').get('contributor_id') } });
        });
      }
    }
  });
});