define('thefader-cms-ember/components/slideshow-upload-additional-files-control', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    tagName: 'input',
    name: 'file',
    attributeBindings: ['name', 'type', 'data-form-data'],
    type: 'file',
    classNames: ['cloudinary-fileupload'],

    didInsertElement: function didInsertElement() {

      var _this = this;

      var controller = this.get('postShowController');
      var cloudinaryPresetName = "ehdchxug";
      var slideshow = this.get('model');

      this.set('currentImageIndex', slideshow.get('images').get('length'));

      this.$().unsigned_cloudinary_upload(cloudinaryPresetName, { cloud_name: "thefader" }, { multiple: true });

      this.$().bind('fileuploaddone', function (e, data) {
        //console.log("fileuploaddone data: ", data);

        var publicId = data.result.public_id;

        var currentImageIndex = _this.get('currentImageIndex') + 1;
        _this.set('currentImageIndex', currentImageIndex);

        var slideshowImage = controller.store.createRecord('slideshowImage');
        slideshowImage.set('slideshow', slideshow);
        slideshowImage.set('position', currentImageIndex);
        slideshowImage.set('cloudinaryPublicId', publicId);

        // debugger;

        slideshowImage.save();
      });

      this.$().bind('fileuploadstart', function (e, data) {
        //console.log("start: ", data);
      });

      this.$().bind('fileuploadstop', function (e, data) {
        //console.log("stop: ", data);

        // debugger;
        // var publicId = data.result.public_id;       
        //   _context.set(_publicIdAttribute, publicId);
      });
    }
  });
});