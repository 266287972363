define('thefader-cms-ember/controllers/base', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),

    actions: {
      invalidateSession: function invalidateSession() {
        console.log("invalidateSession");
        this.get('session').invalidate();
      }
    }
  });
});