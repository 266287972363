define("thefader-cms-ember/components/post-credit", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    contributorTypes: (function () {
      return ["Author", "Editor", "Photographer", "Video", "Interactive Designer", "Illustration", "Fashion", "Guest Curator", "As told to"];
    }).property(),

    actions: {
      selectContributorType: function selectContributorType(contributorType) {
        this.get("model").set("type", contributorType);
      },

      edit: function edit() {
        this.set("isEditing", true);
      },

      cancel: function cancel() {
        this.set("isEditing", false);
      },

      save: function save() {
        var _this = this;

        if (this.get("newRecord")) {
          var postCredit = this.get("model");
          postCredit.set("post", this.get("post"));

          this.set("isEditing", false);

          postCredit.save().then(function () {
            _this.sendAction("action");
          });
        } else {
          this.get("model").save().then(function () {
            _this.set("isEditing", false);
          });
        }
      },

      "delete": function _delete() {
        this.get("model").destroyRecord();
      },

      setContributor: function setContributor() {
        //console.log("no op");
      }
    }
  });
});