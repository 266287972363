define('thefader-cms-ember/components/form-field', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    quickTagsEnabled: true,
    showQuicktags: false,
    showLinkUI: false,
    shouldHideQuicktags: true,

    boldEnabled: true,
    italicEnabled: true,
    leadTextEnabled: true,
    linkEnabled: true,
    openInNewWindow: true,

    needsAttention: false,
    maxChars: null,

    autoSmartQuotesEnabled: false,

    // didInsertElement: function() {

    //// console.log("didInsertElement");
    // Mousetrap.bindElement(this.$(".form-control")[0], 'command+b', function() {
    ////   console.log("bold shortcut");
    // });

    // this.$(".form-control").bind('keydown.ctrl_b', function(event) {
    ////   console.log("bold");
    // });

    // },

    keyUp: function keyUp(event) {
      if (!this.get("autoSmartQuotesEnabled")) {
        return;
      }

      var input = this.$('.form-control');
      var val = input.val();
      var currentPosition = input.caret();
      // console.log("currentPosition: ", currentPosition);

      var insideTag = false;

      for (var i = 0; i < val.length; i++) {
        var char = val.charAt(i);
        // console.log("i, char", i, char);

        if (char === ">") {
          insideTag = false;
        }

        if (char === "<") {
          insideTag = true;
        }

        if (insideTag) {
          continue;
        }

        if (char === "'") {
          var newVal = val.substr(0, i) + "’" + val.substr(i + 1);
          input.val(newVal);
          input.caret(currentPosition);
        } else if (char === '"') {
          var previousChar = val.charAt(i - 1);
          // console.log("previousChar: ", previousChar);

          if (i == 0 || previousChar === " " || previousChar === "\n") {
            // console.log("open quote");
            var newVal = val.substr(0, i) + "“" + val.substr(i + 1);
            input.val(newVal);
            input.caret(currentPosition);
          } else {
            // console.log("close quote");
            var newVal = val.substr(0, i) + "”" + val.substr(i + 1);
            input.val(newVal);
            input.caret(currentPosition);
          }
        }
      }
    },

    keyDown: function keyDown(event) {
      // console.log("keyDown", event);

      var maxChars = this.get('maxChars');
      if (maxChars) {
        // debugger;
        var val = this.$('.form-control').val();
        // var val = this.get('object').get(this.get('fieldname'));
        if (val.length >= maxChars) {
          //// console.log("setting needsAttention");
          this.set('needsAttention', true);
          this.set('attentionMessage', "It's recommended to keep the maximum char limit of this field at " + maxChars);
        } else {
          this.set('attentionMessage', null);
        }
      }
    },

    inputType: (function () {
      if (this.get('type')) {
        return this.get('type');
      } else {
        return "text";
      }
    }).property('type'),

    isTextarea: (function () {
      return this.get('type') === "textarea";
    }).property('type'),

    hasError: (function () {
      if (!this.get('object')) {
        return false;
      }
      // debugger;
      var hasError = this.get('object').get('errors').has(this.get('fieldname'));
      //console.log("check hasError: ", this.get('fieldname'), hasError);
      return hasError;
    }).property('object.errors.length'),

    errors: (function () {
      if (this.get('object.errors')) {
        return this.get('object.errors').errorsFor(this.get('fieldname')).mapBy('message').join(', ');
      } else {
        return _ember['default'].A();
      }
    }).property('object.errors.[]'),

    focusOut: function focusOut(event) {
      console.log("focusOut");

      if (!this.get('quickTagsEnabled')) {
        return;
      }

      _ember['default'].run.later(this, function () {
        if (this.get('shouldHideQuicktags')) {
          this.set('showQuicktags', false);
        }
      }, 500);
    },

    focusIn: function focusIn(event) {
      if (!this.get('quickTagsEnabled')) {
        return;
      }

      this.set('showQuicktags', true);
    },

    actions: {
      makeBold: function makeBold() {
        //// console.log("makeBold");

        this.set('shouldHideQuicktags', false);

        this.$('.form-control').selection('insert', { text: '<b>', mode: 'before' }).selection('insert', { text: '</b>', mode: 'after' });

        _ember['default'].run.later(this, function () {
          this.set('shouldHideQuicktags', true);
        }, 500);
      },

      makeItalic: function makeItalic() {
        //// console.log("makeItalic");
        this.set('shouldHideQuicktags', false);

        this.$('.form-control').selection('insert', { text: '<i>', mode: 'before' }).selection('insert', { text: '</i>', mode: 'after' });

        _ember['default'].run.later(this, function () {
          this.set('shouldHideQuicktags', true);
        }, 500);
      },

      makeLeadText: function makeLeadText() {
        //// console.log("makeItalic");
        this.set('shouldHideQuicktags', false);

        this.$('.form-control').selection('insert', { text: '<span class="lead-text">', mode: 'before' }).selection('insert', { text: '</span>', mode: 'after' });

        _ember['default'].run.later(this, function () {
          this.set('shouldHideQuicktags', true);
        }, 500);
      },

      showLinkUI: function showLinkUI() {
        console.log("showLinkUI");
        this.set('shouldHideQuicktags', false);
        this.set('showLinkUI', true);
      },

      cancelLinkUI: function cancelLinkUI() {
        // this.set('shouldHideQuicktags', true);
        this.set('showLinkUI', false);
      },

      addLink: function addLink() {
        //console.log("addLink");
        var url = this.get('linkUrl');
        var openInNewWindow = true;
        var selectedText = this.$('.form-control').selection();

        if (url.match("www\.thefader\.com")) {
          openInNewWindow = false;
        }

        if (openInNewWindow) {
          var replacementText = '<a target="_new" href="' + url + '">' + selectedText + "</a>";
        } else {
          var replacementText = '<a href="' + url + '">' + selectedText + "</a>";
        }

        this.$('.form-control').selection('replace', { text: replacementText });

        this.set('linkUrl', '');
        this.set('showLinkUI', false);

        _ember['default'].run.later(this, function () {
          this.set('shouldHideQuicktags', true);
        }, 500);
      }
    }
  });
});