define('thefader-cms-ember/components/thumbnail-crop-image', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {

      var _this = this;

      function handleCrop(event) {
        // console.log(event);
        _this.sendAction('action', event);
      }

      var model = this.get('model');
      var selection = null;
      var aspectRatio = null;

      if (this.get('isThumbnailVersion')) {
        // console.log("isThumbnailVersion");

        if (model.get('name') === 'wide') {
          aspectRatio = 1.92;
        }

        var x = model.get('x');
        var y = model.get('y');
        var x2 = x + model.get('width');
        var y2 = y + model.get('height');

        selection = [x, y, x2, y2];
      }

      if (selection) {
        this.$("img").Jcrop({
          onChange: handleCrop,
          onSelect: handleCrop,
          setSelect: selection,
          aspectRatio: aspectRatio
        });
      } else {
        this.$("img").Jcrop({
          onChange: handleCrop,
          onSelect: handleCrop
        });
      }
    }
  });
});