define('thefader-cms-ember/models/daily-newsletter-item', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    dailyNewsletter: _emberData['default'].belongsTo('dailyNewsletter', { inverse: 'dailyNewsletterItems' }),
    preDailyNewsletterId: _emberData['default'].attr('number'),

    type: _emberData['default'].attr(),
    position: _emberData['default'].attr('number'),

    postUrl: _emberData['default'].attr('string'),
    post: _emberData['default'].belongsTo('post', { inverse: null }),

    imageCloudinaryPublicId: _emberData['default'].attr('string'),
    adUrl: _emberData['default'].attr('string'),

    textBoxContent: _emberData['default'].attr('string'),

    skipValidation: _emberData['default'].attr('boolean'),

    isPost: (function () {
      return this.get('type') === "post";
    }).property('type'),

    isAd: (function () {
      return this.get('type') === "ad";
    }).property('type'),

    isTextBox: (function () {
      return this.get('type') === "text_box";
    }).property('type')

  });
});