define('thefader-cms-ember/components/published-posts-summary-report', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Component.extend({

    ajax: _ember['default'].inject.service(),
    data: null,

    moment: _ember['default'].inject.service(),

    selectedDateRange: "Today",

    month: _moment['default'].tz('America/New_York').startOf('month'),
    start: _moment['default'].tz('America/New_York').startOf('day'),
    end: _moment['default'].tz('America/New_York').startOf('day').add(2, 'days'),

    presets: [{
      name: 'Today',
      startDate: _moment['default'].tz('America/New_York').startOf('day'),
      endDate: _moment['default'].tz('America/New_York').endOf('day')
    }, {
      name: 'This Week',
      startDate: _moment['default'].tz('America/New_York').startOf('week'),
      endDate: _moment['default'].tz('America/New_York').endOf('week').startOf('day')
    }, {
      name: 'This Month',
      startDate: _moment['default'].tz('America/New_York').startOf('month'),
      endDate: _moment['default'].tz('America/New_York').endOf('month').startOf('day')
    }, {
      name: 'Last Week',
      startDate: _moment['default'].tz('America/New_York').startOf('week').subtract(1, 'week'),
      endDate: _moment['default'].tz('America/New_York').endOf('week').subtract(1, 'week').startOf('day')
    }, {
      name: 'Last Month',
      startDate: _moment['default'].tz('America/New_York').startOf('month').subtract(1, 'month'),
      endDate: _moment['default'].tz('America/New_York').endOf('month').subtract(1, 'month').startOf('day')

    }],

    dateRangeChoices: _ember['default'].computed(function () {
      return ["Today", "This Week", "This Month", "Last Week", "Last Month"];
    }),

    didInsertElement: function didInsertElement() {
      this.getData();
    },

    getData: function getData() {
      var _this = this;

      var selectedDateRange = this.get('selectedDateRange');

      var start = this.get('start').toJSON();
      var end = this.get('end').toJSON();

      // debugger;

      this.get('ajax').request("/cms_api/v1/reports/published_report?start_date=" + start + "&end_date=" + end).then(function (data) {

        console.log("data: ", data);
        _this.set("data", data);
      });

      // this.get('ajax').request("/cms_api/v1/reports/published_report?date="+selectedDateRange).then(function(data) {

      //   console.log("data: ", data);
      //   _this.set("data", data);

      // });
    },

    actions: {
      selectDateRange: function selectDateRange(value) {
        console.log("selectDateRange", value);
        this.set("selectedDateRange", value);
        this.getData();
      },

      apply: function apply() {
        console.log("apply");
        var endDate = this.get('end');
        this.set('end', endDate.endOf('day'));

        this.getData();
      },

      cancel: function cancel() {
        console.log("cancel");
      }
    }
  });
});