define("thefader-cms-ember/models/post", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].Model.extend({
    modelVersion: _emberData["default"].attr("number"),

    title: _emberData["default"].attr("string"),
    socialTitle: _emberData["default"].attr("string"),
    cardTitle: _emberData["default"].attr("string"),

    originalTitle: _emberData["default"].attr("string"),
    description: _emberData["default"].attr("string"),
    seoDescription: _emberData["default"].attr("string"),
    showSeoDescriptionOnMobile: _emberData["default"].attr("boolean"),
    slug: _emberData["default"].attr("string"),
    manualSlug: _emberData["default"].attr("boolean"),
    permalink: _emberData["default"].attr("string"),

    articleType: _emberData["default"].attr("string"),
    category: _emberData["default"].attr("string"),
    // category: DS.belongsTo('category', {async: false}),

    format: _emberData["default"].attr("string"),
    contentType: _emberData["default"].attr("string"),

    mobileCardType: _emberData["default"].attr("string"),

    backgroundColor: _emberData["default"].attr("string"),
    linkColor: _emberData["default"].attr("string"),

    publishedState: _emberData["default"].attr("string"),
    publishedAt: _emberData["default"].attr("date"),
    updatedAt: _emberData["default"].attr("date"),

    relatedVideoId: _emberData["default"].attr("string"),

    approvalState: _emberData["default"].attr("string"),
    flagged: (function () {
      return this.get("approvalState") == "Flagged";
    }).property("approvalState"),

    approved: (function () {
      return this.get("approvalState") == "Approved";
    }).property("approvalState"),

    denied: (function () {
      return this.get("approvalState") == "Denied";
    }).property("approvalState"),

    queueTimeState: _emberData["default"].attr("string"),
    queueTime: _emberData["default"].attr("date"),

    customJavascript: _emberData["default"].attr("string"),
    customCss: _emberData["default"].attr("string"),

    contentBlocks: _emberData["default"].hasMany("contentBlock", { async: true, inverse: "post" }),
    contentBlocksSorting: ["position:asc"],
    contentBlocksSorted: Ember.computed.sort("contentBlocks", "contentBlocksSorting"),

    thumbnail: _emberData["default"].belongsTo("thumbnail", { async: true }),
    postCredits: _emberData["default"].hasMany("postCredit", { async: true }),

    verticals: _emberData["default"].hasMany("vertical", { async: true }),
    musicGenres: _emberData["default"].hasMany("musicGenre", { async: true }),
    styleGenres: _emberData["default"].hasMany("styleGenre", { async: true }),
    cultureGenres: _emberData["default"].hasMany("cultureGenre", { async: true }),

    countries: _emberData["default"].hasMany("country"),

    artistList: _emberData["default"].attr("string"),
    eventList: _emberData["default"].attr("string"),
    metaTagList: _emberData["default"].attr("string"),

    clientHub: _emberData["default"].belongsTo("clientHub", { async: false }),
    podcast: _emberData["default"].belongsTo("podcast", { async: false }),
    issue: _emberData["default"].belongsTo("issue", { async: false }),
    postCollection: _emberData["default"].belongsTo("postCollection"),

    postCollectionTitle: (function () {
      return this.get("postCollection.title");
    }).property("postCollection"),

    recommendedNextPost: _emberData["default"].belongsTo("post", { async: true }),

    recommendedNextPostTitle: (function () {
      return this.get("recommendedNextPost.title");
    }).property("recommendedNextPost"),

    pinnedWarStory: _emberData["default"].attr("boolean"),
    pinnedWarStoryEndTime: _emberData["default"].attr("date"),
    pinnedWarStoryColor: _emberData["default"].attr("string"),

    premiere: _emberData["default"].attr("boolean"),
    partner: _emberData["default"].attr("boolean"),
    sensitive: _emberData["default"].attr("boolean"),

    categories: ["News", "Features"],

    articleTypes: ["simple", "feature", "custom"],

    mobileCardTypes: ["basic", "standout"],

    author: (function () {
      var _this = this;
      return this.get("postCredits").then(function (postCredits) {
        var author = postCredits.filterBy("type", "Author").get("firstObject");
        _this.set("author", author);
      });
    }).property(),

    isPublished: (function () {
      return this.get("publishedState") === "Published";
    }).property("publishedState"),

    authorId: (function () {
      var _this = this;
      if (this.get("author")) {
        // debugger;
        this.get("author").then(function (author) {
          if (author) {
            //console.log('author', author);
            _this.set("authorId", author.get("contributorIdCached"));
          } else {
            //console.log('no author');
            _this.set("authorId", "");
          }
        });
      }
    }).property("author"),

    modelVersion1: (function () {
      return !this.get("modelVersion2");
    }).property("modelVersion"),

    modelVersion2: (function () {
      return this.get("modelVersion") === 2;
    }).property("modelVersion"),

    hasFullErrors: (function () {
      //console.log("post hasFullErrors");
      // debugger;

      if (this.get("errors.length") > 0) {
        return true;
      }

      var contentBlocksHaveErrors = false;

      this.get("contentBlocks").forEach(function (contentBlock, index) {
        if (contentBlock.get("hasErrors")) {
          //console.log("post hasFullErrors: contentBlock.hasErrors");
          contentBlocksHaveErrors = true;
        }
      });

      return contentBlocksHaveErrors;
    }).property("errors.length", "contentBlocks.@each.hasErrors"),

    fullErrorsLength: (function () {
      var numErrors = this.get("errors.length");
      this.get("contentBlocks").forEach(function (contentBlock, index) {
        numErrors += contentBlock.get("errors.length");
      });
      return numErrors;
    }).property("errors.length", "contentBlocks.@each.hasErrors"),

    countriesText: (function () {
      var self = this;
      this.get("countries").then(function (countries) {
        var countriesText = "";
        countries.forEach(function (country, index) {
          if (index == 0) {
            countriesText += country.get("abbrev");
          } else {
            countriesText += ", " + country.get("abbrev");
          }
        });

        self.set("countriesText", countriesText);
      });
    }).property(),

    currentEditor: _emberData["default"].belongsTo("admin", { async: false }),

    postHasDirtyAttributes: (function () {
      if (this.get("hasDirtyAttributes")) {
        return true;
      }

      var isDirty = false;

      this.get("contentBlocks").forEach(function (contentBlock) {
        if (contentBlock.get("hasDirtyAttributes")) {
          isDirty = true;
        }
      });

      this.get("postCredits").forEach(function (postCredit) {
        if (postCredit.get("hasDirtyAttributes")) {
          isDirty = true;
        }
      });

      return isDirty;
    }).property("hasDirtyAttributes", "contentBlocks.@each.hasDirtyAttributes", "postCredits.@each.hasDirtyAttributes"),

    headlines: _emberData["default"].hasMany("headline", { async: true }),

    optimizationState: _emberData["default"].attr("string"),
    headlineServiceDefaultIsWinner: _emberData["default"].attr("boolean"),
    optimizationFinished: (function () {
      return this.get("optimizationState") == "finished";
    }).property("optimizationState"),

    optimizing: (function () {
      return this.get("optimizationState") == "optimizing";
    }).property("optimizationState"),

    googleStandout: _emberData["default"].attr("boolean"),

    originalTitleOrTitle: (function () {
      if (this.get("originalTitle")) {
        return this.get("originalTitle");
      } else {
        return this.get("title");
      }
    }).property("originalTitle")
  });
});