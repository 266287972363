define('thefader-cms-ember/routes/posts/destroy', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    afterModel: function afterModel(post) {
      console.log("afterModel called");

      var currentAdmin = this.get('session').get('currentAdmin');
      var _this = this;

      if (currentAdmin.get('isAuthor')) {
        post.get('postCredits').then(function (postCredits) {

          var authors = postCredits.filterBy('type', 'Author');
          var contributorIds = authors.mapBy('contributorIdCached');
          if (!contributorIds.contains(currentAdmin.get('contributorIdCached'))) {
            _this.transitionTo('access_denied');
          }
        });
      }
    }

  });
});