define('thefader-cms-ember/components/simple-circles', ['exports', 'ember'], function (exports, _ember) {

  // import * as d3 from "d3";
  // import d3 from 'd3';

  // import { select } from 'd3-selection';
  // import { scaleLinear } from 'd3-scale';
  // import { extent, ascending } from 'd3-array';
  // import { transition } from 'd3-transition';
  // import { easeCubicInOut } from 'd3-ease';

  var run = _ember['default'].run;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({

    tagName: 'svg',
    classNames: ['awesome-d3-widget'],

    width: 720,
    height: 200,

    attributeBindings: ['width', 'height'],

    data: [],

    didReceiveAttrs: function didReceiveAttrs() {
      console.log("didReceiveAttrs");

      // Schedule a call to our `drawCircles` method on Ember's "render" queue, which will
      // happen after the component has been placed in the DOM, and subsequently
      // each time data is changed.
      _ember['default'].run.scheduleOnce('render', this, this.drawCircles);
    },

    drawCircles: function drawCircles() {
      console.log("drawCircles");

      var plot = d3.select(this.element);

      var data = get(this, 'data');
      var width = get(this, 'width');
      var height = get(this, 'height');

      // Create a transition to use later
      var t = d3.transition().duration(250).ease(d3.easeCubicInOut);

      // X scale to scale position on x axis
      var xScale = d3.scaleLinear().domain(d3.extent(data.map(function (d) {
        return d.timestamp;
      }))).range([0, width]);

      // Y scale to scale radius of circles proportional to size of plot
      var yScale = d3.scaleLinear().domain(
      // `extent()` requires that data is sorted ascending
      d3.extent(data.map(function (d) {
        return d.value;
      }).sort(d3.ascending))).range([0, height]);

      // // UPDATE EXISTING
      var circles = plot.selectAll('circle').data(data);

      // EXIT
      circles.exit().transition(t).attr('r', 0).remove();

      // ENTER
      var enterJoin = circles.enter().append('circle').attr('fill', 'steelblue').attr('opacity', 0.5)

      // Set initial size to 0 so we can animate it in from 0 to actual scaled radius
      .attr('r', function () {
        return 0;
      }).attr('cy', function () {
        return height / 2;
      }).attr('cx', function (d) {
        return xScale(d.timestamp);
      });

      // MERGE + UPDATE EXISTING
      enterJoin.merge(circles).transition(t).attr('r', function (d) {
        return yScale(d.value) / 2;
      }).attr('cy', function () {
        return height / 2;
      }).attr('cx', function (d) {
        return xScale(d.timestamp);
      });
    }

  });
});