define('thefader-cms-ember/controllers/login', ['exports', 'thefader-cms-ember/config/environment'], function (exports, _thefaderCmsEmberConfigEnvironment) {
  exports['default'] = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    torii: Ember.inject.service('torii'),

    config: (function () {
      return _thefaderCmsEmberConfigEnvironment['default'].APP;
    }).property('ENV'),

    showMessage: false,

    actions: {

      // used by devise authenticator
      authenticate: function authenticate() {
        var _this2 = this;

        var _getProperties = this.getProperties('identification', 'password');

        var identification = _getProperties.identification;
        var password = _getProperties.password;

        this.get('session').authenticate('authenticator:devise', identification, password)['catch'](function (reason) {
          console.log("error authenticating: reason", reason);
          _this2.set('errorMessage', reason.error || reason);
        });
      },

      authenticateSession: function authenticateSession() {
        var session = this.get('session');
        var _this = this;

        // debugger;

        Ember.run.later(function () {
          _this.set('showMessage', true);
        }, 4000);

        this.get('torii').open('google-oauth2-bearer').then(function (googleAuth) {

          // debugger;

          var googleToken = googleAuth.authorizationToken.access_token;
          console.log('Google authentication successful: ', googleToken);

          session.authenticate('authenticator:google-token', { token: googleToken }).then(function () {
            console.log('custom token authentication successful!');
          }, function (error) {
            // debugger;
            console.log('custom token authentication failed!', error.message);

            // debugger;
            // _this.set('errorMessage', 'Authentication failed.');
          });
        }, function (error) {
          console.error('Google auth failed: ', error.message);
          // debugger;
        });
      }
    }
  });
});