define('thefader-cms-ember/models/daily-newsletter', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    date: _emberData['default'].attr('date'),
    modelVersion: _emberData['default'].attr('number'),
    country: _emberData['default'].belongsTo('country', { async: false }),
    preHeader: _emberData['default'].attr('string'),

    dailyNewsletterItems: _emberData['default'].hasMany('dailyNewsletterItem', { async: true, inverse: 'dailyNewsletter' }),
    dailyNewsletterItemsSorting: ['position:asc'],
    dailyNewsletterItemsSorted: Ember.computed.sort('dailyNewsletterItems', 'dailyNewsletterItemsSorting'),

    modelVersion2: (function () {
      return this.get('modelVersion') === 2;
    }).property('modelVersion')

  });
});