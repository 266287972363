define('thefader-cms-ember/components/slideshow-upload-control', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    tagName: 'input',
    name: 'file',
    attributeBindings: ['name', 'type', 'data-form-data'],
    type: 'file',
    classNames: ['cloudinary-fileupload'],

    didInsertElement: function didInsertElement() {

      var _this = this;
      var store = this.get('store');
      var cloudinaryPresetName = "ehdchxug";

      // debugger;

      this.set('currentImageIndex', 0);

      this.$().unsigned_cloudinary_upload(cloudinaryPresetName, { cloud_name: "thefader" }, { multiple: true });

      this.$().bind('fileuploaddone', function (e, data) {
        console.log("fileuploaddone data: ", data);

        var publicId = data.result.public_id;
        var slideshow = _this.get('contentBlock.slideshow');

        // var currentImageIndex = _this.get('currentImageIndex') + 1;
        // _this.set('currentImageIndex', currentImageIndex);

        var slideshowImage = store.createRecord('slideshowImage');
        slideshowImage.set('slideshow', slideshow);
        // slideshowImage.set('position', currentImageIndex);
        slideshowImage.set('cloudinaryPublicId', publicId);

        // debugger;

        slideshowImage.save();
      });

      this.$().bind('fileuploadstart', function (e, data) {
        console.log("start: ", data);

        var slideshow = store.createRecord('slideshow');
        slideshow.set('title', "slideshow");
        slideshow.set('contentBlock', _this.get('contentBlock'));
        slideshow.save().then(function () {
          console.log("start ended, slideshow saved");
        });
      });

      this.$().bind('fileuploadstop', function (e, data) {
        console.log("stop: ", data);

        _this.sendAction('done');
      });
    }
  });
});