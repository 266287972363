define("thefader-cms-ember/templates/content-blocks/-colors", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "thefader-cms-ember/templates/content-blocks/-colors.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-group vspace");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "style", "");
        var el3 = dom.createTextNode("Background Color");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "style", "margin-left: 10px;");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-group vspace");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "style", "");
        var el3 = dom.createTextNode("Text Color");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "style", "margin-left: 10px;");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element0, 3, 3);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
        morphs[2] = dom.createMorphAt(element1, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
        return morphs;
      },
      statements: [["inline", "spectrum-color-picker", [], ["color", ["subexpr", "@mut", [["get", "model.backgroundColor", ["loc", [null, [3, 32], [3, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["backgroundColorChanged"], [], ["loc", [null, [3, 63], [3, 96]]], 0, 0], "preferredFormat", "hex"], ["loc", [null, [3, 2], [3, 120]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "model.backgroundColor", ["loc", [null, [6, 18], [6, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ""], ["loc", [null, [6, 4], [6, 50]]], 0, 0], ["inline", "spectrum-color-picker", [], ["color", ["subexpr", "@mut", [["get", "model.textColor", ["loc", [null, [12, 32], [12, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "onChange", ["subexpr", "action", ["textColorChanged"], [], ["loc", [null, [12, 57], [12, 84]]], 0, 0], "preferredFormat", "hex"], ["loc", [null, [12, 2], [12, 108]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "model.textColor", ["loc", [null, [15, 18], [15, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ""], ["loc", [null, [15, 4], [15, 44]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});