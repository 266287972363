define('thefader-cms-ember/controllers/thumbnail/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    actions: {

      setCrop: function setCrop(event) {
        // console.log("event: ", event);

        this.set('x', event.x);
        this.set('y', event.y);
        this.set('width', event.w);
        this.set('height', event.h);
      },

      preview: function preview() {
        //console.log("preview");

        var thumbnail = this.get('model');
        var normalVersion = this.store.createRecord('thumbnailVersion');

        var x = this.get('x');
        var y = this.get('y');
        var width = this.get('width');
        var height = this.get('height');

        normalVersion.set('name', 'normal');
        normalVersion.set('x', x);
        normalVersion.set('y', y);
        normalVersion.set('width', width);
        normalVersion.set('height', height);
        normalVersion.set('thumbnail', thumbnail);

        // var bigVersion = this.store.createRecord('thumbnailVersion');
        // bigVersion.set('name', 'big');
        // bigVersion.set('x', x);
        // bigVersion.set('y', y);
        // bigVersion.set('width', width);
        // bigVersion.set('height', height);
        // bigVersion.set('thumbnail', thumbnail);

        var wideVersion = this.store.createRecord('thumbnailVersion');

        // debugger;

        // adjust for 1.92 aspect ratio
        if (x == undefined) {
          x = 0;
        }

        if (y == undefined) {
          y = 0;
        }

        if (width == undefined) {
          width = thumbnail.get('originalWidth');
        }

        if (height == undefined) {
          height = thumbnail.get('originalHeight');
        }

        // debugger;

        var newHeight = width / 1.92;
        var deltaHeight = height - newHeight;
        var newY = y + deltaHeight / 2;
        newY = Math.round(newY);

        if (thumbnail.get('modelVersion') == 1) {
          var scaleTransformation = { width: 800, height: 500, crop: 'fit' };
        } else {
          var scaleTransformation = {};
        }

        var cropTransformation = { width: width, height: height, x: x, y: newY, crop: 'crop' };
        var transformations = [scaleTransformation, cropTransformation];

        var croppedImage = $.cloudinary.image(thumbnail.get('cloudinaryPublicId'), {
          type: thumbnail.get('uploadType'),
          transformation: transformations
        });

        croppedImage = croppedImage[0];

        var _this = this;
        var img = new Image();
        img.onload = function () {

          // debugger;

          if (this.width < 200 || this.height < 200) {
            _this.set('errorMessage', "Wide Version images must be greater than 200 x 200, please adjust the crop to a wider image proportion");
            return;
          }

          wideVersion.set('name', 'wide');
          wideVersion.set('x', x);
          wideVersion.set('y', newY);
          wideVersion.set('width', this.width);
          wideVersion.set('height', this.height);
          wideVersion.set('thumbnail', thumbnail);

          // debugger;

          //console.log("wideVersion:", wideVersion);

          normalVersion.save().then(function () {
            wideVersion.save().then(function () {
              _this.transitionToRoute('thumbnail.preview', thumbnail);
            });
          });
        };

        img.src = $(croppedImage).attr('src');
      },

      cancel: function cancel() {
        var post = this.get('post');
        this.transitionToRoute('posts.show', post.get('id'));
      },

      thumbnailStart: function thumbnailStart() {
        //console.log("thumbnailStart");
      },

      thumbnailFinished: function thumbnailFinished(publicId, width, height) {
        //console.log("thumbnailFinished");
      }
    }
  });
});