define('thefader-cms-ember/routes/posts/optimize', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model(params) {
      return this.store.find('post', params.post_id);
    },

    actions: {
      skip: function skip() {
        console.log("skip action received");
        this.transitionTo("posts.index");
      },

      optimizing: function optimizing() {
        console.log("optimizing action received");
        var post = this.get('currentModel');
        this.transitionTo("posts.optimizing", post.get('id'));
      },

      update: function update() {
        console.log("update action received");
        var post = this.get('currentModel');
        this.transitionTo("posts.optimizing", post.get('id'));
      }
    }
  });
});