define("thefader-cms-ember/templates/content-blocks/-related-article", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.0",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 8
            },
            "end": {
              "line": 16,
              "column": 8
            }
          },
          "moduleName": "thefader-cms-ember/templates/content-blocks/-related-article.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("option");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'value');
          morphs[1] = dom.createAttrMorph(element0, 'selected');
          morphs[2] = dom.createMorphAt(element0, 0, 0);
          return morphs;
        },
        statements: [["attribute", "value", ["get", "option", ["loc", [null, [15, 24], [15, 30]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "selected", ["subexpr", "eq", [["get", "model.align", ["loc", [null, [15, 47], [15, 58]]], 0, 0, 0, 0], ["get", "option", ["loc", [null, [15, 59], [15, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [15, 67]]], 0, 0], 0, 0, 0, 0], ["content", "option", ["loc", [null, [15, 68], [15, 78]]], 0, 0, 0, 0]],
        locals: ["option"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 121
          }
        },
        "moduleName": "thefader-cms-ember/templates/content-blocks/-related-article.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-9");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-xs-2");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "form-group");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "");
        var el5 = dom.createTextNode("Align");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("select");
        dom.setAttribute(el4, "class", "form-control");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("option");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      \n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [2, 1, 1, 3]);
        var element2 = dom.childAt(element1, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        morphs[1] = dom.createAttrMorph(element1, 'onchange');
        morphs[2] = dom.createAttrMorph(element2, 'selected');
        morphs[3] = dom.createMorphAt(element1, 3, 3);
        morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "form-field", [], ["object", ["subexpr", "@mut", [["get", "model", ["loc", [null, [3, 24], [3, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.relatedPostUrl", ["loc", [null, [3, 36], [3, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "fieldname", "relatedPostUrl", "label", "Related Article URL", "mandatory", true, "placeholder", "http://www.thefader.com/...", "quickTagsEnabled", false], ["loc", [null, [3, 4], [3, 193]]], 0, 0], ["attribute", "onchange", ["subexpr", "action", ["selectAlign"], ["value", "target.value"], ["loc", [null, [null, null], [12, 68]]], 0, 0], 0, 0, 0, 0], ["attribute", "selected", ["subexpr", "eq", [["get", "model.align", ["loc", [null, [13, 30], [13, 41]]], 0, 0, 0, 0], null], [], ["loc", [null, [null, null], [13, 48]]], 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "alignOptions", ["loc", [null, [14, 16], [14, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [14, 8], [16, 17]]]], ["inline", "form-field", [], ["type", "textarea", "object", ["subexpr", "@mut", [["get", "model", ["loc", [null, [23, 36], [23, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.mediaBody", ["loc", [null, [23, 48], [23, 63]]], 0, 0, 0, 0]], [], [], 0, 0], "label", "Paragraph Content", "class", "vspace", "placeholder", ""], ["loc", [null, [23, 0], [23, 121]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});