define('thefader-cms-ember/routes/slideshow/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      return this.store.find('slideshow', params.slideshow_id);
    }

  });
});
// afterModel: function(model) {
////   console.log("getting images");
//   model.get('slideshowImages').then(function(images) {
////     console.log("getting images inside promise", images);
//   });
// }