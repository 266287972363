define("thefader-cms-ember/templates/content-blocks/-headline-field", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.0",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 6,
              "column": 2
            }
          },
          "moduleName": "thefader-cms-ember/templates/content-blocks/-headline-field.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "section-slug-field", [], ["label", "Section Slug", "value", ["subexpr", "@mut", [["get", "model.sectionSlug", ["loc", [null, [5, 52], [5, 69]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "text", "placeholder", "Section Slug - updates URL and refreshes sidebar ad (optional)", "object", ["subexpr", "@mut", [["get", "model", ["loc", [null, [5, 166], [5, 171]]], 0, 0, 0, 0]], [], [], 0, 0], "fieldname", "sectionSlug", "observeField", "headline", "mandatory", false], ["loc", [null, [5, 4], [5, 237]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "thefader-cms-ember/templates/content-blocks/-headline-field.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-group");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["inline", "form-field", [], ["object", ["subexpr", "@mut", [["get", "model", ["loc", [null, [2, 22], [2, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.headline", ["loc", [null, [2, 34], [2, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", "Headline (optional)"], ["loc", [null, [2, 2], [2, 84]]], 0, 0], ["block", "if", [["get", "postIsFeatureWithSidebar", ["loc", [null, [4, 8], [4, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 2], [6, 9]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});