define('thefader-cms-ember/controllers/slideshow/images', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    // sortProperties: ['position'],

    sortedImages: _ember['default'].computed.sort('model', 'imageSorting'),
    imageSorting: ['position:asc'],

    updateSortOrder: function updateSortOrder(indexes) {

      //console.log("slideshow updateSortOrder:");
      //console.log(indexes);

      // this.beginPropertyChanges();

      this.forEach(function (item) {

        // debugger;

        var index = indexes[item.get('id')];
        index = index + 1; // so we're not zero indexed

        item.set('position', index);

        //console.log("item with id: ", item.get('id'), index);

        // item.save();
      }, this);

      // this.endPropertyChanges();
    }

  });
});