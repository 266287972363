define("thefader-cms-ember/components/fader-checkbox", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    change: function change(e) {
      console.log("change ", e);
      console.log("change ", e.target.checked);
      console.log("change ", this.get('value'));
      var checked = e.target.checked;
      this.sendAction("action", this.get('value'), checked);
    }
  });
});