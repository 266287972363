define("thefader-cms-ember/initializers/airbrake", ["exports", "ember", "thefader-cms-ember/config/environment"], function (exports, _ember, _thefaderCmsEmberConfigEnvironment) {
  exports.initialize = initialize;

  var isSetup = false;

  function setupAirbrake(container) {
    Airbrake.addReporter(Airbrake.consoleReporter);
    Airbrake.setProject(_thefaderCmsEmberConfigEnvironment["default"].airbrake.projectId, _thefaderCmsEmberConfigEnvironment["default"].airbrake.projectKey);
    Airbrake.setEnvironmentName(_thefaderCmsEmberConfigEnvironment["default"].environment);
    if (_thefaderCmsEmberConfigEnvironment["default"].airbrake.host) {
      Airbrake.setHost(_thefaderCmsEmberConfigEnvironment["default"].airbrake.host);
    }
    Airbrake.addEnvironment({
      user_agent: window.navigator.userAgent
    });

    var preprocessor = function preprocessor(err) {
      return err;
    };
    if (_thefaderCmsEmberConfigEnvironment["default"].airbrake.preprocessor) {
      preprocessor = container.lookup(_thefaderCmsEmberConfigEnvironment["default"].airbrake.preprocessor);
    }
    function pushError(err) {
      Airbrake.push(preprocessor(err));
    }

    var originalOnError = _ember["default"].onerror || _ember["default"].K;
    _ember["default"].onerror = function (err) {
      // any ember error
      originalOnError(err);
      pushError(err);
    };
    window.onerror = function (message, file, line, column, error) {
      // window general errors.
      if (message === 'Script error.') {
        // Ignore.
        return;
      }

      if (error) {
        pushError({ error: error });
      } else {
        pushError({ error: {
            message: message,
            fileName: file,
            lineNumber: line,
            columnNumber: column || 0
          } });
      }
    };
  }

  function initialize(container) {
    if (_thefaderCmsEmberConfigEnvironment["default"].airbrake && !isSetup) {
      isSetup = true;
      if (Airbrake.setProject) {
        setupAirbrake(container);
      } else {
        Airbrake.onload = function () {
          setupAirbrake(container);
        };
      }
    }
  }

  exports["default"] = {
    name: 'airbrake',
    initialize: initialize
  };
});
/* global Airbrake*/