define('thefader-cms-ember/authenticators/google-token', ['exports', 'ember', 'ember-simple-auth/authenticators/base'], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase) {
  exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
    restore: function restore(data) {
      console.log("restore");
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (!_ember['default'].isEmpty(data.user_token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },

    authenticate: function authenticate(options) {
      console.log("authenticate ", options);

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {

        // make the request to authenticate the user at /authpath endpoint.
        _ember['default'].$.ajax({
          url: '/cms_api/v1/admins/sign_in_with_google_token',
          type: 'POST',
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify({ "token": options.token })
        }).then(function (response) {
          console.log("authenticate success");
          //saving to the session.
          resolve(response);
        }, function (xhr, status, error) {
          reject(xhr.responseText);
        });
      });
    },

    invalidate: function invalidate(data) {
      // console.log("invalidate ", data);
      // does nothing for now, you should add code for this to work. (clear the session, send a logout to the server

      return new _ember['default'].RSVP.resolve(true);
    }
  });
});