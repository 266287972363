define('thefader-cms-ember/controllers/daily-newsletters/edit/daily-newsletter-item/delete', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      cancel: function cancel() {
        var model = this.get("model");
        var dailyNewsletter = model.get('dailyNewsletter');
        this.transitionToRoute('daily-newsletters.edit', dailyNewsletter.get('id'));
      },

      'delete': function _delete() {
        var model = this.get('model');
        var dailyNewsletter = model.get('dailyNewsletter');

        // debugger;

        var currentPosition = model.get('position');

        if (currentPosition === dailyNewsletter.get('dailyNewsletterItems.length')) {
          //console.log("last content block");
          model.destroyRecord();
        } else {

          model.destroyRecord();

          var dailyNewsletterItemsToUpdate = dailyNewsletter.get('dailyNewsletterItems').filter(function (item, index) {
            return item.get('position') > currentPosition;
          });

          var position = currentPosition;
          dailyNewsletterItemsToUpdate.forEach(function (dailyNewsletterItem) {

            dailyNewsletterItem.set('position', position);
            dailyNewsletterItem.save();

            position = position + 1;
          });
        }

        this.transitionToRoute('daily-newsletters.edit', dailyNewsletter.get('id'));
      }
    }
  });
});