define("thefader-cms-ember/components/apple-music-embed-count-report", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    ajax: _ember["default"].inject.service(),

    didInsertElement: function didInsertElement() {
      this.getData("apple_music_embed_count");
    },

    getData: function getData() {
      var sortBy = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      var _this = this;

      console.log("getData: ", sortBy);

      var url = "/cms_api/v1/reports/apple_music_embed_count_report";
      if (sortBy) {
        url += "?sort_by=" + sortBy;
      }

      this.get("ajax").request(url).then(function (data) {
        console.log("data: ", data);
        _this.set("data", data);
      });
    },

    actions: {
      sortByPublished: function sortByPublished() {
        this.getData("published");
      },

      sortByEmbedCount: function sortByEmbedCount() {
        this.getData("embed_count");
      }
    }
  });
});