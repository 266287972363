define('thefader-cms-ember/components/post-published', ['exports', 'ember', 'thefader-cms-ember/config/environment'], function (exports, _ember, _thefaderCmsEmberConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({

    livePostLink: (function () {
      var url = _thefaderCmsEmberConfigEnvironment['default']['legacyCmsBaseUrl'] + this.get('model.permalink');
      return url;
    }).property('model.id'),

    actions: {
      success: function success() {
        console.log("success");
      },

      error: function error() {
        console.log("error");
      }
    }
  });
});