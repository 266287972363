define('thefader-cms-ember/routes/content-blocks/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      //console.log("model");

      var contentBlock = this.store.createRecord('contentBlock');
      contentBlock.set('contentBlockType', 'text');
      contentBlock.set('mediaDisplayType', 'medium');
      return contentBlock;
    },

    actions: {
      invalidateModel: function invalidateModel() {
        _ember['default'].Logger.log('Route is now refreshing...');
        this.refresh();
      }
    }

    // renderTemplate: function() {
    ////   console.log("rendering content-blocks/new");
    //   this.render({outlet: 'sidebar'});
    // }
  });
});