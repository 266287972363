define('thefader-cms-ember/components/youtube-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].TextArea.extend({

    input: function input(event) {

      var _this = this;

      var val = this.get('value');

      if (val.match(/^https?:\/\/w?w?w?.?youtube.*/) || val.match(/^https?:\/\/youtu.be.*/)) {

        var videoUrl;

        if (val.match(/^https:\/\/www.youtube.*/)) {
          videoUrl = val.replace("https://www.youtube.com", "http://youtube.com");
        } else if (val.match(/^http:\/\/youtu.be.*/)) {
          videoUrl = val.replace("http://youtu.be/", "http://youtube.com/watch?v=");
        } else {
          videoUrl = val;
        }

        var matches = videoUrl.match(/^https?:\/\/w?w?w?.?youtube\.com\/.*[?&]v=([^&]+)/i) || videoUrl.match(/^https?:\/\/youtu\.be\/([^?]+)/i);
        if (matches) {

          var videoId = matches[1];

          var model = this.get('model');
          model.set(this.get('valueField'), videoUrl);
          model.set(this.get('idField'), videoId);

          $.ajax({
            url: "https://www.googleapis.com/youtube/v3/videos",
            context: this,
            data: {
              key: "AIzaSyAaq6OCpuNgKmzAgPVF1NolQLm1fEJPUDI",
              part: "snippet,statistics",
              id: videoId
            },
            success: function success(data) {
              //console.log("youtube data: ", data);
              var elementId = _this.get('elementId');
              $("#" + elementId).parent().parent().parent().find('.video-preview-image').attr('src', data['items'][0]['snippet']['thumbnails']['default']['url']);
            }
          });
        }
      }
    }

  });
});