define('thefader-cms-ember/services/session-current-admin', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var RSVP = _ember['default'].RSVP;
  var Service = _ember['default'].Service;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = _ember['default'].Service.extend({

    session: service('session'),
    store: service(),

    loadCurrentAdmin: function loadCurrentAdmin() {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        var adminId = _this.get('session.data.authenticated.admin_id');
        if (!isEmpty(adminId)) {
          return _this.get('store').find('admin', adminId).then(function (admin) {
            _this.set('admin', admin);
            resolve();
          }, reject);
        } else {
          resolve();
        }
      });
    }
  });
});