define("thefader-cms-ember/templates/content-blocks/-youtube-fields", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 40,
            "column": 6
          }
        },
        "moduleName": "thefader-cms-ember/templates/content-blocks/-youtube-fields.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-4");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "video-preview");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4, "src", "data:image/gif;base64,R0lGODlhAQABAPAAAAAAAAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
        dom.setAttribute(el4, "class", "video-preview-image");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4, "src", "/assets/images/suplist_video_play_icon.png");
        dom.setAttribute(el4, "class", "playbtn");
        dom.setAttribute(el4, "alt", "");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-8");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "explanation");
        var el4 = dom.createTextNode("\n      We support URLs from YouTube. ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" , Vimeo, Soundcloud, Rdio, Spotify, Facebook, Hulu, Google Video, LiveLeak, MetaCafe, DailyMotion, or MySpace Video. ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-group");
        dom.setAttribute(el1, "style", "padding-top: 10px;");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-2");
        dom.setAttribute(el3, "style", "padding-top: 5px;");
        var el4 = dom.createTextNode("\n      Video Credit\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-10");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        dom.setAttribute(el2, "style", "margin-top: 20px;");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-2");
        dom.setAttribute(el3, "style", "padding-top: 5px;");
        var el4 = dom.createTextNode("\n      Via\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-10");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [4]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 3, 3]), 2, 2);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [1, 3]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [3, 3]), 1, 1);
        return morphs;
      },
      statements: [["inline", "youtube-input", [], ["class", "form-control", "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [16, 49], [16, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "valueField", "videoUrl", "idField", "youtubeVideoId", "placeholder", "Add a video URL or embed code.."], ["loc", [null, [16, 6], [16, 149]]], 0, 0], ["inline", "form-field", [], ["type", "textarea", "object", ["subexpr", "@mut", [["get", "model", ["loc", [null, [21, 36], [21, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.caption", ["loc", [null, [21, 48], [21, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "vspace", "placeholder", "Add a caption.."], ["loc", [null, [21, 0], [21, 108]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "model.credit", ["loc", [null, [29, 20], [29, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-control", "placeholder", ".."], ["loc", [null, [29, 6], [29, 72]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "model.attribution", ["loc", [null, [37, 20], [37, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-control", "placeholder", "Where did you find it (URL)?"], ["loc", [null, [37, 6], [37, 103]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});