define('thefader-cms-ember/components/post-row', ['exports', 'ember', 'thefader-cms-ember/config/environment'], function (exports, _ember, _thefaderCmsEmberConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',
    totalViews: 'Total',
    enableHeadlines: _thefaderCmsEmberConfigEnvironment['default'].APP.ENABLE_HEADLINES,

    livePostLink: (function () {
      var url = _thefaderCmsEmberConfigEnvironment['default']['legacyCmsBaseUrl'] + this.get('model.permalink');
      return url;
    }).property('model.permalink'),

    instantArticleUrl: (function () {
      return _thefaderCmsEmberConfigEnvironment['default']['legacyCmsBaseUrl'] + "/cms/posts/" + this.get('model.id') + "/preview_instant_article";
    }).property(),

    didInsertElement: function didInsertElement() {

      return;

      // debugger;

      if (!this.get('model.isPublished')) {
        return;
      }

      var url = _thefaderCmsEmberConfigEnvironment['default']['legacyCmsBaseUrl'] + this.get('model.permalink');
      url = url.replace("http://localhost:3000", "http://www.thefader.com");url = url.replace("https:", "http:");

      // console.log("post url: ", url);

      var _this = this;

      var protocol = window.location.href.split("/")[0];

      // $.ajax({
      //   type: "GET",
      //   url: protocol + "//api.parsely.com/v2/shares/post/detail?apikey=thefader.com&secret=tcCJYSNdvRt1G1I1Uyopw5ZS2f44vABW1P7BESh8Hb0&url="+url,
      //   dataType: 'jsonp'
      // }).done(function(data) {
      //   // console.log("parsely data from shares", data);

      //   if (data['data'][0]) {
      //     var socialCount = data['data'][0]['total'];
      //     var facebookCount = data['data'][0]['fb'];
      //     var twitterCount = data['data'][0]['tw'];

      //     _this.set('socialCount', socialCount);
      //     _this.set('facebookCount', facebookCount);
      //     _this.set('twitterCount', twitterCount); 
      //   }

      //   $.ajax({
      //     type: "GET",
      //     url: protocol + "//api.parsely.com/v2/analytics/post/detail?apikey=thefader.com&secret=tcCJYSNdvRt1G1I1Uyopw5ZS2f44vABW1P7BESh8Hb0&url=&url="+url,
      //     dataType: 'jsonp'
      //   }).done(function(data) {
      //     // debugger;

      //     // console.log("parsely data from analytics", data);

      //     if (data['data'][0]) {
      //       var totalCount = data['data'][0]['_hits'];
      //       _this.set('totalCount', totalCount);
      //       _this.set('nonsocialCount', totalCount - socialCount); 
      //     }
      //   }).error(function(type) {
      //     console.log("parsely analytics request error", type);
      //   });
      // }).error(function(type) {
      //   console.log("parsely shares request error", type);
      // });
    }
  });
});