define('thefader-cms-ember/components/fader-autocomplete', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].TextField.extend({

    // tagName: 'input',
    classNames: ["form-control"],
    // attributeBindings: ["value"],

    didInsertElement: function didInsertElement() {
      //console.log("element inserted");

      var _this = this;
      var _controller = this.get('controller');
      var _store = this.get('store');

      // debugger;

      _ember['default'].run.later(function () {

        // _this.$().bind("keydown", function(event) {
        //   //console.log("keydown: ", this.value);

        //   if (event.keyCode === $.ui.keyCode.TAB && $(this).autocomplete("instance").menu.active) {
        //     event.preventDefault();
        //   }

        _this.$().autocomplete({
          // }).autocomplete({
          minLength: 2,
          delay: 500,

          source: function source(request, response) {
            //// console.log("request term: ", request.term);

            var term = _this.extractLast(request.term);
            //console.log("source, term: ", term);

            var modelName = _this.get('modelName');

            _store.query(modelName, { search: term }).then(function (results) {
              //console.log("results: ", results);
              response(results.mapBy(_this.get('displayKey')));
            });
          },

          focus: function focus() {
            // prevent value inserted on focus
            return false;
          },

          select: function select(event, ui) {
            console.log("event, ui", event, ui);

            if (_this.get('multiple')) {
              //// console.log("selecting using multiple strategy");

              var terms = _this.split(this.value);
              // remove the current input
              terms.pop();
              // add the selected item
              terms.push(ui.item.value);
              // add placeholder to get the comma-and-space at the end
              terms.push("");

              var textFieldValue = terms.join(", ");
              this.value = textFieldValue;
              _this.set('value', textFieldValue);

              if (_this.get('action')) {

                _ember['default'].run.later(function () {
                  // console.log("we fired the action");
                  _this.sendAction("action", textFieldValue);
                });
              }

              return false;
            } else {

              var textFieldValue = ui.item.value;
              this.value = textFieldValue;
              _this.set('value', textFieldValue);

              if (_this.get('action')) {
                //console.log("we fired the action");
                _ember['default'].run.later(function () {
                  _this.sendAction("action", textFieldValue);
                });
              }

              return false;
            }
          }
        });
      });
    },

    extractLast: function extractLast(term) {
      return this.split(term).pop();
    },

    split: function split(val) {
      return val.split(/,\s*/);
    },

    input: function input() {
      var _this = this;
      // console.log("input fired", this.value);
      if (this.value == "") {
        // console.log("blank");
        _ember['default'].run.later(function () {
          _this.sendAction("action", null);
        });
      }
    }
  });
});