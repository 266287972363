define('thefader-cms-ember/components/daily-newsletter-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    imageLoading: false,

    moveUpIsEnabled: (function () {
      var model = this.get('model');
      // if (!model.get('post')) { return; }

      var currentPosition = model.get('position');
      var count = model.get('dailyNewsletter.dailyNewsletterItems.length');

      if (currentPosition != 1 && count > 1) {
        return true;
      } else {
        return false;
      }
    }).property('model.position'),

    moveDownIsEnabled: (function () {
      var model = this.get('model');

      var currentPosition = model.get('position');
      var count = model.get('dailyNewsletter.dailyNewsletterItems.length');

      // debugger;

      if (currentPosition != count && currentPosition < count) {
        return true;
      } else {
        return false;
      }
    }).property('model.position'),

    swapPositions: function swapPositions(direction) {
      var model = this.get('model');
      var dailyNewsletter = model.get('dailyNewsletter');

      var currentPosition = model.get('position');
      var newPosition = currentPosition + direction;

      //console.log('currentPosition: '+currentPosition);
      //console.log('newPosition: '+newPosition);

      var swapped = dailyNewsletter.get('dailyNewsletterItems').filterBy('position', newPosition).get('firstObject');

      swapped.set('position', currentPosition);
      swapped.save();

      model.set('position', newPosition);
      model.save();
    },

    actions: {
      setPost: function setPost() {
        var model = this.get('model');
        model.set('type', 'post');
      },

      setAd: function setAd() {
        this.get('model').set('type', 'ad');
      },

      setTextBox: function setTextBox() {
        this.get('model').set('type', 'text_box');
      },

      add: function add() {
        var _this = this;
        var model = this.get('model');
        var dailyNewsletter = this.get('dailyNewsletter');
        model.set('preDailyNewsletterId', dailyNewsletter.get('id'));

        model.save().then(function () {
          console.log("item saved");
          _this.sendAction();
        }, function () {
          console.log("item failed to save");
        });
      },

      edit: function edit() {
        this.set('isEditing', true);
      },

      save: function save() {
        var _this = this;
        var model = this.get('model');
        var dailyNewsletter = this.get('dailyNewsletter');

        model.save().then(function () {
          _this.set('isEditing', false);
        }, function () {
          console.log("invalid");
        });
      },

      cancel: function cancel() {
        this.set('isEditing', false);
      },

      'delete': function _delete() {
        var model = this.get('model');
        this.get('router').transitionTo('daily-newsletters.edit.daily-newsletter-item.delete', model);
      },

      showSpinner: function showSpinner() {
        //// console.log("showSpinner1");
        this.set('imageLoading', true);
      },

      imageFinished: function imageFinished(publicId, width, height, format) {

        var model = this.get('model');
        model.set('imageCloudinaryPublicId', publicId);
        // model.set('imageOriginalWidth', width);
        // model.set('imageOriginalHeight', height);

        this.set('imageLoading', false);

        // if (format == "gif") {
        //   model.set('imageIsAnimatedGif', true);
        // }
      },

      moveUp: function moveUp() {
        var model = this.get('model');
        var currentPosition = model.get('position');
        if (currentPosition == 1) {
          return;
        }

        this.swapPositions(-1);
      },

      moveDown: function moveDown() {
        var model = this.get('model');
        var currentPosition = model.get('position');
        if (currentPosition == model.get('dailyNewsletter.dailyNewsletterItems.length')) {
          return;
        }

        this.swapPositions(1);
      }
    }
  });
});