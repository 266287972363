define('thefader-cms-ember/models/country', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    post: _emberData['default'].belongsTo('post'),

    name: _emberData['default'].attr('string'),
    countryCode: _emberData['default'].attr('number'),
    abbrev: _emberData['default'].attr('string'),

    isUs: (function () {
      return this.get('countryCode') === 1;
    }).property('countryCode'),

    isUk: (function () {
      return this.get('countryCode') === 2;
    }).property('countryCode')

  });
});