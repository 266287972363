define('thefader-cms-ember/routes/posts/show/locked', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    sessionCurrentAdmin: _ember['default'].inject.service('session-current-admin'),
    ajax: _ember['default'].inject.service(),

    setupController: function setupController(controller, models, transition) {
      this._super.apply(this, arguments);

      //console.log("setupController");
      controller.set('model', models.post);
    },

    actions: {
      unlock: function unlock() {
        //console.log("unlocking post");
        var _this = this;
        // debugger;
        var post = this.get('currentModel.post');
        var currentAdmin = this.get('sessionCurrentAdmin.admin');

        this.get('ajax').post("/cms_api/v1/posts/" + post.get('id') + "/override_current_editor").then(function () {
          //console.log("override current editor successfully set!");
          _this.transitionTo('posts.show', post.get('id'));
        });
      }
    }
  });
});