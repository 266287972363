define("thefader-cms-ember/routes/posts/show", ["exports", "ember", "thefader-cms-ember/routes/base"], function (exports, _ember, _thefaderCmsEmberRoutesBase) {
  exports["default"] = _thefaderCmsEmberRoutesBase["default"].extend({
    session: _ember["default"].inject.service("session"),
    sessionCurrentAdmin: _ember["default"].inject.service("session-current-admin"),
    ajax: _ember["default"].inject.service(),

    model: function model(params) {
      //console.log("model called");
      return _ember["default"].RSVP.hash({
        post: this.store.findRecord("post", params.post_id),
        countries: this.store.findAll("country", { reload: true }),
        verticals: this.store.findAll("vertical", { reload: true }),
        musicGenres: this.store.findAll("musicGenre", { reload: true }),
        styleGenres: this.store.findAll("styleGenre", { reload: true }),
        cultureGenres: this.store.findAll("cultureGenre", { reload: true }),
        issues: this.store.findAll("issue", { reload: true }),
        clientHubs: this.store.findAll("clientHub", { reload: true }),
        podcasts: this.store.findAll("podcast", { reload: true }),
        categories: this.store.findAll("category", { reload: true })
      });
    },

    afterModel: function afterModel(models) {
      //console.log("afterModel called");

      var post = models.post;
      var _this = this;

      post.get("countries");
      post.get("thumbnail");
      post.get("verticals");
      post.get("musicGenres");
      post.get("styleGenres");
      post.get("cultureGenres");
      // post.get('postCollection');

      var currentAdmin = this.get("sessionCurrentAdmin.admin");

      if (currentAdmin.get("isAuthor")) {
        post.get("postCredits").then(function (postCredits) {
          var authors = postCredits.filterBy("type", "Author");
          var contributorIds = authors.mapBy("contributorIdCached");
          if (!contributorIds.contains(currentAdmin.get("contributorIdCached"))) {
            _this.transitionTo("access_denied");
          }
        });
      }

      if (post.get("currentEditor") && post.get("currentEditor") !== currentAdmin) {
        this.transitionTo("posts.show.locked", post.get("id"));
      } else if (!post.get("currentEditor")) {
        // debugger;
        this.get("ajax").post("/cms_api/v1/posts/" + post.get("id") + "/set_current_editor").then(function () {
          post.set("currentEditor", currentAdmin);
        });
      }
    },

    setupController: function setupController(controller, models, transition) {
      //console.log("posts/show setupController, models: ", models);

      // debugger;

      console.log("setupController");

      this._super.apply(this, arguments);

      controller.set("model", models.post);
      controller.set("allVerticals", models.verticals);
      controller.set("checkedVerticalNames", models.post.get("verticals").mapBy("name"));

      controller.set("model", models.post);
      controller.set("allCountries", models.countries);
      controller.set("allMusicGenres", models.musicGenres);
      controller.set("allStyleGenres", models.styleGenres);
      controller.set("allCultureGenres", models.cultureGenres);
      controller.set("issues", models.issues);
      controller.set("clientHubs", models.clientHubs);
      controller.set("podcasts", models.podcasts);
      controller.set("categories", models.categories);

      var newContentBlock = this.store.createRecord("contentBlock");
      newContentBlock.set("contentBlockType", "text");
      newContentBlock.set("mediaDisplayType", "medium");
      newContentBlock.set("isEditing", true);
      controller.set("newContentBlock", newContentBlock);

      var newPostCredit = this.store.createRecord("postCredit");
      newPostCredit.set("type", "Author");
      controller.set("newPostCredit", newPostCredit);
    }
  });
});