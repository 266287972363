define('thefader-cms-ember/routes/thumbnail/preview', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      // console.log("setupController");
      var post = this.modelFor('posts.show').post;
      controller.set('post', post);
      controller.set('model', model);
    }
  });
});