define("thefader-cms-ember/templates/components/slideshow-image", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "thefader-cms-ember/templates/components/slideshow-image.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-sm-6 col-md-4 slideshow-image");
        var el2 = dom.createTextNode("\n    \n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "thumbnail");
        dom.setAttribute(el2, "style", "");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "class", "pull-right");
        dom.setAttribute(el3, "style", "padding: 10px; cursor: pointer;");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "style", "clear: both;");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "caption");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("Caption");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    \n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element0, 'data-id');
        morphs[1] = dom.createElementMorph(element2);
        morphs[2] = dom.createMorphAt(element2, 1, 1);
        morphs[3] = dom.createMorphAt(element1, 5, 5);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [7]), 3, 3);
        return morphs;
      },
      statements: [["attribute", "data-id", ["concat", [["subexpr", "unbound", [["get", "image.id", ["loc", [null, [1, 66], [1, 74]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 56], [1, 76]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["deleteImage"], ["href", "#"], ["loc", [null, [5, 7], [5, 40]]], 0, 0], ["inline", "fa-icon", [], ["icon", "fa-trash"], ["loc", [null, [6, 6], [6, 33]]], 0, 0], ["inline", "cloudinary-tag", [["get", "image.cloudinaryPublicId", ["loc", [null, [11, 21], [11, 45]]], 0, 0, 0, 0]], ["width", 200, "uploadType", ["subexpr", "@mut", [["get", "image.uploadType", ["loc", [null, [11, 67], [11, 83]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [11, 4], [11, 85]]], 0, 0], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "image.caption", ["loc", [null, [15, 20], [15, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "form-control"], ["loc", [null, [15, 6], [15, 56]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});