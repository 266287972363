define('thefader-cms-ember/authenticators/devise', ['exports', 'ember', 'ember-simple-auth/authenticators/devise'], function (exports, _ember, _emberSimpleAuthAuthenticatorsDevise) {
  var service = _ember['default'].inject.service;
  exports['default'] = _emberSimpleAuthAuthenticatorsDevise['default'].extend({
    serverTokenEndpoint: '/cms_api/v1/admins/sign_in',
    tokenAttributeName: 'user_token',
    resourceName: 'admin',
    identificationAttributeName: 'email',

    sessionDataUpdated: function sessionDataUpdated() {
      console.log("sessionDataUpdated");
    }
  });
});