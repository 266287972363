define("thefader-cms-ember/components/published-author-report", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    ajax: _ember["default"].inject.service(),

    didInsertElement: function didInsertElement() {
      this.getData();
    },

    getData: function getData() {
      var _this = this;

      this.get("ajax").request("/cms_api/v1/reports/author_report").then(function (data) {
        console.log("data: ", data);
        _this.set("data", data);
      });
    }
  });
});