define('thefader-cms-ember/controllers/posts/headlines', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    queryParams: ["contributor_id"],

    selectedIsMy: (function () {
      return !_ember['default'].isBlank(this.get('contributor_id'));
    }).property('contributor_id'),

    selectedIsAllPosts: (function () {
      return _ember['default'].isBlank(this.get('contributor_id'));
    }).property('contributor_id'),

    actions: {
      showMyPosts: function showMyPosts() {
        console.log("showMyPosts");
        this.set('contributor_id', this.get('session.contributor_id'));
      },

      showAllPosts: function showAllPosts() {
        console.log("showAllPosts");
        this.set('contributor_id', null);
      }
    }
  });
});