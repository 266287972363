define("thefader-cms-ember/templates/posts/optimize", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "thefader-cms-ember/templates/posts/optimize.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        dom.setAttribute(el2, "style", "margin-top: 40px;");
        var el3 = dom.createTextNode("Optimize Post");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "alert");
        var el3 = dom.createTextNode("\n    You cannot change the number of variations once a headline test is running\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
        morphs[1] = dom.createMorphAt(element0, 7, 7);
        return morphs;
      },
      statements: [["content", "model.title", ["loc", [null, [4, 6], [4, 21]]], 0, 0, 0, 0], ["inline", "headline-titles", [], ["headlines", ["subexpr", "@mut", [["get", "model.headlines", ["loc", [null, [10, 30], [10, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "store", ["subexpr", "@mut", [["get", "store", ["loc", [null, [10, 52], [10, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "post", ["subexpr", "@mut", [["get", "model", ["loc", [null, [10, 63], [10, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "skip", "skip", "action", "optimizing", "update", "update"], ["loc", [null, [10, 2], [10, 118]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});