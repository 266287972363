define('thefader-cms-ember/helpers/cloudinary-tag', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.helper(function (publicId, options) {

    //console.log("cloudinaryTag options: ", publicId, options);

    // debugger;

    publicId = publicId[0];

    if (Em.isBlank(publicId) || publicId === undefined) {
      return '';
    }

    // debugger;
    if (publicId.match(/\.png/)) {
      publicId = publicId.replace('.png', '.jpg');
    }

    var height = options.height || 535,
        width = options.width || 535,
        crop = options.crop || 'fit',
        cssClass = options['class'] || 'cloudinary-image';

    var uploadType = options.uploadType || "public";

    var resourceType = options.resourceType || "image";

    var transformations = null;
    var thumbnailVersion = options.thumbnailVersion;
    //console.log("thumbnailVersion: ", thumbnailVersion);

    if (thumbnailVersion) {

      if (thumbnailVersion.get('thumbnail.isModelVersion1')) {
        var scaleTransformation = { width: 800, height: 500, crop: 'fit' };
      } else {
        var scaleTransformation = { width: 1024, crop: 'limit' };
      }

      // debugger;

      if (thumbnailVersion.get('width') != null && thumbnailVersion.get('height') != null && thumbnailVersion.get('x') != null && thumbnailVersion.get('y') != null) {
        var cropTransformation = { width: thumbnailVersion.get('width'), height: thumbnailVersion.get('height'), x: thumbnailVersion.get('x'), y: thumbnailVersion.get('y'), crop: 'crop' };
        transformations = [scaleTransformation, cropTransformation];
      }
    }

    // debugger;

    var image = $.cloudinary.image(publicId, {
      transformation: transformations,
      width: width, height: height, crop: crop,
      'class': cssClass,
      type: uploadType,
      resourceType: resourceType
    });

    var el = image[0];

    if (resourceType == "video") {
      var src = $(el).attr('src');
      src = src.replace("/image/", "/video/");
      $(el).attr('src', src);
    }

    return el;
  });
});