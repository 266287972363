define("thefader-cms-ember/templates/posts/show", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 0
          }
        },
        "moduleName": "thefader-cms-ember/templates/posts/show.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "post-show", [], ["store", ["subexpr", "@mut", [["get", "store", ["loc", [null, [2, 8], [2, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "categories", ["subexpr", "@mut", [["get", "categories", ["loc", [null, [3, 13], [3, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 8], [4, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "issues", ["subexpr", "@mut", [["get", "issues", ["loc", [null, [5, 9], [5, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "multiSiteEnabled", ["subexpr", "@mut", [["get", "multiSiteEnabled", ["loc", [null, [6, 19], [6, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "allCountries", ["subexpr", "@mut", [["get", "allCountries", ["loc", [null, [7, 15], [7, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "allVerticals", ["subexpr", "@mut", [["get", "allVerticals", ["loc", [null, [8, 15], [8, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "allMusicGenres", ["subexpr", "@mut", [["get", "allMusicGenres", ["loc", [null, [9, 17], [9, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "allStyleGenres", ["subexpr", "@mut", [["get", "allStyleGenres", ["loc", [null, [10, 17], [10, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "allCultureGenres", ["subexpr", "@mut", [["get", "allCultureGenres", ["loc", [null, [11, 19], [11, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "clientHubs", ["subexpr", "@mut", [["get", "clientHubs", ["loc", [null, [12, 13], [12, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "podcasts", ["subexpr", "@mut", [["get", "podcasts", ["loc", [null, [13, 11], [13, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "newContentBlock", ["subexpr", "@mut", [["get", "newContentBlock", ["loc", [null, [14, 18], [14, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "newPostCredit", ["subexpr", "@mut", [["get", "newPostCredit", ["loc", [null, [15, 16], [15, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [16, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});