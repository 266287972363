define('thefader-cms-ember/components/headline-titles', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    ajax: _ember['default'].inject.service(),

    optimizeButtonDisabled: (function () {
      if (this.get('post.headlines.length') == 0) {
        return true;
      }

      var headlinesBlank = false;

      this.get('post.headlines').forEach(function (headline) {
        // debugger;
        if (_ember['default'].isBlank(headline.get('title'))) {
          headlinesBlank = true;
        }
      });

      return headlinesBlank;
    }).property('post.headlines.@each.title'),

    actions: {
      addTitle: function addTitle() {
        //console.log("addTitle");

        var post = this.get('post');
        var headline = this.get('store').createRecord('headline', {
          post: post
        });
      },

      optimize: function optimize() {
        //console.log("optimize");

        var _this = this;

        this.get('post.headlines').then(function (headlines) {
          //console.log(headlines);

          var promises = [];

          headlines.forEach(function (headline) {
            promises.push(headline.save());
          });

          var post = _this.get('post');

          _ember['default'].RSVP.all(promises).then(function (headlines) {
            _this.get('ajax').post("/cms_api/v1/posts/" + post.get('id') + "/optimize_headlines").then(function () {
              //console.log("optimized!");
              post.reload().then(function (p) {
                _this.sendAction("action");
              });
            });
          });
        });
      },

      update: function update() {
        var _this = this;

        this.get('post.headlines').then(function (headlines) {

          var promises = [];

          headlines.forEach(function (headline) {
            promises.push(headline.save());
          });

          var post = _this.get('post');

          _ember['default'].RSVP.all(promises).then(function (headlines) {

            _this.get('ajax').post("/cms_api/v1/posts/" + post.get('id') + "/update_headlines").then(function () {

              console.log("headlines updated!");

              post.reload().then(function (p) {
                console.log("send update action");
                _this.sendAction("update");
              });
            });
          });
        });
      },

      skip: function skip() {
        console.log("skip");
        this.sendAction('skip');
      }
    }
  });
});