define('thefader-cms-ember/controllers/thumbnail-version/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    errorMessage: null,

    actions: {

      setCrop: function setCrop(event) {
        // console.log("event: ", event);

        this.set('x', event.x);
        this.set('y', event.y);
        this.set('width', event.w);
        this.set('height', event.h);
      },

      preview: function preview() {
        //console.log("preview");

        var thumbnailVersion = this.get('model');

        var x = this.get('x');
        var y = this.get('y');
        var width = Math.floor(this.get('width'));
        var height = Math.floor(this.get('height'));

        if (width < 200 || height < 200) {
          //console.log("wideVersion images must be greater than 200 x 200");
          this.set('errorMessage', "Wide Version images must be greater than 200 x 200, please re-crop the image to be larger.");
          return;
        }

        thumbnailVersion.set('x', x);
        thumbnailVersion.set('y', y);
        thumbnailVersion.set('width', width);
        thumbnailVersion.set('height', height);

        var _this = this;
        _this.transitionToRoute('thumbnail.preview', thumbnailVersion.get('thumbnail'));
      },

      cancel: function cancel() {
        var thumbnailVersion = this.get('model');
        this.transitionToRoute('thumbnail.preview', thumbnailVersion.get('thumbnail'));
      }
    }

  });
});