define('thefader-cms-ember/initializers/cloudinary', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize() /* container, application */{

    $.cloudinary.config({
      cloud_name: 'thefader',
      api_key: '188924896599967'
    });
  }

  ;

  exports['default'] = {
    name: 'cloudinary',
    initialize: initialize
  };
});