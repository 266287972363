define('thefader-cms-ember/routes/posts/headlines', ['exports', 'ember', 'thefader-cms-ember/routes/base', 'ember-cli-pagination/remote/route-mixin'], function (exports, _ember, _thefaderCmsEmberRoutesBase, _emberCliPaginationRemoteRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberCliPaginationRemoteRouteMixin['default'], {

    perPage: 25,

    queryParams: {
      contributor_id: {
        refreshModel: true
      }
    },

    model: function model(params) {
      //console.log("params: ", params);
      params['has_headlines'] = true;

      return _ember['default'].RSVP.hash({
        posts: this.findPaged('post', params),
        countries: this.store.findAll('country', { reload: true })
      });
    },

    setupController: function setupController(controller, models, transition) {
      this._super.apply(this, arguments);

      //console.log("controller: ", controller);
      controller.set('countries', models.countries);
      controller.set('posts', models.posts);
    }
  });
});