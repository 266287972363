define('thefader-cms-ember/controllers/slideshow/image', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      deleteImage: function deleteImage() {
        //console.log("deleteImage called");
        var image = this.get('model');
        var slideshow = image.get('slideshow');
        image.destroyRecord();

        var position = 1;
        slideshow.get('images').forEach(function (image) {
          image.set('position', position);
          position = position + 1;
          image.save();
        });
      }
    }
  });
});