define("thefader-cms-ember/components/published-posts-report", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({

    data: [],

    ajax: _ember["default"].inject.service(),

    dateRangeChoices: _ember["default"].computed(function () {
      return ["Custom", "Today", "Yesterday", "Last Week", "Last Month", "Last 7 days", "Last 30 days"];
    }),

    didInsertElement: function didInsertElement() {
      console.log("didInsertElement");
      var _this = this;

      this.get('ajax').request("/cms_api/v1/reports").then(function (data) {
        console.log("data: ", data);
        _this.draw(data);
      });
    },

    draw: function draw(data) {
      console.log("draw");

      var margin = { top: 20, right: 20, bottom: 50, left: 70 };
      var width = 960 - margin.left - margin.right;
      var height = 500 - margin.top - margin.bottom;

      var parseTime = d3.timeParse("%Y-%m-%d");

      data = data.reports;
      data.forEach(function (d) {
        d.time = parseTime(d.time);
        d.count = +d.count;
        console.log("count: ", d.count);
      });

      var x = d3.scaleTime().range([0, width]);
      var y = d3.scaleLinear().range([height, 0]);

      // define the line
      var count_line = d3.line().x(function (d) {
        return x(d.time);
      }).y(function (d) {
        return y(d.count);
      });

      var svg = d3.select(this.element).append("svg").attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom).append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // Scale the range of the data
      x.domain(d3.extent(data, function (d) {
        return d.time;
      }));
      y.domain([0, d3.max(data, function (d) {
        return d.count;
      })]);

      // Add the valueline path.
      svg.append("path").data([data]).attr("class", "line").attr("d", count_line);

      // Add the x Axis

      var x_axis = d3.axisBottom(x);
      x_axis.ticks(5);

      svg.append("g").attr("transform", "translate(0," + height + ")").call(x_axis);

      // text label for the x axis
      svg.append("text").attr("transform", "translate(" + width / 2 + " ," + (height + margin.top + 20) + ")").style("text-anchor", "middle").text("Date");

      // Add the y Axis
      svg.append("g").call(d3.axisLeft(y));

      // text label for the y axis
      svg.append("text").attr("transform", "rotate(-90)").attr("y", 0 - margin.left).attr("x", 0 - height / 2).attr("dy", "1em").style("text-anchor", "middle").text("Post Count");

      // let chart = d3.select(this.element).append('g').attr('class', 'chart');

      // chart.selectAll('point')
      //   .data(data)
      //   .enter()
      //   .append("circle");

      // let count_extent = d3.extent(
      //   data, function(d) {
      //     return d.count;
      //   }
      // );

      // let count_scale = d3.scaleLinear()
      //     .domain(count_extent)
      //     .range([height, margin]);

      // d3.selectAll('circle')
      //   .attr("cy", function(d) {
      //     return count_scale(d.count);
      //   });

      // let time_extent = d3.extent(data, function(d) {
      //   console.log(d.time);
      //   return new Date(d.time);
      // });

      // console.log(time_extent);

      // let time_scale = d3.scaleTime().domain(time_extent).range([margin, width]);

      // console.log("time_scale: ", time_scale(new Date(2017, 11, 15)));

      // d3.selectAll('circle').attr('cx', function(d) {
      //   console.log("d: ", d);
      //   let t = time_scale(new Date(d.time));
      //   console.log("t: ", t);
      //   return t;
      // })
      //   .attr('r', 3);

      // let time_axis = d3.axisBottom(time_scale);

      // time_axis.ticks(5);

      // d3.select(this.element).append("g")
      //   .attr("class", "x-axis")
      //   .attr("transform", "translate(0," + (height) + ")")
      //   .call(time_axis);
    },

    actions: {
      selectDateRange: function selectDateRange() {
        console.log("selectDateRange");
      }
    }
  });
});