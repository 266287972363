define('thefader-cms-ember/components/slug-field', ['exports', 'ember', 'thefader-cms-ember/components/form-field'], function (exports, _ember, _thefaderCmsEmberComponentsFormField) {
  exports['default'] = _thefaderCmsEmberComponentsFormField['default'].extend({

    didInsertElement: function didInsertElement() {
      //console.log("didInsertElement");
      if (this.get('object.isPublished')) {
        this.$(".form-control").attr('disabled', true);
      }
    },

    keyDown: function keyDown(event) {
      console.log("slugField keyDown");

      if (this.get('object.isPublished')) {
        return;
      }

      this.get('object').set('manualSlug', true);
    },

    disableSlug: (function () {
      if (this.get('object.isPublished')) {
        this.$(".form-control").attr('disabled', true);
      }
    }).observes('object.isPublished'),

    setSlugToTitle: (function () {

      if (this.get('object.isPublished') || this.get('object.manualSlug')) {
        return;
      }

      var title = this.get('object').get(this.get('observeField')).trim();
      var strippedTitle = title.replace(/(<([^>]+)>)/ig, "");

      var autoSlug = strippedTitle.toLowerCase().replace(/ /g, '-').replace(/\$/g, 's').replace(/[^\w-]+/g, '');

      this.set('object.slug', autoSlug);
    }).observes('object.title')
  });
});