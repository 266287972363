define('thefader-cms-ember/routes/daily-newsletters/edit', ['exports', 'ember', 'thefader-cms-ember/routes/base'], function (exports, _ember, _thefaderCmsEmberRoutesBase) {
  exports['default'] = _thefaderCmsEmberRoutesBase['default'].extend({

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        dailyNewsletter: this.store.findRecord('dailyNewsletter', params.daily_newsletter_id),
        countries: this.store.findAll('country')
      });
    },

    setupController: function setupController(controller, model, transitionTo) {
      this._super.apply(this, arguments);

      controller.set('model', model.dailyNewsletter);
      controller.set('countries', model.countries);

      var dailyNewsletterItem = this.get('store').createRecord('dailyNewsletterItem', {
        type: "post"
      });

      controller.set('newDailyNewsletterItem', dailyNewsletterItem);
    }
  });
});