define('thefader-cms-ember/serializers/slideshow', ['exports', 'ember-data', 'active-model-adapter'], function (exports, _emberData, _activeModelAdapter) {
  exports['default'] = _activeModelAdapter.ActiveModelSerializer.extend(_emberData['default'].EmbeddedRecordsMixin).extend({
    isNewSerializerAPI: true,
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },

    attrs: {
      contentBlock: { serialize: false }
    }
  });
});