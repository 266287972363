define('thefader-cms-ember/helpers/is-clipboard-supported', ['exports', 'ember-cli-clipboard/helpers/is-clipboard-supported'], function (exports, _emberCliClipboardHelpersIsClipboardSupported) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliClipboardHelpersIsClipboardSupported['default'];
    }
  });
  Object.defineProperty(exports, 'isClipboardSupported', {
    enumerable: true,
    get: function get() {
      return _emberCliClipboardHelpersIsClipboardSupported.isClipboardSupported;
    }
  });
});