define('thefader-cms-ember/services/pusher-service', ['exports', 'ember', 'thefader-cms-ember/config/environment'], function (exports, _ember, _thefaderCmsEmberConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({

    init: function init() {
      this._super.apply(this, arguments);

      var pusher = new Pusher(_thefaderCmsEmberConfigEnvironment['default'].pusher.key, {
        encrypted: true
      });

      this.set('pusher', pusher);
    }
  });
});