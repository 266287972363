define("thefader-cms-ember/templates/post-package-item/-show", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "thefader-cms-ember/templates/post-package-item/-show.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "edit-form-content-block");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h7");
        var el3 = dom.createTextNode("ID ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" position: ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "content-block-tooltab");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "href", "");
        dom.setAttribute(el3, "class", "content-block-action content-block-edit");
        dom.setAttribute(el3, "title", "Edit Item");
        var el4 = dom.createTextNode("Edit");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "href", "");
        dom.setAttribute(el3, "title", "Move Up");
        var el4 = dom.createTextNode("Move Up");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "class", "content-block-arrow content-block-grab_item bf_dom");
        dom.setAttribute(el3, "title", "Grab");
        var el4 = dom.createTextNode("Grab");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "title", "Move Down");
        var el4 = dom.createTextNode("Move Down");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "class", "content-block-action content-block-delete");
        dom.setAttribute(el3, "title", "Delete Item");
        var el4 = dom.createTextNode("Delete");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "edit-form-content-block-content-area");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "content-block-content");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "style", "clear:both; padding-bottom: 20px;");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [3]);
        var element5 = dom.childAt(element2, [7]);
        var element6 = dom.childAt(element2, [9]);
        var morphs = new Array(10);
        morphs[0] = dom.createAttrMorph(element0, 'data-id');
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        morphs[2] = dom.createMorphAt(element1, 3, 3);
        morphs[3] = dom.createElementMorph(element3);
        morphs[4] = dom.createAttrMorph(element4, 'class');
        morphs[5] = dom.createElementMorph(element4);
        morphs[6] = dom.createAttrMorph(element5, 'class');
        morphs[7] = dom.createElementMorph(element5);
        morphs[8] = dom.createElementMorph(element6);
        morphs[9] = dom.createMorphAt(dom.childAt(element0, [5, 1]), 1, 1);
        return morphs;
      },
      statements: [["attribute", "data-id", ["concat", [["get", "model.id", ["loc", [null, [1, 48], [1, 56]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "model.id", ["loc", [null, [3, 9], [3, 21]]], 0, 0, 0, 0], ["content", "model.position", ["loc", [null, [3, 32], [3, 50]]], 0, 0, 0, 0], ["element", "action", ["edit"], [], ["loc", [null, [6, 7], [6, 24]]], 0, 0], ["attribute", "class", ["concat", ["content-block-arrow content-block-arrow_up ", ["subexpr", "if", [["get", "moveUpIsEnabled", ["loc", [null, [7, 90], [7, 105]]], 0, 0, 0, 0], "enabled", "disabled"], [], ["loc", [null, [7, 85], [7, 128]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["moveUp"], [], ["loc", [null, [7, 7], [7, 26]]], 0, 0], ["attribute", "class", ["concat", ["content-block-arrow content-block-arrow_down ", ["subexpr", "if", [["get", "moveDownIsEnabled", ["loc", [null, [9, 86], [9, 103]]], 0, 0, 0, 0], "enabled", "disabled"], [], ["loc", [null, [9, 81], [9, 126]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["moveDown"], [], ["loc", [null, [9, 7], [9, 28]]], 0, 0], ["element", "action", ["delete"], [], ["loc", [null, [10, 7], [10, 26]]], 0, 0], ["inline", "partial", ["daily-newsletter-item/post-show"], [], ["loc", [null, [16, 6], [16, 51]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});