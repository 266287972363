define("thefader-cms-ember/models/admin", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].Model.extend({
    firstName: _emberData["default"].attr("string"),
    lastName: _emberData["default"].attr("string"),
    email: _emberData["default"].attr("string"),
    contributorIdCached: _emberData["default"].attr("number"),
    role: _emberData["default"].attr("string"),

    contributor: _emberData["default"].belongsTo("contributor", { async: false }),

    displayName: (function () {
      if (this.get("contributor")) {
        return this.get("contributor").get("displayName");
      } else {
        return this.get("email");
      }
    }).property("contributor", "email"),

    isAuthor: (function () {
      return this.get("role") == "author";
    }).property("role"),

    isSuperUser: (function () {
      return this.get("role") == "super_user";
    }).property("role")
  });
});