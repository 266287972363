define('thefader-cms-ember/routes/posts/optimizing', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model(params) {
      return this.store.find('post', params.post_id);
    },

    actions: {
      edit: function edit() {
        this.transitionTo('posts.optimize', this.get('currentModel.id'));
      }
    }
  });
});