define('thefader-cms-ember/controllers/content-blocks/delete', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      cancel: function cancel() {
        var post = this.get("model.post");
        this.transitionToRoute('posts.show', post.get('id'));
      },

      'delete': function _delete() {
        var model = this.get('model');
        var post = model.get('post');

        // debugger;

        var currentPosition = model.get('position');

        if (currentPosition === post.get('contentBlocks.length')) {
          //console.log("last content block");
          model.destroyRecord();
        } else {

          model.destroyRecord();

          var contentBlocksToUpdate = post.get('contentBlocks').filter(function (item, index) {
            return item.get('position') > currentPosition;
          });

          var position = currentPosition;
          contentBlocksToUpdate.forEach(function (contentBlock) {

            contentBlock.set('position', position);
            contentBlock.save();

            position = position + 1;
          });
        }

        this.transitionToRoute('posts.show', post.get('id'));
      }
    }
  });
});