define("thefader-cms-ember/components/published-apple-music-report", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    ajax: _ember["default"].inject.service(),

    didInsertElement: function didInsertElement() {
      this.getData();
    },

    warningMessage: (function () {
      var today = moment();
      var endDate = moment([2019, 11, 1]);
      var days = endDate.diff(today, "days") + 1;

      var numPostsRemaining = 3000 - this.get("data.total_since");

      var numPostsPerDay = Math.round(numPostsRemaining / days);
      var numPostsPerWeek = numPostsPerDay * 7;

      var warningMessage = numPostsRemaining + " more posts are needed over the next " + days + " days which is approximately " + numPostsPerDay + " posts a day or " + numPostsPerWeek + " posts per week.";

      return warningMessage;
    }).property("data"),

    getData: function getData() {
      var _this = this;

      this.get("ajax").request("/cms_api/v1/reports/apple_music_report").then(function (data) {
        console.log("data: ", data);
        _this.set("data", data);
      });
    }
  });
});