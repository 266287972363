define('thefader-cms-ember/components/post-show', ['exports', 'ember', 'thefader-cms-ember/config/environment'], function (exports, _ember, _thefaderCmsEmberConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['posts-show'],

    sessionCurrentAdmin: _ember['default'].inject.service('session-current-admin'),
    ajax: _ember['default'].inject.service(),

    showErrors: false,
    selectedRightBarControl: 'settings',

    multiSiteEnabled: (function () {
      return _thefaderCmsEmberConfigEnvironment['default'].APP.ENABLE_MULTI_SITE;
    }).property(),

    showNewContentBlock: true,

    showSuccessMessage: false,
    showInfoMessage: false,

    artistSearchTerm: '',

    artistsForTypeahead: (function () {
      var term = this.get('artistSearchTerm');
      return this.store.find('artist', { name: term });
    }).property('artistSearchTerm'),

    eventSearchTerm: '',

    eventsForTypeahead: (function () {
      var term = this.get('eventSearchTerm');
      return this.store.find('event', { name: term });
    }).property('eventSearchTerm'),

    metaTagSearchTerm: '',

    metaTagsForTypeahead: (function () {
      var term = this.get('metaTagSearchTerm');
      return this.store.find('metaTag', { name: term });
    }).property('metaTagSearchTerm'),

    previewLink: (function () {
      var url = _thefaderCmsEmberConfigEnvironment['default']['legacyCmsBaseUrl'] + "/cms/posts/" + this.get('model').get('id') + '/preview';
      return url.replace('https', 'http');
      return url;
    }).property('model.id'),

    clientPreviewLink: (function () {
      var url = _thefaderCmsEmberConfigEnvironment['default']['legacyCmsBaseUrl'] + "/client_posts/" + this.get('model').get('id');
      return url.replace('https', 'http');
      return url;
    }).property('model.id'),

    livePostLink: (function () {
      var url = _thefaderCmsEmberConfigEnvironment['default']['legacyCmsBaseUrl'] + this.get('model.permalink');
      return url.replace('https', 'http');
    }).property('model.id'),

    showSuccessfullySaved: function showSuccessfullySaved(post) {
      //console.log("showSuccessfullySaved");
    },

    handlePostErrors: function handlePostErrors(errors) {
      //console.log("handlePostErrors", errors);
      var post = this.get('model');
      //console.log(post.get('errors'));
      _this.set('selectedRightBarControl', 'alerts');
    },

    hasErrors: (function () {
      // debugger;
      return this.get('model.hasFullErrors') || this.get('newContentBlock.hasErrors');
    }).property("model.hasFullErrors", "newContentBlock.hasErrors"),

    numErrors: (function () {
      var numErrors = this.get('model').get('fullErrorsLength');
      numErrors += this.get('newContentBlock.errors.length');
      //console.log("numErrors: ", numErrors);
      return numErrors;
    }).property("model.hasFullErrors", "newContentBlock.hasErrors"),

    allErrors: (function () {
      var errorsForEachModel = [];
      errorsForEachModel.push(['post', this.get('model.errors')]);

      this.get('model.contentBlocks').forEach(function (contentBlock, index) {
        var contentBlockIndex = index + 1;
        errorsForEachModel.push(['content block ' + contentBlockIndex, contentBlock.get('errors')]);
      });

      errorsForEachModel.push(['new content block', this.get('newContentBlock.errors')]);
      return errorsForEachModel;
    }).property("model.hasFullErrors", "newContentBlock.hasErrors"),

    setIssue: (function () {
      //console.log("set magazine issue");
      //console.log("selectedIssue: ", this.get('selectedIssue'));

      var issueId = this.get('selectedIssue');
      if (issueId) {

        var issue = this.store.getById('issue', issueId);
        //console.log("issue: ", issue);
        this.get('model').set('issue', issue);
      }
    }).observes('selectedIssue'),

    selectedIssue: (function () {
      var issue = this.get('model').get('issue');
      //console.log("selectedIssue: ", issue);

      if (issue) {
        return issue.get('id');
      } else {
        return null;
      }
    }).property(),

    setClientHub: (function () {
      //console.log("set magazine clientHub");
      //console.log("selectedClientHub: ", this.get('selectedClientHub'));

      var clientHubId = this.get('selectedClientHub');
      if (clientHubId) {

        var clientHub = this.store.getById('clientHub', clientHubId);
        //console.log("clientHub: ", clientHub);
        this.get('model').set('clientHub', clientHub);
      }
    }).observes('selectedClientHub'),

    selectedClientHub: (function () {
      var clientHub = this.get('model').get('clientHub');
      //console.log("selectedClientHub: ", clientHub);

      // debugger;

      if (clientHub) {
        return clientHub.get('id');
      } else {
        return null;
      }
    }).property(),

    isSuperFeature: (function () {
      return this.get('model.articleType') === "super_feature";
    }).property('model.articleType'),

    isCustom: (function () {
      return this.get('model.articleType') === "custom";
    }).property('model.articleType'),

    isFeature: (function () {
      return this.get('model.articleType') === "feature";
    }).property('model.articleType'),

    isPhotoEssay: (function () {
      return this.get('model.articleType') === "photo_essay";
    }).property('model.articleType'),

    isGallery: (function () {
      return this.get('model.articleType') === "gallery";
    }).property('model.articleType'),

    isSimple: (function () {
      return this.get('model.articleType') === "simple";
    }).property('model.articleType'),

    isVideo: (function () {
      return this.get('model.articleType') === "video";
    }).property('model.articleType'),

    savePost: function savePost(attemptPublish) {
      this.set('showErrors', true);
      this.set('showSuccessMessage', false);

      var _this = this;
      var post = this.get('model');

      post.save().then(function () {
        console.log("saved");
        _this.set('showSuccessMessage', true);

        if (attemptPublish) {
          _this.get('router').transitionTo('posts.published', post.get('id'));
        }
      }, function () {

        var errors = post.get('errors');
        console.log("errors: ", errors);

        if (errors.has('approvalState')) {
          post.set("approvalState", errors.get('approvalState')[0]['message']);
        }

        // debugger;

        // var approvalStateErrors = post.get("errors.appprovalState");

        // console.log("appprovalStateErrors: ", approvalStateErrors);

        // post.reload();
        _this.set('selectedRightBarControl', 'alerts');

        // debugger;
        // _this.send('reloadPost');
      });
    },

    scheduleSetSidebarHeight: (function () {
      this.trigger('settingsOpened');
    }).observes('selectedRightBarControl'),

    actions: {

      reloadPost: function reloadPost() {
        console.log("reloading post");
        this.get('model').reload();
      },

      approve: function approve() {
        console.log("approved");
        var _this = this;
        var post = this.get('model');

        this.get('ajax').post("/cms_api/v1/posts/" + this.get('model.id') + "/approve").then(function () {
          console.log("approved!");
          // post.reload();

          post.set("approvalState", "Approved");

          _this.set('infoMessage', "This post is now approved and can be published.");
          _this.set('showInfoMessage', true);
        });
      },

      deny: function deny() {
        console.log("denied");

        var _this = this;
        var post = this.get('model');

        this.get('ajax').post("/cms_api/v1/posts/" + this.get('model.id') + "/deny").then(function () {

          console.log("denied!");
          // post.reload();

          post.set("approvalState", "Denied");
        });
      },

      saveDraft: function saveDraft() {
        //console.log("saveDraft");
        this.savePost(false);
      },

      publish: function publish() {
        //console.log("publishing");

        var _this = this;
        var post = this.get('model');

        if (post.get('queueTimeState') === 'scheduled') {
          post.set('publishedState', 'Scheduled');
          post.set('publishedAt', post.get('queueTime'));
        } else {
          post.set('publishedState', 'AttemptPublish');
          // post.set('publishedAt', moment().toDate());
        }

        post.set('currentEditor', null);

        this.savePost(true);
      },

      publishChanges: function publishChanges() {
        //console.log("publishing changes");
        var post = this.get('model');
        post.set('currentEditor', null);
        this.savePost(false);
      },

      purgeCache: function purgeCache() {
        var _this = this;
        this.get('ajax').post("/cms_api/v1/posts/" + this.get('model.id') + "/purge").then(function () {
          _this.set('infoMessage', "Frontend cache purged. This can take up to 1 second to propagate.");
          _this.set('showInfoMessage', true);
        });
      },

      addContentBlock: function addContentBlock() {

        var body = this.get('newContentBlockBody');

        if (!body) {
          return false;
        }
        if (!body.trim()) {
          return;
        }

        //console.log("body: " + body);

        var lastContentBlock = this.get('model').get('contentBlocks').get('lastObject');
        var position;

        if (lastContentBlock) {
          //console.log("we have a lastContentBlock");

          position = lastContentBlock.get('position') + 1;
        } else {
          position = 1;
        }

        //console.log("position: " + position);

        var contentBlock = this.store.createRecord('contentBlock', {
          body: body,
          post: this.get('model'),
          position: position,
          headline: this.get('newContentBlockHeadline'),
          attribution: this.get('newContentBlockAttribution'),
          cloudinaryPublicId: this.get('newContentBlockCloudinaryPublicId')
        });

        this.set('newContentBlockHeadline', '');
        this.set('newContentBlockBody', '');
        this.set('newContentBlockAttribution', '');
        this.set('newContentBlockCloudinaryPublicId', '');

        contentBlock.save();
      },

      makeNewPostCredit: function makeNewPostCredit() {
        var newPC = this.store.createRecord('postCredit');
        newPC.set('type', 'Author');
        this.set('newPostCredit', newPC);
      },

      makeNewContentBlock: function makeNewContentBlock() {
        console.log("makeNewContentBlock");
        var newCb = this.store.createRecord('contentBlock');
        newCb.set('contentBlockType', 'text');
        newCb.set('isEditing', true);
        this.set('newContentBlock', newCb);
      },

      displayErrors: function displayErrors() {
        this.set('showErrors', true);
        this.set('selectedRightBarControl', 'alerts');
      }
    },

    didInsertElement: function didInsertElement() {
      console.log("didInsertElement");
      this.$(window).bind('scroll', _ember['default'].run.bind(this, this.scrollWithDebounce));

      var _this = this;
      var pusher = this.get('pusher-service.pusher');
      var currentAdmin = this.get('sessionCurrentAdmin.admin');
      var router = this.get('router');
      var post = this.get('model');

      var post_channel = pusher.subscribe('post_channel_' + post.get('id'));

      post_channel.bind('override', function (data) {
        console.log("data: ", data);

        // debugger;
        // if (post.get('id') != data.post_id) { return; }

        if (currentAdmin.get('id') == data.admin_id) {
          console.log("overriding!");
          router.transitionTo('posts.show', post.get('id'));
        } else {
          console.log("locking!");
          post.reload().then(function (p) {
            router.transitionTo('posts.show.locked', p.get('id'));
          });
        }
      });
    },

    willDestroyElement: function willDestroyElement() {
      // console.log("willDestroyElement");
      this.$(window).unbind('scroll');

      var post = this.get('model');
      var pusher = this.get('pusher-service.pusher');
      pusher.unsubscribe('post_channel_' + post.get('id'));

      var currentAdmin = this.get('sessionCurrentAdmin.admin');

      if (post.get('currentEditor') == currentAdmin) {
        console.log("releasing lock");
        this.get('ajax').post("/cms_api/v1/posts/" + post.get('id') + "/unlock_current_editor").then(function () {
          console.log("unlock successfully set!");
        });
      }
    },

    willClearRender: function willClearRender() {
      this.get('controller').off('settingsOpened', this, this.setSidebarHeight);
    },

    scrollWithDebounce: function scrollWithDebounce(event) {
      var debounceContext = { event: event };
      _ember['default'].run.debounce(debounceContext, this.scrolled, 10);
    },

    scrolled: function scrolled() {
      var event = this.event;
      // console.log("window scrolled: ", event);

      // Get the position of the location where the scroller starts.
      var saveButtonsAnchor = $(".save-buttons-anchor");
      if (!saveButtonsAnchor) {
        return;
      }

      var scrollerAnchor = saveButtonsAnchor.offset().top;

      // Check if the user has scrolled and the current position is after the scroller start location and if its not already fixed at the top
      if ($(window).scrollTop() >= scrollerAnchor && $('.save-buttons').css('position') != 'fixed') {

        // fix the save buttons to the top of the screen
        $('.save-buttons').css({
          'position': 'fixed',
          'top': '0px'
        });

        // move the right bar up under the save buttons
        $('#suplist_right_bar_wrapper').css({
          'top': '60px'
        });

        $('#suplist_right_bar_menu').css({
          'top': '60px'
        });

        $('#suplist_right_post_controls').css({
          'top': '60px'
        });

        // chnage the height of the scroller anchor to that of scroller so that
        // there is no change in the overall height of the page.
        $('.save-buttons-anchor').css('height', '54px');
      } else if ($(window).scrollTop() < scrollerAnchor && $('.save-buttons').css('position') != 'relative') {
        // If the user has scrolled back to the location above the scroller anchor place it back into the content.

        // Change the height of the scroller anchor to 0 and now we will be adding the scroller back to the content.
        $('.save-buttons-anchor').css('height', '0px');

        $('.save-buttons').css({
          'position': 'relative'
        });

        // put it back to its original position.
        $('#suplist_right_bar_wrapper').css({
          'top': '120px'
        });

        $('#suplist_right_bar_menu').css({
          'top': '120px'
        });

        $('#suplist_right_post_controls').css({
          'top': '120px'
        });
      }
    }
  });
});