define('thefader-cms-ember/controllers/posts/destroy', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      cancel: function cancel() {
        var post = this.get("model");
        this.transitionTo('posts', { queryParams: { contributor_id: this.get('session').get('contributor_id') } });
      },

      'delete': function _delete() {
        var post = this.get('model');
        post.destroyRecord();
        this.transitionTo('posts', { queryParams: { contributor_id: this.get('session').get('contributor_id') } });
      }
    }
  });
});