define('thefader-cms-ember/routes/daily-newsletters/index', ['exports', 'ember', 'thefader-cms-ember/routes/base', 'thefader-cms-ember/config/environment', 'ember-cli-pagination/remote/route-mixin'], function (exports, _ember, _thefaderCmsEmberRoutesBase, _thefaderCmsEmberConfigEnvironment, _emberCliPaginationRemoteRouteMixin) {
  exports['default'] = _thefaderCmsEmberRoutesBase['default'].extend(_emberCliPaginationRemoteRouteMixin['default'], {

    perPage: 25,

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        dailyNewsletters: this.findPaged('dailyNewsletter', params),
        countries: this.store.findAll('country', { reload: true })
      });
    },

    setupController: function setupController(controller, models, transition) {
      this._super.apply(this, arguments);

      controller.set('model', models.dailyNewsletters);
      controller.set('countries', models.countries);
    },

    actions: {
      create: function create() {
        console.log("create action");

        var controller = this.get('controller');
        var defaultCountry = this.get('controller.countries').findBy('isUs', true);

        var dailyNewsletter = this.store.createRecord('dailyNewsletter', {
          date: new Date(),
          country: defaultCountry
        });

        dailyNewsletter.save().then(function () {
          controller.transitionToRoute('daily-newsletters.edit', dailyNewsletter.get('id'));
        });
      }
    }
  });
});