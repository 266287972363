define('thefader-cms-ember/models/thumbnail-version', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    thumbnail: _emberData['default'].belongsTo('thumbnail', { async: false }),

    name: _emberData['default'].attr('string'),
    x: _emberData['default'].attr('number'),
    y: _emberData['default'].attr('number'),
    width: _emberData['default'].attr('number'),
    height: _emberData['default'].attr('number'),

    // http://res.cloudinary.com/thefader/image/upload/c_fit,h_535,w_200/ypsykdcx3mthu92pcaqv
    cloudinaryPublicUrl: (function () {

      var publicId = this.get('thumbnail').get('cloudinaryPublicId');
      var uploadType = this.get('thumbnail.uploadType');

      if (this.get('thumbnail.isModelVersion1')) {
        var url = "http://res.cloudinary.com/thefader/image/upload/c_fit,w_800,h_500/" + publicId;
      } else {
        var url = "http://res.cloudinary.com/thefader/image/" + uploadType + "/c_limit,w_1024/" + publicId;
      }

      return url;
    }).property('thumbnail.cloudinaryPublicId')

  });
});