define('thefader-cms-ember/models/post-package-item', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    postPackage: _emberData['default'].belongsTo('postPackage', { inverse: 'postPackageItems' }),
    prePostPackageId: _emberData['default'].attr('number'),

    position: _emberData['default'].attr('number'),

    postUrl: _emberData['default'].attr('string'),
    post: _emberData['default'].belongsTo('post', { inverse: null }),

    skipValidation: _emberData['default'].attr('boolean')

  });
});