define('thefader-cms-ember/components/content-block-content', ['exports', 'ember', 'thefader-cms-ember/components/form-field'], function (exports, _ember, _thefaderCmsEmberComponentsFormField) {
  exports['default'] = _thefaderCmsEmberComponentsFormField['default'].extend({
    classNames: ['content-block-content-control'],

    setup: function setup() {
      var val = this.get('value');
      //console.log("val is: ", val);

      // var _controller = this.get('targetObject');

      if (!val) {
        return;
      }

      if (val.length === 0) {
        this.sendAction("action", false);
      } else if (val.length >= 1) {
        this.sendAction("action", true);
      }
    },

    input: function input(event) {

      this.setup();
      var _this = this;
      var val = this.get('value');

      if (val.match(/^https:\/\/twitter.com.*/)) {

        $.ajax({
          url: "https://api.twitter.com/1/statuses/oembed.json?url=" + val,
          dataType: "jsonp",
          success: function success(data) {
            //// console.log(data);
            _this.sendAction("setContentBlockData", "tweet", { body: data.html });
          }
        });
      } else if (val.match(/^https:\/\/w?w?w?\.?instagr.*/)) {

        $.ajax({
          url: "https://api.instagram.com/oembed?url=" + val,
          dataType: "jsonp",
          success: function success(data) {
            //console.log("instgram result: ", data);
            _this.sendAction("setContentBlockData", "instagram", { body: data.html });
          }
        });
      } else if (val.match(/^https:\/\/soundcloud\.com.*/)) {

        $.ajax({
          url: "http://soundcloud.com/oembed?url=" + val,
          dataType: "json",
          success: function success(data) {
            //console.log("soundcloud result: ", data);

            _this.sendAction("setContentBlockData", "audio", { body: '',
              audioUrl: val
            });

            $('.video-preview-image').attr('src', data['thumbnail_url']);
          }
        });
      } else if (val.match(/^https?:\/\/w?w?w?.?youtube.*/) || val.match(/^https?:\/\/youtu.be.*/)) {

        var videoUrl;

        if (val.match(/^https:\/\/www.youtube.*/)) {
          videoUrl = val.replace("https://www.youtube.com", "http://youtube.com");
        } else if (val.match(/^http:\/\/youtu.be.*/)) {
          videoUrl = val.replace("http://youtu.be/", "http://youtube.com/watch?v=");
        } else {
          videoUrl = val;
        }

        var matches = videoUrl.match(/^https?:\/\/w?w?w?.?youtube\.com\/.*[?&]v=([^&]+)/i) || videoUrl.match(/^https?:\/\/youtu\.be\/([^?]+)/i);
        if (matches) {

          var videoId = matches[1];

          this.sendAction("setContentBlockData", "video", { body: '',
            videoUrl: videoUrl,
            youtubeVideoId: videoId
          });
          $.ajax({
            url: "https://www.googleapis.com/youtube/v3/videos",
            context: this,
            data: {
              key: "AIzaSyDDD2FvyKqqEoBSgTLtl49nB_x74xOLmhQ",
              part: "snippet,statistics",
              id: videoId
            },
            success: function success(data) {
              //console.log("youtube data: ", data);
              // debugger;
              $("#" + elementId).parent().parent().parent().find('.video-preview-image').attr('src', data['items'][0]['snippet']['thumbnails']['default']['url']);
            }
          });
        }
      } else if (val.match(/^https:\/\/vimeo\.com.*/)) {

        $.ajax({
          url: "https://vimeo.com/api/oembed.json?url=" + val,
          data: {
            maxwidth: 500
          },
          dataType: "json",
          success: function success(data) {
            //console.log("vimeo result: ", data);

            _this.sendAction("setContentBlockData", "embed", { body: '',
              embed: data.html
            });

            // $('.video-preview-image').attr('src', data['thumbnail_url']);
          }
        });

        // TODO: vine oembed not working due to CORS error. Need to further debug.
      } else if (val.match(/^https:\/\/vine\.co.*/)) {

          $.ajax({
            url: "https://api.embed.ly/1/oembed?key=cc3c433a3c7440b8b937d90d28d834e6&url=" + val,
            data: {
              maxwidth: 500
            },
            dataType: "json",
            crossDomain: true,
            // headers: {
            //   'Access-Control-Allow-Origin': true,
            //   'Access-Control-Allow': 'http://localhost:4200'
            // },
            success: function success(data) {
              //console.log("vine result: ", data);

              _this.sendAction("setContentBlockData", "embed", { body: '',
                embed: data.html
              });
            }
          });
        } else {
          this.setTextareaHeight();
        }
    },

    setTextareaHeight: function setTextareaHeight() {
      // console.log("setTextareaHeight");

      var val = this.get('value');
      if (!val) {
        return;
      }

      var baseMinHeight = 68;
      var lines = val.split(/\n\r?/g).length;
      var minHeight = baseMinHeight + lines * 40;
      var maxHeight = 374;

      // console.log("lines: ", lines);
      // console.log("minHeight: ", minHeight);
      // console.log("maxHeight: ", maxHeight);

      if (minHeight <= maxHeight) {
        this.$("textarea").css('min-height', minHeight);
      } else {
        this.$("textarea").css('min-height', maxHeight);
      }
    },

    focusIn: function focusIn(event) {
      this._super();
      this.setup();
      this.setTextareaHeight();
    },

    didInsertElement: function didInsertElement() {
      //console.log("didInsertElement");
      this.setup();
      this.setTextareaHeight();
    }
  });
});