define('thefader-cms-ember/routes/base', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    showHeader: true,

    setupController: function setupController() {
      this._super.apply(this, arguments);
      this.controllerFor('application').set('showHeader', this.get('showHeader'));
    }

  });
});