define('thefader-cms-ember/components/cloudinary-uploader', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'input',
    name: 'file',
    attributeBindings: ['name', 'type', 'data-form-data'],
    type: 'file',
    classNames: ['cloudinary-fileupload'],

    didInsertElement: function didInsertElement() {
      //console.log("inserting cloudinary fileupload field");

      $(document).bind('drop dragover', function (e) {
        e.preventDefault();
      });

      var _this = this;

      var dropZoneClass = this.get('dropZone');
      var dropZone = null;
      if (dropZoneClass) {
        dropZone = this.$().parents('.' + dropZoneClass);
        //console.log("dropZone: ", dropZone);
      }

      // var cloudinaryPresetName = "ehdchxug";
      var cloudinaryPresetName = "unsigned_private";

      _ember['default'].run.later(function () {

        // debugger;

        _this.$().unsigned_cloudinary_upload(cloudinaryPresetName, { cloud_name: "thefader" }, {
          disableImageResize: true,
          // imageMaxWidth: 1000,
          // imageMaxHeight: 1000,
          acceptFileTypes: /(\.|\/)(gif|jpe?g|png|bmp|ico)$/i,
          // maxFileSize: 10000000, // 10MB
          dropZone: dropZone,
          responsive_breakpoints: {
            create_derived: false,
            bytes_step: 20000,
            min_width: 200,
            max_width: 1000,
            max_images: 20
          }
        });

        _this.$().bind('fileuploadstart', function (e, data) {
          //console.log("fileuploadstart");
          _this.sendAction('start');
        });

        _this.$().bind('fileuploaddone', function (e, data) {

          console.log("fileuploaddone", data);

          var publicId = data.result.public_id;
          var extension = data.result.format;
          publicId += "." + extension;
          var width = data.result.width;
          var height = data.result.height;
          var format = data.result.format;

          _this.sendAction('done', publicId, width, height, format, data.result);
        });
      });
    }
  });
});