define('thefader-cms-ember/controllers/post-packages/edit/post-package-item/delete', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      cancel: function cancel() {
        var model = this.get("model");
        var postPackage = model.get('postPackage');
        this.transitionToRoute('post-packages.edit', postPackage.get('id'));
      },

      'delete': function _delete() {
        var model = this.get('model');
        var postPackage = model.get('postPackage');

        // debugger;

        var currentPosition = model.get('position');

        if (currentPosition === postPackage.get('postPackageItems.length')) {
          //console.log("last content block");
          model.destroyRecord();
        } else {

          model.destroyRecord();

          var postPackageItemsToUpdate = postPackage.get('postPackageItems').filter(function (item, index) {
            return item.get('position') > currentPosition;
          });

          var position = currentPosition;
          postPackageItemsToUpdate.forEach(function (postPackageItem) {

            postPackageItem.set('position', position);
            postPackageItem.save();

            position = position + 1;
          });
        }

        this.transitionToRoute('post-packages.edit', postPackage.get('id'));
      }
    }
  });
});