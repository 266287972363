define('thefader-cms-ember/routes/thumbnail/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      var post = this.modelFor('post');
      controller.set('post', post);
      controller.set('model', model);
    }
  });
});