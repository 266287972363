define('thefader-cms-ember/components/headline-title', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      remove: function remove() {
        //console.log("remove headline");
        var headline = this.get('headline');
        // headline.deleteRecord();
        headline.destroyRecord();
      }
    }
  });
});