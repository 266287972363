define('thefader-cms-ember/controllers/daily-newsletters/edit', ['exports', 'ember', 'thefader-cms-ember/config/environment'], function (exports, _ember, _thefaderCmsEmberConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    ajax: _ember['default'].inject.service(),

    previewLink: (function () {
      var url = _thefaderCmsEmberConfigEnvironment['default']['legacyCmsBaseUrl'] + "/cms/daily_newsletters/" + this.get('model').get('id') + '/preview2';
      return url.replace('https', 'http');
      return url;
    }).property('model.id'),

    actions: {
      selectCountry: function selectCountry(selectedCountryId) {
        var selectedCountry = this.store.peekRecord('country', selectedCountryId);
        this.get('model').set('country', selectedCountry);
      },

      newItemAdded: function newItemAdded() {
        console.log("newItemAdded");
        var dailyNewsletterItem = this.get('store').createRecord('dailyNewsletterItem', {
          type: "post"
        });

        this.set('newDailyNewsletterItem', dailyNewsletterItem);
      },

      save: function save() {
        var _this = this;
        var model = this.get('model');

        model.save().then(function () {
          console.log("saved");
          _this.set('showSuccessMessage', true);
        }, function () {
          console.log("invalid");
        });
      },

      sendToMailchimp: function sendToMailchimp() {
        var url = "/cms_api/v1/daily_newsletters/" + this.get('model').get('id') + '/send_to_mailchimp';
        console.log("sendToMailchimp:", url);

        var _this = this;

        this.get('ajax').post(url).then(function () {
          _this.set('showInfoMessage', true);
        });
      }
    }
  });
});