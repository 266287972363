define('thefader-cms-ember/controllers/post-packages/edit', ['exports', 'ember', 'thefader-cms-ember/config/environment'], function (exports, _ember, _thefaderCmsEmberConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      newItemAdded: function newItemAdded() {
        console.log("newItemAdded");
        var postPackageItem = this.get('store').createRecord('postPackageItem', {});

        this.set('newPostPackageItem', postPackageItem);
      },

      save: function save() {
        var _this = this;
        var model = this.get('model');

        model.save().then(function () {
          console.log("saved");
          _this.set('showSuccessMessage', true);
        }, function () {
          console.log("invalid");
        });
      }
    }
  });
});