define("thefader-cms-ember/components/content-block", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    didInsertElement: function didInsertElement() {
      _ember["default"].run(function () {
        instgrm.Embeds.process();
      });
    },

    isExpanded: false,

    articleType: _ember["default"].computed.alias("post.articleType"),

    contentBlockIsText: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "text";
    }).property("model.contentBlockType"),

    contentBlockIsPullQuote: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "pull_quote";
    }).property("model.contentBlockType"),

    contentBlockIsNewsletter: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "newsletter";
    }).property("model.contentBlockType"),

    contentBlockIsBlockQuote: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "block_quote";
    }).property("model.contentBlockType"),

    contentBlockIsImage: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "image";
    }).property("model.contentBlockType"),

    contentBlockIsVideo: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "video";
    }).property("model.contentBlockType"),

    contentBlockIsEmbed: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "embed";
    }).property("model.contentBlockType"),

    contentBlockIsAudio: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "audio";
    }).property("model.contentBlockType"),

    contentBlockIsTweet: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "tweet";
    }).property("model.contentBlockType"),

    contentBlockIsInstagram: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "instagram";
    }).property("model.contentBlockType"),

    contentBlockIsDiptych: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "diptych";
    }).property("model.contentBlockType"),

    contentBlockIsSlideshow: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "slideshow";
    }).property("model.contentBlockType"),

    contentBlockIsRelatedArticle: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "related_article";
    }).property("model.contentBlockType"),

    contentBlockIsTitle: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "title";
    }).property("model.contentBlockType"),

    contentBlockIsHeader: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "header";
    }).property("model.contentBlockType"),

    contentBlockIsHtml5Video: (function () {
      if (!this.get("model")) {
        return false;
      }
      return this.get("model").get("contentBlockType") === "html5_video";
    }).property("model.contentBlockType"),

    diptychContentTypes: (function () {
      return ["image", "soundcloud", "youtube", "embed", "quote"];
    }).property(),

    diptychContent1TypeIsImage: (function () {
      return this.get("model.diptychContent1Type") === "image";
    }).property("model.diptychContent1Type"),

    diptychContent1TypeIsQuote: (function () {
      return this.get("model.diptychContent1Type") === "quote";
    }).property("model.diptychContent1Type"),

    diptychContent1TypeIsSoundcloud: (function () {
      return this.get("model.diptychContent1Type") === "soundcloud";
    }).property("model.diptychContent1Type"),

    diptychContent1TypeIsYoutube: (function () {
      return this.get("model.diptychContent1Type") === "youtube";
    }).property("model.diptychContent1Type"),

    diptychContent1TypeIsEmbed: (function () {
      return this.get("model.diptychContent1Type") === "embed";
    }).property("model.diptychContent1Type"),

    diptychContent2TypeIsImage: (function () {
      return this.get("model.diptychContent2Type") === "image";
    }).property("model.diptychContent2Type"),

    diptychContent2TypeIsQuote: (function () {
      return this.get("model.diptychContent2Type") === "quote";
    }).property("model.diptychContent2Type"),

    diptychContent2TypeIsSoundcloud: (function () {
      return this.get("model.diptychContent2Type") === "soundcloud";
    }).property("model.diptychContent2Type"),

    diptychContent2TypeIsYoutube: (function () {
      return this.get("model.diptychContent2Type") === "youtube";
    }).property("model.diptychContent2Type"),

    diptychContent2TypeIsEmbed: (function () {
      return this.get("model.diptychContent2Type") === "embed";
    }).property("model.diptychContent2Type"),

    firstSlideshowImagePublicId: (function () {
      // debugger;
      console.log("firstSlideshowImagePublicId");

      if (!this.get("contentBlockIsSlideshow")) {
        return;
      }
      if (!this.get("model.slideshow.id")) {
        return;
      }

      console.log("firstSlideshowImagePublicId2");

      return this.get("model.slideshow").get("images").get("firstObject").get("cloudinaryPublicId");
    }).property("model.slideshow.images.@each.cloudinaryPublicId"),

    mediaCanBeInsetWithContent: (function () {
      return false;

      // if (this.get('postShowController.isVideo') || this.get('postShowController.isPhotoEssay') ||
      //    this.get('postShowController.isGallery')) { return false; }
      // return this.get("contentBlockIsImage") || this.get("contentBlockIsAudio") || (this.get("contentBlockIsText") && this.get('isQuote'));
    }).property("model.contentBlockType"),

    mediaCanBeBreaker: (function () {
      return false;

      // if (this.get('postIsFeature') || this.get('postIsSuperFeature') || this.get('postShowController.isGallery')) {
      //   return this.get("contentBlockIsImage") || this.get("contentBlockIsAudio") || (this.get("contentBlockIsText") && this.get('isQuote')) || this.get('contentBlockIsEmbed') || this.get('contentBlockIsVideo');
      // } else if (this.get('postShowController.isPhotoEssay') &&
      //            ( this.get('contentBlockIsImage') || ( this.get('contentBlockIsText') && this.get('isQuote') ) ) ) {
      //   return true;
      // } else {
      //   return false;
      // }
    }).property("model.contentBlockType"),

    // enforceBreaker: function() {
    //   if (this.get('postShowController.isPhotoEssay')) {
    //     if (this.get('contentBlockIsText') && this.get('isQuote')) {
    //       this.set('mediaDisplayType', 'breaker');
    //     } else if (this.get('contentBlockIsImage')) {
    //       this.set('mediaDisplayType', 'breaker');
    //     }
    //   } else if (this.get('postShowController.isGallery')) {
    //     if (!this.get('contentBlockIsDiptych')) {
    //       this.set('mediaDisplayType', 'breaker');
    //     }
    //   }
    // }.observes('postShowController.articleType'),

    alignClass: (function () {
      var className = "content-block-align-";
      className = className + this.get("align");
      return className;
    }).property("model.align"),

    alignOptions: (function () {
      var mediaDisplayType = this.get("mediaDisplayType");
      if (mediaDisplayType === "gutter_image" || mediaDisplayType === "inset" || mediaDisplayType === "two_column") {
        return ["left", "right"];
      } else if (this.get("contentBlockType") === "related_article") {
        return ["left", "right"];
      } else {
        return ["left", "center", "right"];
      }
    }).property("model.mediaDisplayType"),

    needsAlignOptions: (function () {
      if (!this.get("postIsSuperFeature") && !this.get("postIsCustom")) {
        return false;
      }
      var contentBlockType = this.get("contentBlockType");
      return contentBlockType === "text" || contentBlockType === "image" || contentBlockType === "audio" || contentBlockType === "video" || contentBlockType === "embed";
    }).property("post.articleType", "contentBlockType"),

    postIsSuperFeature: (function () {
      return this.get("post.articleType") === "super_feature";
    }).property("post.articleType"),

    postIsFeature: (function () {
      return this.get("post.articleType") === "feature";
    }).property("post.articleType"),

    postIsCustom: (function () {
      return this.get("post.articleType") === "custom";
    }).property("post.articleType"),

    postIsSimple: (function () {
      return this.get("post.articleType") === "simple";
    }).property("post.articleType"),

    postIsFeatureWithSidebar: (function () {
      return this.get("post.articleType") === "feature_with_sidebar";
    }).property("post.articleType"),

    postIsPhotoEssay: (function () {
      return this.get("post.articleType") === "photo_essay";
    }).property("post.articleType"),

    postIsVideo: (function () {
      return this.get("post.articleType") === "video";
    }).property("post.articleType"),

    postHasDisplayOptions: (function () {
      if (this.get("post.articleType") === "simple") {
        return false;
      } else if (this.get("post.articleType") === "custom") {
        return true;
      } else if (this.get("contentBlockIsText")) {
        return false;
      } else {
        return true;
      }
    }).property("post.articleType", "contentBlockType"),

    isMediaWithContent: (function () {
      var mediaDisplayType = this.get("mediaDisplayType");
      var articleType = this.get("post.articleType");
      // return mediaDisplayType === 'inset' || mediaDisplayType === 'gutter_image' || mediaDisplayType === 'two_column';

      // debugger;

      var contentBlockType = this.get("contentBlockType");
      if (contentBlockType == "instagram" || contentBlockType == "tweet") {
        return true;
      }

      if (this.get("contentBlockIsText")) {
        return false;
      }

      return true;
    }).property("model.mediaDisplayType", "model.contentBlockType", "post.articleType"),

    isTwoColumn: (function () {
      return this.get("mediaDisplayType") === "two_column";
    }).property("model.mediaDisplayType"),

    soundcloudPlayerSrc: (function () {
      var src = "https://w.soundcloud.com/player/?url=";
      src = src + this.get("model.audioUrl");
      src = src + "&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false";
      return src;
    }).property("model.audioUrl"),

    soundcloudPlayerSrc2: (function () {
      var src = "https://w.soundcloud.com/player/?url=";
      src = src + this.get("model.audioUrl2");
      src = src + "&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false";
      return src;
    }).property("model.audioUrl2"),

    isAllowedDiptych: (function () {
      return this.get("postIsSuperFeature") || this.get("postIsFeature") || this.get("postIsCustom");
    }).property("post.articleType"),

    isAllowedSlideshow: (function () {
      return this.get("postIsFeature") || this.get("postIsSuperFeature") || this.get("postIsGallery") || this.get("isCustom");
    }).property("post.articleType"),

    isAllowedRelatedArticle: (function () {
      if (this.get("isSimple")) {
        return false;
      }

      return this.get("isFeature") || this.get("isSuperFeature") || this.get("isCustom");
    }).property("post.articleType"),

    isAllowedHtml5Video: (function () {
      if (this.get("isCustom")) {
        return true;
      }
      return false;
    }).property("post.articleType"),

    isAllowedTitle: (function () {
      if (this.get("postIsCustom")) {
        return true;
      }
      return false;
    }).property("post.articleType"),

    moveUpIsEnabled: (function () {
      // debugger;
      var model = this.get("model");
      if (!model.get("post")) {
        return;
      }

      var currentPosition = model.get("position");
      var contentBlocksCount = model.get("post").get("contentBlocks").get("length");
      if (currentPosition != 1 && contentBlocksCount > 1) {
        return true;
      } else {
        return false;
      }
    }).property("model.position"),

    moveDownIsEnabled: (function () {
      var model = this.get("model");
      if (!model.get("post")) {
        return;
      }

      var currentPosition = model.get("position");
      var contentBlocksCount = model.get("post").get("contentBlocks").get("length");
      if (currentPosition != contentBlocksCount && currentPosition < contentBlocksCount) {
        return true;
      } else {
        return false;
      }
    }).property("model.position"),

    actions: {
      setIsExpanded: function setIsExpanded(expanded) {
        this.set("isExpanded", expanded);
      },

      setContentBlockData: function setContentBlockData(contentBlockType, params) {
        //console.log("setContentBlockData: ", contentBlockType, params);

        var model = this.get("model");
        model.set("contentBlockType", contentBlockType);
        model.set("body", params.body);
        model.set("audioUrl", params.audioUrl);
        model.set("videoUrl", params.videoUrl);
        model.set("youtubeVideoId", params.youtubeVideoId);
        model.set("embed", params.embed);

        this.send("add");
      },

      setText: function setText() {
        this.get("model").set("contentBlockType", "text");
        this.set("isExpanded", true);
      },

      setTitle: function setTitle() {
        this.get("model").set("contentBlockType", "title");
      },

      setHeader: function setHeader() {
        this.get("model").set("contentBlockType", "header");
      },

      setPullQuote: function setPullQuote() {
        //console.log("setPullQuote");
        this.get("model").set("contentBlockType", "pull_quote");
      },

      setNewsletter: function setNewsletter() {
        //console.log("setPullQuote");
        this.get("model").set("contentBlockType", "newsletter");
      },

      setBlockQuote: function setBlockQuote() {
        this.get("model").set("contentBlockType", "block_quote");
      },

      setImage: function setImage() {
        this.get("model").set("contentBlockType", "image");
      },

      setVideo: function setVideo() {
        this.get("model").set("contentBlockType", "video");
      },

      setEmbed: function setEmbed() {
        this.get("model").set("contentBlockType", "embed");
      },

      setNewsletterSignup: function setNewsletterSignup() {
        this.get("model").set("contentBlockType", "newsletter_signup");
      },

      setAudio: function setAudio() {
        this.get("model").set("contentBlockType", "audio");
      },

      setTweet: function setTweet() {
        this.get("model").set("contentBlockType", "tweet");
      },

      setDiptych: function setDiptych() {
        this.get("model").set("contentBlockType", "diptych");
      },

      setSlideshow: function setSlideshow() {
        this.get("model").set("contentBlockType", "slideshow");
      },

      setHtml5Video: function setHtml5Video() {
        this.get("model").set("contentBlockType", "html5_video");
      },

      editSlideshow: function editSlideshow() {
        this.get("router").transitionTo("slideshow.index", this.get("slideshow"));
      },

      setRelatedArticle: function setRelatedArticle() {
        this.get("model").set("contentBlockType", "related_article");
      },

      edit: function edit() {
        this.get("model").set("isEditing", true);
        // this.get('this').set('showNewContentBlock', false);
      },

      cancel: function cancel() {
        this.get("model").set("isEditing", false);
        // this.get('this').set('showNewContentBlock', true);
      },

      save: function save() {
        var contentBlock = this.get("model");

        contentBlock.save().then(function () {
          contentBlock.set("isEditing", false);

          // postShowController.set('showNewContentBlock', true);
        }, function () {
          // postShowController.set('showErrors', true);
          // postShowController.set('selectedRightBarControl', 'alerts');
        });
      },

      "delete": function _delete() {
        var model = this.get("model");
        this.get("router").transitionTo("content-blocks.delete", model);
      },

      moveUp: function moveUp() {
        var model = this.get("model");
        var currentPosition = model.get("position");
        if (currentPosition == 1) {
          return;
        }

        this.swapContentBlockPositions(-1);
      },

      moveDown: function moveDown() {
        var model = this.get("model");
        var currentPosition = model.get("position");
        if (currentPosition == model.get("post.contentBlocks.length")) {
          return;
        }

        this.swapContentBlockPositions(1);
      },

      insertContentBlock: function insertContentBlock() {
        var model = this.get("model");
        var currentPosition = model.get("position");

        model.get("post").get("contentBlocks").forEach(function (contentBlock) {
          var position = contentBlock.get("position");
          if (position >= currentPosition) {
            contentBlock.set("position", position + 1);
            // contentBlock.save();
          }
        });

        var newContentBlock = this.get("store").createRecord("contentBlock");
        newContentBlock.set("isEditing", true);
        newContentBlock.set("contentBlockType", "text");
        newContentBlock.set("position", currentPosition);
        newContentBlock.set("post", model.get("post"));
        newContentBlock.set("prePostId", model.get("post").get("id"));

        // newContentBlock.save();
      },

      add: function add() {
        var contentBlock = this.get("model");
        var post = this.get("post");
        var _this = this;

        //console.log('adding content block');

        if (!contentBlock.get("position")) {
          var lastContentBlock = post.get("contentBlocks").get("lastObject");
          var position;

          if (lastContentBlock) {
            //console.log("we have a lastContentBlock");
            position = lastContentBlock.get("position") + 1;
          } else {
            position = 1;
          }

          //console.log("position: " + position);
          contentBlock.set("position", position);
        }

        if (!contentBlock.get("prePostId")) {
          contentBlock.set("prePostId", post.get("id"));
        }

        contentBlock.save().then(function () {
          contentBlock.set("isEditing", false);
          // contentBlock.set('post', post);

          //console.log("contentBlock saved");

          _this.set("isExpanded", false);

          // update poistions after possible insert
          contentBlock.get("post").get("contentBlocks").forEach(function (cb) {
            if (cb.get("hasDirtyAttributes")) {
              cb.save();
            }
          });

          _this.sendAction("success");
        }, function () {
          console.log("content block errors");
          _this.sendAction("error");
        });
      },

      cancelNew: function cancelNew() {
        this.set("isExpanded", false);
        this.set("body", "");
      },

      showSpinner1: function showSpinner1() {
        //// console.log("showSpinner1");
        this.set("imageLoading", true);
      },

      image1Finished: function image1Finished(publicId, width, height, format, result) {
        this.set("imageLoading", false);
        var model = this.get("model");
        model.set("imageCloudinaryPublicId", publicId);
        model.set("imageOriginalWidth", width);
        model.set("imageOriginalHeight", height);
        model.set("uploadType", result.type);

        if (format == "gif") {
          model.set("imageIsAnimatedGif", true);
        }
      },

      video1Finished: function video1Finished(publicId, width, height, format, result) {
        this.set("imageLoading", false);
        var model = this.get("model");
        model.set("videoCloudinaryId", publicId);
        model.set("uploadType", result.type);
      },

      showSpinner2: function showSpinner2() {
        this.set("image2Loading", true);
      },

      image2Finished: function image2Finished(publicId, width, height, format, result) {
        var model = this.get("model");
        this.set("image2Loading", false);
        model.set("imageCloudinaryPublicId2", publicId);
        model.set("image2OriginalWidth", width);
        model.set("image2OriginalHeight", height);
        model.set("uploadType2", result.type);

        if (format == "gif") {
          model.set("imageIsAnimatedGif2", true);
        }
      },

      slideshowFinished: function slideshowFinished() {
        this.send("add");
        this.get("router").transitionTo("slideshow.index", this.get("model.slideshow.id"));
      },

      selectDiptychContent1Type: function selectDiptychContent1Type(contentType) {
        this.get("model").set("diptychContent1Type", contentType);
      },

      selectDiptychContent2Type: function selectDiptychContent2Type(contentType) {
        this.get("model").set("diptychContent2Type", contentType);
      },

      selectAlign: function selectAlign(option) {
        this.get("model").set("align", option);
      },

      titleColorChanged: function titleColorChanged(color) {
        console.log("titleColorChanged: ", color);
        this.get("model").set("titleColor", color);
      },

      backgroundColorChanged: function backgroundColorChanged(color) {
        console.log("backgroundColorChanged: ", color);
        this.get("model").set("backgroundColor", color);
      },

      textColorChanged: function textColorChanged(color) {
        console.log("textColorChanged: ", color);
        this.get("model").set("textColor", color);
      }
    },

    swapContentBlockPositions: function swapContentBlockPositions(direction) {
      var model = this.get("model");
      var post = model.get("post");

      var currentPosition = model.get("position");
      var newPosition = currentPosition + direction;

      //console.log('currentPosition: '+currentPosition);
      //console.log('newPosition: '+newPosition);

      var swappedContentBlock = post.get("contentBlocks").filterBy("position", newPosition).get("firstObject");

      swappedContentBlock.set("position", currentPosition);
      swappedContentBlock.save();

      model.set("position", newPosition);
      model.save();
    }
  });
});