define('thefader-cms-ember/routes/post-packages/index', ['exports', 'ember', 'thefader-cms-ember/routes/base', 'thefader-cms-ember/config/environment', 'ember-cli-pagination/remote/route-mixin'], function (exports, _ember, _thefaderCmsEmberRoutesBase, _thefaderCmsEmberConfigEnvironment, _emberCliPaginationRemoteRouteMixin) {
  exports['default'] = _thefaderCmsEmberRoutesBase['default'].extend(_emberCliPaginationRemoteRouteMixin['default'], {

    perPage: 25,

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        postPackages: this.findPaged('postPackage', params)
      });
    },

    setupController: function setupController(controller, models, transition) {
      this._super.apply(this, arguments);

      controller.set('model', models.postPackages);
    },

    actions: {
      create: function create() {
        console.log("create action");

        var controller = this.get('controller');

        var postPackage = this.store.createRecord('postPackage', {
          title: "New Post Package"
        });

        postPackage.save().then(function () {
          controller.transitionToRoute('post-packages.edit', postPackage.get('id'));
        });
      }
    }
  });
});