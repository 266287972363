define("thefader-cms-ember/routes/posts/index", ["exports", "ember", "thefader-cms-ember/routes/base", "ember-cli-pagination/remote/route-mixin"], function (exports, _ember, _thefaderCmsEmberRoutesBase, _emberCliPaginationRemoteRouteMixin) {
  exports["default"] = _thefaderCmsEmberRoutesBase["default"].extend(_emberCliPaginationRemoteRouteMixin["default"], {
    perPage: 25,

    queryParams: {
      search: {
        refreshModel: false
      },
      contributor_id: {
        refreshModel: false
      },
      field_name: {
        refreshModel: false
      },
      country_id: {
        refreshModel: true
      },
      published_state: {
        refreshModel: true
      },
      sensitive: {
        refreshModel: false
      },
      sort_by_date: {
        refreshModel: true
      },
      article_type: {
        refreshModel: false
      },
      partner: {
        refreshModel: false
      },
      category: {
        refreshModel: false
      },
      format: {
        refreshModel: false
      },
      vertical_ids: {
        refreshModel: false,
        type: "array"
      },
      genre_ids: {
        refreshModel: false,
        type: "array"
      },
      start_date: {
        refreshModel: false
      },
      end_date: {
        refreshModel: false
      }
    },

    formats: function formats() {
      return ["Actual music", "Actual News", "Profile", "Opinon Essay", "Roundup", "Photo editorial"];
    },

    model: function model(params) {
      //console.log("params: ", params);
      return _ember["default"].RSVP.hash({
        posts: this.findPaged("post", params),
        countries: this.store.findAll("country", { reload: true }),
        verticals: this.store.findAll("vertical", { reload: true }),
        musicGenres: this.store.findAll("musicGenre", { reload: true }),
        styleGenres: this.store.findAll("styleGenre", { reload: true }),
        cultureGenres: this.store.findAll("cultureGenre", { reload: true })
      });
    },

    setupController: function setupController(controller, models, transition) {
      console.log("controller: ", controller);
      this._super.apply(this, arguments);

      controller.set("countries", models.countries);
      controller.set("posts", models.posts);
      controller.set("categories", ["News", "Features"]);
      controller.set("formats", this.formats());
      controller.set("allVerticals", models.verticals);
      controller.set("allMusicGenres", models.musicGenres);
      controller.set("allStyleGenres", models.styleGenres);
      controller.set("allCultureGenres", models.cultureGenres);
    },

    actions: {
      refreshSearch: function refreshSearch(params) {
        // debugger;
        this.refresh(params);
      }
    }
  });
});