define('thefader-cms-ember/components/posts-preview', ['exports', 'ember', 'thefader-cms-ember/config/environment'], function (exports, _ember, _thefaderCmsEmberConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    screenState: "mobile",

    previewLink: (function () {
      var url = _thefaderCmsEmberConfigEnvironment['default']['legacyCmsBaseUrl'] + "/cms/posts/" + this.get('model').get('id') + '/preview';
      return url;
    }).property('model.id'),

    legacyLoginLink: (function () {
      var url = _thefaderCmsEmberConfigEnvironment['default']['legacyCmsBaseUrl'] + "/cms";
      return url;
    }).property(),

    isMobile: (function () {
      return this.get('screenState') == "mobile";
    }).property('screenState'),

    isDesktop: (function () {
      return this.get('screenState') == "desktop";
    }).property('screenState'),

    isSocial: (function () {
      return this.get('screenState') == "social";
    }).property('screenState'),

    actions: {

      setMobile: function setMobile() {
        // console.log("setMobile");
        this.set('screenState', "mobile");
      },

      setDesktop: function setDesktop() {
        // console.log("setDesktop");
        this.set('screenState', "desktop");
      },

      setSocial: function setSocial() {
        // console.log("setSocial");
        this.set('screenState', "social");
      }
    }
  });
});